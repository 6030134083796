import axios from 'axios';
import MaterialSelector from '../components/job/MaterialSelector';
import MoldMaterial from '../store/job/mold-material';

export default {
    async getAllMaterials() {
        try {
            return await axios.get('/api/material/getAllMaterials');
        } catch (error) {
            throw new Error('Error getting materials');
        }
    },
    async createMaterial(materials: MoldMaterial[], type: string): Promise<MoldMaterial[]> {
        try {
            return (await axios.post('/api/material', { type: type, materials: materials })).data;
        } catch (error) {
            throw new Error('Error creating material');
        }
    },
    async updateMaterial(materials: MoldMaterial[], type: string) {
        try {
            return await axios.put('/api/material', { type: type, materials: materials });
        } catch (error) {
            throw new Error('Error updating material');
        }
    },
    async deleteMaterial(materialId: string, type: string, creatorUserRecordKey?: string, creatorUserContainer?: string) {
        try {
            return await axios.delete('/api/material', { params: { id: materialId, type, creatorUserRecordKey, creatorUserContainer } });
        } catch (error) {
            throw new Error('Error deleting material');
        }
    }
}