import { Box, Card, Fade, CircularProgress, Typography, Divider, Button, IconButton, Tooltip } from "@mui/material";
import { PropsWithChildren, useContext, useEffect, useState } from "react";
import theme from "../../styles/main-theme";
import { UserInvitationDialog } from "../common/UserInvitationDialog";
import { UsersMetricsDialog } from "../common/UsersMetricsDialog";
import Layout from "../ui/Layout";
import { UserInvitation, UserInvitations } from "./UserInvitations";
import { UserList, UserListModel, UserRole } from "./UserList";
import AssessmentIcon from '@mui/icons-material/Assessment';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { AuthContext } from "../../auth/AuthenticationContext";
import CreditsAccountService from "../../services/CreditsAccountService";
import queryErrorHandler, { successToast } from "../../react-query/queryErrorHandler";
import CompanyInvitationService from "../../services/CompanyInvitationService";
import { useTranslation } from 'react-i18next';
import { CompanyUsersContext } from "../../store/creditsAccount/CreditsAccountContext";

export const UsersPage = (props: PropsWithChildren) => {
    const authContext = useContext(AuthContext);
    const [openInvitationDialog, setOpenInvitationDialog] = useState(false);
    const [openMetricsDialog, setOpenMetricsDialog] = useState(false);
    const [invitations, setInvitations] = useState<Array<UserInvitation>>([]);
    const [isLoading, setIsloading] = useState(false);
    const { t } = useTranslation();
    const companyUsersContext = useContext(CompanyUsersContext);

    const loadPendingInvitations = async () => {
        try {
            const pendingInvitations = await CompanyInvitationService.GetPendingInvitations();

            setInvitations(pendingInvitations);
        } catch (error) {
            queryErrorHandler(t("Error getting pending invitations"));
        }
    }

    const loadData = async () => {
        let promises = [];
        setIsloading(true);
        promises.push(loadPendingInvitations());
        await Promise.all(promises);
        setIsloading(false);
    }

    //On component mount
    useEffect(() => {
        loadData();
    }, [])

    const onUsersInvited = (invitedUsers: Array<UserInvitation>) => {
        setInvitations(invitations.concat(invitedUsers));
    }

    const onCancelInvitation = async (invitationToCancel: UserInvitation) => {
        try {
            await CompanyInvitationService.CancelInvitation(invitationToCancel.id);
            setInvitations(invitations.filter((i) => { return i.id != invitationToCancel.id }));
        } catch (error) {
            queryErrorHandler(error);
        }
    }

    const onRemoveUser = async (userId: string) => {
        try {
            companyUsersContext.removeUser(userId);
        } catch (e) {
            queryErrorHandler(e);
        }
    }

    const onResendInvitation = async (invitation: UserInvitation) => {
        try {
            const updatedInvitation: UserInvitation = await CompanyInvitationService.ResendInvitation(invitation.id);

            const newList = invitations.filter((i) => { return i.id != updatedInvitation.id });
            setInvitations([...newList, updatedInvitation]);
            successToast(t("The invitation has been sent."));
        } catch (e) {
            queryErrorHandler(e);
        }
    }

    const sortUsers = (companyUsers: Array<UserListModel>) => {
        return companyUsers.sort((u1: UserListModel, u2: UserListModel) => {
            if (u1.role == UserRole.Admin) return -1;
            if (u2.role == UserRole.Admin) return 1;

            if (u1.name > u2.name) {
                return 1;
            }

            if (u1.name < u2.name) {
                return -1;
            }

            return 0;
        });
    }

    return (
        <Layout>
            <Box sx={{ color: theme.palette.primary.dark, p: 5, minHeight: "100%", display: "flex", flexDirection: "row", justifyContent: "center" }}>
                <Card sx={{ maxWidth: "1200px", padding: "25px", flex: "1 1 0", height: "fit-content", position: "relative" }}>
                    <Fade in={isLoading} unmountOnExit>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", position: "absolute", left: "0", right: "0", top: "0", bottom: "0" }}>
                            <CircularProgress />
                        </Box>
                    </Fade>
                    <Typography sx={{ color: theme.palette.primary.dark, fontWeight: "bold" }} variant="h5" gutterBottom>{t("Users in Company Account")}</Typography>
                    <Divider sx={{ mb: "15px" }}></Divider>
                    <Button data-testid="addEmailButton" sx={{
                        textAlign: 'left',
                        color: theme.palette.primary.dark,
                        backgroundColor: theme.palette.secondary.dark,
                        textTransform: 'none',
                        fontSize: '1.1em',
                        cursor: 'pointer',
                        borderRadius: "20px",
                        margin: "5px",
                        "&:hover": {
                            backgroundColor: theme.palette.info.dark
                        },
                    }}
                        onClick={() => { setOpenInvitationDialog(true) }}
                        startIcon={<AddBoxIcon />} size='small' variant='contained'>
                        {t("Invite Users")}
                    </Button>
                    <Button data-testid="usersStatisticsButton"
                    size='small' variant='contained'
                        sx={{
                            textAlign: 'left',
                            color: theme.palette.primary.dark,
                            backgroundColor: theme.palette.secondary.dark,
                            textTransform: 'none',
                            fontSize: '1.1em',
                            cursor: 'pointer',
                            borderRadius: "20px",
                            margin: "5px",
                            "&:hover": {
                                backgroundColor: theme.palette.info.dark
                            },
                        }}                        
                        onClick={() => { setOpenMetricsDialog(true) }}>
                        {t("User Statistics")}
                    </Button>                    
                    <br /><br />
                    <UserList users={companyUsersContext.users} onRemoveUser={onRemoveUser} />
                    <br /><br />

                    <Typography variant="h5" sx={{ color: theme.palette.primary.dark, fontWeight: "bold" }} gutterBottom>{t("Pending Invitations")}</Typography>
                    <Divider sx={{ mb: "15px" }}></Divider>

                    <UserInvitations invitations={invitations} onCancelInvitation={onCancelInvitation} onResendInvitation={onResendInvitation} />
                </Card>
            </Box>

            <UserInvitationDialog open={openInvitationDialog} onClose={() => { setOpenInvitationDialog(false) }} onUsersInvited={onUsersInvited} title={`${t("Invite Users to")} ${authContext.companyName} ${t("Company Account")}`} />
            <UsersMetricsDialog open={openMetricsDialog} onClose={() => { setOpenMetricsDialog(false) }}></UsersMetricsDialog>
        </Layout>
    )
}