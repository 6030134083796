import { Alert, AlertTitle, Box, Button, Card, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Fade, FormControlLabel, FormGroup, Paper, Tooltip, Typography, useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../auth/AuthenticationContext";
import AccountProductService from "../../services/AccountProductService";
import PaymentService from "../../services/PaymentService";
import CreditsAccountContext, { AccountUsersConfiguration, CreditsAccountContextValue } from "../../store/creditsAccount/CreditsAccountContext";
import Layout from "../ui/Layout";
import BuyProductComponent from "./buyProductComponent";
import { PriceItem, ProductModel, SubscriptionModel } from "./models/productModel";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import queryErrorHandler from "../../react-query/queryErrorHandler";
import ConfirmationModal from "../common/ConfirmationModal";
import ConfigurationOptionsService from "../../services/ConfigurationOptionsService";
import SubscriptionOptions from "../../models/subscriptionOptions";
import { capitalizeFirstLetter } from "../../services/HelperService";
import { AccountConfiguration } from "./AccountConfiguration";
import { useTranslation } from 'react-i18next';
import { makeStyles } from "@mui/styles";

const AccountPage = () => {
    const theme = useTheme();
    const authContext = useContext(AuthContext);
    const [subscriptionProducts, setSubscriptionProducts] = useState<Array<ProductModel>>([]);
    const [oneTimeProducts, setOneTimeProducts] = useState<Array<ProductModel>>([]);
    const [subscriptions, setSubscriptions] = useState<Array<SubscriptionModel>>([]);
    const [loading, setLoading] = useState(false);
    const creditsAccountContext = useContext<CreditsAccountContextValue>(CreditsAccountContext);
    const [openCancelSubscriptionAlert, setOpenCancelSubscriptionAlert] = useState<boolean>(false);
    const [showSubscriptionModal, setShowSubscriptionModal] = useState<boolean>(false);
    const [selectedSubscriptionProduct, setSelectedSubscriptionProduct] = useState<ProductModel | null>(null);
    const [subscriptionOptions, setSubscriptionOptions] = useState<SubscriptionOptions | null>(null);
    const { t } = useTranslation();

    useEffect(() => {
        //on create
        loadData();
    }, [])

    const loadData = async () => {
        setLoading(true);

        const productResponse = await AccountProductService.GetAllProducts();
        setSubscriptionProducts(productResponse.subscriptionProducts);
        setOneTimeProducts(productResponse.oneTimeProducts);

        //Get user subscriptions
        const subscriptions = await PaymentService.GetActiveSubscriptions();
        setSubscriptions(subscriptions);

        //Get subscription options
        const options = await ConfigurationOptionsService.GetSubscriptionOptions();
        setSubscriptionOptions(options);

        setLoading(false);
    }

    const buyProduct = async (product: ProductModel) => {
        const priceId = product.default_price.id;
        let checkoutSession = await PaymentService.BuyProduct(priceId);
        window.location.href = checkoutSession.url;
    }

    const subscribeProduct = async (product: ProductModel | null) => {
        if (!product) return;

        const priceId = product.default_price.id;
        let checkoutSession = await PaymentService.SubscribeProduct(priceId);
        window.location.href = checkoutSession.url;
    }

    const isFreeAccount = () => {
        return creditsAccountContext?.isFree;
    }

    const manageSubscription = async () => {
        let checkoutSession = await PaymentService.CreatePortalSession();
        window.location.href = checkoutSession.url;
    }

    const getCurrency = (price: PriceItem) => {
        return price.currency.toUpperCase();
    }

    const getUnitAmount = (price: PriceItem) => {
        return price.unit_amount / 100;
    }

    const getRecurringInterval = (price: PriceItem) => {
        return capitalizeFirstLetter(price.recurring.interval);
    }

    const getAmountOfCredits = (price: PriceItem) => {
        return price.metadata?.Credits;
    }

    const handleCloseCancelSubscriptionAlert = async (cancelSubscription: boolean) => {
        try {
            setOpenCancelSubscriptionAlert(false);

            if (cancelSubscription) {
                const subscriptionId = subscriptions[0].id;

                setLoading(true);
                await PaymentService.cancelSubscription(subscriptionId);
                setLoading(false);

                await loadData();
            }
        } catch (error: any) {
            queryErrorHandler(new Error(error.message));
        } finally {
            setLoading(false);
        }
    }

    const getSubscriptionDate = () => {
        const date = new Date(subscriptions[0].created * 1000);
        return date.toLocaleDateString();
    }

    const getSubscriptionType = () => {
        return subscriptions[0].items.data[0].price.type;
    }

    const CanSubscriptionBeCancelled = () => {
        const subscriptionCreationDate = new Date(subscriptions[0].created * 1000);

        const today = new Date();

        const diff = monthDiff(subscriptionCreationDate, today)

        const numberOfPaymentsBeforeUserCanCancel = subscriptionOptions?.numberOfPaymentsBeforeUserCanCancel ?? 3;

        return diff >= (numberOfPaymentsBeforeUserCanCancel - 1);
    }

    const monthDiff = (d1: Date, d2: Date) => {
        var months;
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth();
        return months <= 0 ? 0 : months;
    }

    const openSubscriptionModal = (subscriptionProduct: ProductModel) => {
        setSelectedSubscriptionProduct(subscriptionProduct);
        setShowSubscriptionModal(true);
    }

    const getPricePerMonth = (price: PriceItem) => {
        return `${getCurrency(price)}$${getUnitAmount(price)} / ${getRecurringInterval(price)}`
    }

    const getSubscriptionCancelInfo = () => {
        const paymentLabel = subscriptionOptions?.numberOfPaymentsBeforeUserCanCancel && subscriptionOptions?.numberOfPaymentsBeforeUserCanCancel > 1 ? " payments" : "payment"
        let info = `Subscriptions can only be cancelled after ${subscriptionOptions?.numberOfPaymentsBeforeUserCanCancel} ${paymentLabel}`

        return info;
    }

    const handleChangeUsersConfig = (config: AccountUsersConfiguration) => {
        creditsAccountContext.updateUsersConfiguration(config);
    }

    const classes = makeStyles({
        btn: {
            color: theme.palette.primary.dark,
            backgroundColor: theme.palette.secondary.dark,
            textTransform: 'none',
            fontSize: '1.1em',
            cursor: 'pointer',
            borderRadius: "20px",
            margin: "5px",
            "&:hover": {
                backgroundColor: theme.palette.info.dark
            }
        }
    })();

    return <>
        <Layout>
            <Box sx={{ color: theme.palette.primary.dark, p: 5, minHeight: "100%", display: "flex", flexDirection: "row", justifyContent: "center" }}>
                <Card sx={{ maxWidth: "1200px", padding: "25px", flex: "1 1 0", height: "fit-content", position: "relative" }}>
                    <Fade in={loading} unmountOnExit>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", position: "absolute", left: "0", right: "0", top: "0", bottom: "0" }}>
                            <CircularProgress />
                        </Box>
                    </Fade>
                    <Typography sx={{ color: theme.palette.primary.dark, fontWeight: "bold" }} variant="h5" gutterBottom>{t("My Account")} ({authContext.companyName})</Typography>
                    <Divider sx={{ mb: "15px" }}></Divider>
                    <Box sx={{ mb: "15px" }}>
                        <Typography sx={{ color: theme.palette.primary.dark }}>{authContext.username}</Typography>
                        <Typography sx={{ color: theme.palette.primary.dark }}>{authContext.email}</Typography>
                    </Box>
                    <Box sx={{ mb: "15px" }}>
                        <Typography variant="h6" sx={{ mb: "20px", fontWeight: "bold", color: theme.palette.primary.dark }}>{creditsAccountContext?.balance} {t("Credits Left")} {isFreeAccount() ? t("(Free trial)") : ""}</Typography>
                        <Button onClick={() => { manageSubscription() }} sx={{
                        textAlign: 'left',
                        color: theme.palette.primary.dark,
                        backgroundColor: theme.palette.secondary.dark,
                        textTransform: 'none',
                        fontSize: '1.1em',
                        cursor: 'pointer',
                        borderRadius: "20px",
                        margin: "5px",
                        "&:hover": {
                            backgroundColor: theme.palette.info.dark
                        },
                    }} variant="contained" size="small">{t("Manage billing info")}</Button>
                    </Box>

                    <Divider sx={{ mb: "25px", mt: "25px" }}></Divider>

                    <Box id="subscription-section">
                        <Box sx={{ display: "flex", alignItems: "center", mb: "10px" }}>
                            <Typography variant="h5" sx={{ color: theme.palette.primary.dark, fontWeight: "bold", margin: "0px", mr: "20px" }} gutterBottom>{t("Subscription")}</Typography>
                        </Box>

                        {/* If user is already subscribed, show subscription and allow to update at any time or cancel if subscription time > 3 month */}
                        {
                            subscriptions.length > 0 &&
                            <Box>
                                <Typography>{t("Current Subscription")}</Typography>
                                <br />
                                <Paper elevation={1} sx={{ p: "15px" }}>
                                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                        <Typography sx={{ color: theme.palette.error.main, fontWeight: "bold" }} variant="h6">
                                            {subscriptions[0].items.data[0].price.product.name}
                                        </Typography>
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            {!CanSubscriptionBeCancelled() &&
                                                <Tooltip title={getSubscriptionCancelInfo()} placement="top">
                                                    <ErrorOutlineIcon sx={{ color: theme.palette.error.main, mr: "5px", cursor: "pointer" }}></ErrorOutlineIcon>
                                                </Tooltip>
                                            }
                                            <Button disabled={!CanSubscriptionBeCancelled()} onClick={() => { setOpenCancelSubscriptionAlert(true) }} variant="contained" sx={{ mr: "15px" }} 
                                                size="small" color="error">{t("Cancel plan")}</Button>
                                            <Button onClick={() => { manageSubscription() }} variant="contained"
                                                sx={{ color: theme.palette.background.paper }} size="small">{t("Update plan")}</Button>
                                        </Box>
                                    </Box>
                                    <br />
                                    <Typography>
                                        {t("Type")}: <b>{getSubscriptionType()}</b>
                                    </Typography>
                                    <br />
                                    <Typography>
                                        {t("Subscription Date")}: <b>{getSubscriptionDate()}</b>
                                    </Typography>
                                    <br />
                                    <Typography>
                                        {getPricePerMonth(subscriptions[0].items.data[0].price)}
                                        :&nbsp;<b>{getAmountOfCredits(subscriptions[0].items.data[0].price)} {t("Credits")}</b>
                                    </Typography>
                                </Paper>
                            </Box>
                        }

                        {/* If user is not already subscribed, display list of available subscriptions */}
                        {
                            subscriptions.length == 0 && loading == false &&
                            <Box>
                                <Typography>{t("No Active Subscription")}</Typography>
                                <br />
                                <Typography variant="h6" sx={{ color: theme.palette.primary.contrastText }}>{t("Select a subscription")}</Typography>
                                <Box sx={{ position: "relative", display: "flex", justifyContent: "flex-start", overflowX: "auto", scrollbarColor: theme.palette.info.contrastText }}>
                                    {subscriptions.length == 0 &&
                                        subscriptionProducts.map((p) => {
                                            return <BuyProductComponent key={p.id} onButtonClick={() => { openSubscriptionModal(p) }} product={p} buyButtonTitle={t("Subscribe")}></BuyProductComponent>
                                        })
                                    }
                                </Box>
                            </Box>
                        }
                    </Box>

                    <Divider sx={{ mb: "25px", mt: "25px" }}></Divider>

                    <Box id="one-time-buy-product-section">
                        <Typography variant="h5" sx={{ mb: "5px", color: theme.palette.primary.dark, fontWeight: "bold" }} gutterBottom>{t("One-time Purchase")}</Typography>
                        <Box sx={{ position: "relative", display: "flex", justifyContent: "flex-start", flexWrap: "nowrap", overflowX: "auto", scrollbarColor: theme.palette.info.contrastText }}>
                            <>
                                {
                                    oneTimeProducts.map((p) => {
                                        return <BuyProductComponent key={p.id} product={p} onButtonClick={() => { buyProduct(p) }} buyButtonTitle="Buy"></BuyProductComponent>
                                    })
                                }
                            </>
                        </Box>
                    </Box>

                    <Divider sx={{ mb: "25px", mt: "25px" }}></Divider>

                    <Box sx={{position: "relative"}}>
                        <Fade in={creditsAccountContext.isMutating} unmountOnExit>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", position: "absolute", left: "0", right: "0", top: "0", bottom: "0" }}>
                                <CircularProgress />
                            </Box>
                        </Fade>
                        <Typography variant="h5" sx={{ mb: "5px", color: theme.palette.primary.dark, fontWeight: "bold" }} gutterBottom>{t("Configuration")}</Typography>
                        <AccountConfiguration 
                            AccountUsersConfiguration={creditsAccountContext.accountConfiguration.AccountUsersConfiguration}
                            onChangeUsersConfig={handleChangeUsersConfig} />
                    </Box>
                </Card>
            </Box>
        </Layout>

        <ConfirmationModal
            open={openCancelSubscriptionAlert}
            onClose={handleCloseCancelSubscriptionAlert}
            title={t("Cancel subscription")}
            content={t("Are you sure you want to cancel that subscription?")}
        ></ConfirmationModal>

        <Dialog
            open={showSubscriptionModal}
            onClose={() => { setShowSubscriptionModal(false) }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
               {t("Subscription")}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Typography>{t("Are you sure you want to subscribe to this product?")}</Typography>
                    <br />
                    <Typography variant="h6" sx={{ color: theme.palette.primary.dark }}><b>{selectedSubscriptionProduct?.name}</b></Typography>
                    <Typography>{selectedSubscriptionProduct?.default_price ? getPricePerMonth(selectedSubscriptionProduct?.default_price) : ""}</Typography>
                    <Typography>{selectedSubscriptionProduct?.default_price ? getAmountOfCredits(selectedSubscriptionProduct?.default_price) + " " + t("Credits") : ""}</Typography>
                    <br />
                    <Alert severity="warning">
                        <AlertTitle>{t("Minimum subscription time")}</AlertTitle>
                        {getSubscriptionCancelInfo()}
                    </Alert>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button className={classes.btn} onClick={() => { setShowSubscriptionModal(false) }}>{t("Cancel")}</Button>
                <Button className={classes.btn} onClick={() => { subscribeProduct(selectedSubscriptionProduct) }} color="error">{t("Subscribe")}</Button>
            </DialogActions>
        </Dialog>
    </>
}

export default AccountPage;