import { Box, Chip, Container, Grid, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import moment from "moment";
import Project from "../../store/project/project";
import ProjectThumbnail from "../common/ProjectThumbnail";
import Iteration from "../../store/project/iteration"
import ProjectService from "../../services/ProjectsService";
import { useNavigate } from 'react-router-dom';
import { DEFAULT_TYPE, ProjectType } from '../../store/project/project-data';
import { useTranslation } from 'react-i18next';
export type ProjectInfoComponentProps = {
    project: Project
    compact: boolean
}
const ProjectInfoComponent = (props: ProjectInfoComponentProps) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const theme = useTheme();

    const getLastModifiedIterationFromNow = (iterations: Iteration[]) => {
        let fromNow: string = "";
        let lastModifiedIteration = ProjectService.getLastModifiedIteration(iterations);

        if (lastModifiedIteration) {
            fromNow = lastModifiedIteration.lastModified ? moment(lastModifiedIteration.lastModified).fromNow() : moment().fromNow();
        }
        return fromNow;
    }

    const getLastModifiedFromNow = () => {
        let fromNow: string = "";
        if (props.project.iterations?.length == 0 || props.project.iterations == undefined) {
            fromNow = props.project.lastModified ? moment(props.project.lastModified).fromNow() : moment().fromNow();
        } else {
            fromNow = getLastModifiedIterationFromNow(props.project.iterations);
        }

        return fromNow;
    }

    const selectProject = () => {
        new ProjectService().navigateToLastModifiedIteration(props.project, navigate, props.project.type as ProjectType);
    }

    return <Grid onClick={selectProject} container direction='row' sx={{ flexWrap: "nowrap" }}>
        {!props.compact &&
            <Grid item xs={3} sx={{ minWidth: "120px" }}>
                {ProjectThumbnail(props.project.thumbnailData, 120, 120)}
            </Grid>
        }
        <Grid item xs={props.compact ? 12 : 9}>
            <Stack spacing={0.5} direction='column' sx={{ paddingLeft: theme.custom.padding.small, alignText: 'left' }}>

                {!props.compact &&
                    <Tooltip
                        placement="left"
                        title={props.project.name}>
                        <Typography noWrap variant="h5"
                            sx={{ fontWeight: 'bold', color: theme.palette.primary.dark, maxWidth: '92%' }}>
                            {props.project.name}
                        </Typography>
                    </Tooltip>}

                <Container
                    sx={{ width: '45vw', transform: 'translateX(-20px)' }}>
                    {props.compact &&
                        <Tooltip
                            placement="left"
                            title={props.project.name}>
                            <Typography noWrap variant="h5"
                                sx={{
                                    fontWeight: 'bold', color: theme.palette.primary.dark, cursor: 'pointer',
                                    maxWidth: '92%'
                                }}>
                                {props.project.name}
                            </Typography>
                        </Tooltip>}
                </Container>
                {!props.compact && <Box
                    sx={{
                        width: '40%',
                        color: theme.palette.grey[800],
                        borderRadius: "5px", backgroundColor: props.project.type && props.project.type == ProjectType.Feasibility ?
                            theme.palette.grey[200] : theme.palette.success.dark
                    }}>
                    <Tooltip placement="left"
                        title={t("Project Type") + ": " + (props.project.type ? t(props.project.type) : t(DEFAULT_TYPE))}>
                        <Typography noWrap>
                            {props.project.type ? t(props.project.type) : t(DEFAULT_TYPE)} {t("project")}
                        </Typography>
                    </Tooltip>
                </Box>
                }
                {!props.compact &&
                    <Box sx={{ fontWeight: 'bold', color: theme.palette.primary.contrastText }}>
                        <Typography noWrap>
                            {!!getLastModifiedFromNow().split(' ')[0].trim() ?
                                t(getLastModifiedFromNow().replace(/\d+|^\s+|\s+$/g, '').trim()).replace('{0}', getLastModifiedFromNow().split(' ')[0].trim()) :
                                t(getLastModifiedFromNow())}
                        </Typography>
                    </Box>
                }
                {props.compact &&
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{
                            marginLeft: '0.5em',
                            marginRight: '0.25%', marginBottom: '5px', width: '30%',
                            color: theme.palette.grey[800],
                            borderRadius: "5px", backgroundColor: props.project.type && props.project.type == ProjectType.Feasibility ?
                                theme.palette.grey[200] : theme.palette.success.dark
                        }} >
                            <Tooltip placement="left"
                                title={t("Project Type") + ": " + (props.project.type ? t(props.project.type) : t(DEFAULT_TYPE))}>
                                <Typography noWrap>
                                    {props.project.type ? t(props.project.type) : t(DEFAULT_TYPE)}
                                </Typography>
                            </Tooltip>
                        </Box>
                        <Typography noWrap sx={{
                            color: theme.palette.info.light, whiteSpace: 'nowrap',
                            width: '100%', display: 'inline-block', marginLeft: '0.5em'
                        }}>
                            <>&#40;</>{t("Updated")}&nbsp;{!!getLastModifiedFromNow().split(' ')[0].trim() ?
                                t(getLastModifiedFromNow().replace(/\d+|^\s+|\s+$/g, '').trim()).replace('{0}', getLastModifiedFromNow().split(' ')[0].trim()) :
                                t(getLastModifiedFromNow())}<>&#41;</>
                        </Typography>
                    </div>
                }
                <Box sx={{ color: theme.palette.info.light, maxWidth: '92%', display: (!props.compact ? 'inline' : 'none') }}>
                    <Tooltip
                        placement="left"
                        title={props.project.description}>
                        <Typography noWrap>
                            {props.project.description || <>&nbsp;</>}
                        </Typography>
                    </Tooltip>
                </Box>
                {!props.compact &&
                    <Box>
                        {props.project.tags?.length
                            ? <Grid container direction='row' columnSpacing={1}>
                                {props.project.tags.map((tag, index) => (
                                    <Grid item key={tag + index} sx={{ padding: '0' }}>
                                        <Chip label={tag} size='small' sx={{ mt: 1, backgroundColor: theme.palette.primary.light, color: theme.palette.primary.contrastText }} />
                                    </Grid>
                                ))}
                            </Grid>
                            : <Chip label={''} size='small' sx={{ visibility: 'hidden' }} />
                        }
                    </Box>
                }
            </Stack>
        </Grid>
    </Grid>
}

export default ProjectInfoComponent;