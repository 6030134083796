import { FC, PropsWithChildren, useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AccountPage from "../components/account/accountPage";
import MetricsPage from "../components/account/ApplicationUsageReportsPage";
import JobComponent from "../components/job/JobComponent";
import JobResultsWrapper from "../components/job/JobResultsWrapper";
import ProjectsListPage from "../components/projects/ProjectsListPage";
import { UsersPage } from "../components/users/UsersPage";
import routes from "./routes";
import { AuthContext } from "../auth/AuthenticationContext";
import { UserRole } from "../components/users/UserList";
import CreditsAccountContext from "../store/creditsAccount/CreditsAccountContext";

const MainRouter = (props: PropsWithChildren) => {
    const authContext = useContext(AuthContext);
    const creditsAccountContext = useContext(CreditsAccountContext);
    
    const isAdmin = authContext.role == UserRole.Admin;
    const isPaidAccount = !creditsAccountContext?.isFree;

    return <Routes>
        <Route path={routes.projects} element={<ProjectsListPage />} />
        {/* Note. the order matters for the following setup routes. RR 6 does not support regex. */}
        <Route path={routes.setup} element={<JobComponent />} />
        <Route path={routes.setups} element={<JobComponent />} />
        <Route path={routes.result} element={<JobResultsWrapper />} />
        <Route path={routes.account} element={isAdmin ? <AccountPage /> : <Navigate to={routes.projects}/>} />
        <Route path={routes.metrics} element={isAdmin ? <MetricsPage/> : <Navigate to={routes.projects}/>} />
        <Route path={routes.root} element={<Navigate to={routes.projects}/>} />
        <Route path={routes.users} element={isAdmin && isPaidAccount ? <UsersPage/> : <Navigate to={routes.projects}/>} />
        <Route path='*' element={<Navigate to={routes.root} />} />
    </Routes>
}

export default MainRouter