import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ChannelBodyType, ChannelEndType, Face, SyntheticFace } from "../../../store/job/job-data";
import { ChannelEndMesh, SelectTerminalOperator } from "../operators/SelectTerminalOperator";
import { Channel } from "../../../store/job/channel";
import { HoopsEntitiesContext } from "../../../store/job/hoops-entities-context";
import { OperatorIndex, areSyntheticMeshesEqual, getCurrentSelectionOperator } from "../../../utils/hoops.utils";
import JobContext from "../../../store/job/job-context";
import { useTranslation } from 'react-i18next';

export type SelectTerminalStartCallback = () => void;
export type SelectTerminalCompleteCallback = () => void;
export type SelectTerminalErrorCallback = (message: string) => void;

export function useSelectTerminal(channels: Channel[], hwv: Communicator.WebViewer, startCb: SelectTerminalStartCallback, completeCb: SelectTerminalCompleteCallback, errorCb: SelectTerminalErrorCallback) {
    const jobContext = useContext(JobContext);
    const { registerChannelMeshes, unregisterMeshes } = useContext(HoopsEntitiesContext);
    const { getMeshForNodeId } = useContext(HoopsEntitiesContext);
    const [enabled, setEnabled] = useState<boolean>(false);
    const [currentSelectionOperator, setCurrentSelectionOperator] = useState<OperatorIndex>();

    const operatorId = useMemo(() => {
        if (hwv && jobContext.IsTreeLoaded) {
            return hwv.registerCustomOperator(new SelectTerminalOperator(hwv, false));
        }
        return Communicator.OperatorId.None;
    }, [jobContext.IsTreeLoaded, hwv]);
    const { t } = useTranslation();

    useEffect(() => {
        return () => {
            hwv && hwv.unregisterCustomOperator(operatorId);
        }
    }, [hwv, operatorId]);

    const getChannel = useCallback((face: Face) => {
        const mesh = getMeshForNodeId(face.nodeId);

        for (const channel of channels) {
            const bodyParts = channel.getBodyParts();
            const bodyFaces = channel.getBodyFaceGroups()
            const isInBodyParts = bodyParts.flatMap(p => p.nodesIds).some(nodeId => nodeId === face.nodeId);
            const isInBodyFaces = bodyFaces.flatMap(f => f.config)
                .some(bodyFace => bodyFace.nodeId === face.nodeId && bodyFace.faceIndex === face.faceIndex);
            const isInSyntheticFaces = mesh && [...channel.inlets, ...channel.outlets].filter(terminal => Channel.isSyntheticFace(terminal.face))
                .find(terminal => areSyntheticMeshesEqual((terminal.face as SyntheticFace).config, mesh));

            if (isInBodyParts || isInBodyFaces || isInSyntheticFaces) {
                return channel;
            }
        }

        return null;
    }, [channels, getMeshForNodeId]);

    const getMesh = useCallback((face: Face) => {
        const channel = getChannel(face);

        if (!channel) {
            return null;
        }

        const mesh = [...channel.inlets, ...channel.outlets]
            .filter(terminal => Channel.isSyntheticFace(terminal.face))
            .map(terminal => terminal.face as SyntheticFace).find(f => f.nodeId === face.nodeId && f.faceIndex === face.faceIndex)?.config ?? getMeshForNodeId(face.nodeId);

        return mesh;
    }, [getChannel]);

    const getChannelType = useCallback((face: Face) => {
        const channel = getChannel(face);

        if (!channel) {
            return null;
        }

        if (channel.getBodyFaceGroups().length) {
            return ChannelBodyType.FACE_GROUPS;
        }

        return ChannelBodyType.PARTS;
    }, [getChannel]);

    const setSelectedSyntheticChannelTerminal = useCallback((face: ChannelEndMesh, channelEndType: ChannelEndType) => {
        const channel = getChannel(face);

        if (!channel) {
            throw new Error(t('This face cannot be a part of channel'));
        }

        const terminal = [...(channelEndType === ChannelEndType.Inlet ? channel.inlets : channel.outlets)]
            .find(terminal => Channel.isSyntheticFace(terminal.face) && areSyntheticMeshesEqual(face.config, terminal.face.config));

        const syntheticTerminalMeshes = [...(channelEndType === ChannelEndType.Inlet ? channel.inlets : channel.outlets)]
            .map(t => t.face).filter(Channel.isSyntheticFace).map(f => f.config);

        
        unregisterMeshes(syntheticTerminalMeshes);
        
        if (terminal) {
            jobContext.removeChannelEnd(channel.id, terminal.id, channelEndType);
        } else {
            jobContext.addChannelEnd(channel.id, face, channelEndType);
        }

        registerChannelMeshes();
        
    }, [getChannel, jobContext]);

    const setSelectedChannelTerminal = useCallback((face: Face, channelEndType: ChannelEndType) => {
        const channel = getChannel(face);

        if (!channel) {
            throw new Error(t('This face cannot be a part of channel'));
        }

        const isAlreadyUsed = channels.filter(c => c.id !== channel.id).flatMap(c => c.getAllFaces())
            .some(f => f.nodeId === face.nodeId && f.faceIndex === face.faceIndex);

        if (isAlreadyUsed) {
            throw new Error(t('This face is already used by another channel'));
        }

        const terminal = [...(channelEndType === ChannelEndType.Inlet ? channel.inlets : channel.outlets)]
            .find(terminal => terminal.face.faceIndex === face.faceIndex && terminal.face.nodeId === face.nodeId);

        if (terminal) {
            jobContext.removeChannelEnd(channel.id, terminal.id, channelEndType);
        } else {
            jobContext.addChannelEnd(channel.id, face, channelEndType);
        }
    }, [getChannel, jobContext]);

    const startTerminalSelection = useCallback(() => {
        if (!hwv || operatorId === Communicator.OperatorId.None || enabled == true) {
            return;
        }

        const operator = hwv.operatorManager.getOperator(operatorId) as SelectTerminalOperator;
        operator.onStart = startCb;

        const currentSelectionTool = getCurrentSelectionOperator(hwv);
        if (currentSelectionTool) {
            setCurrentSelectionOperator(currentSelectionTool);
            hwv.operatorManager.set(operatorId, currentSelectionTool.indexOf);
        } else {
            hwv.operatorManager.set(operatorId, 1);
        }

        setEnabled(true);
    }, [hwv, operatorId, enabled, getMesh, getChannelType, setSelectedSyntheticChannelTerminal, setSelectedChannelTerminal]);

    const endTerminalSelection = useCallback(() => {
        if (!hwv || operatorId === Communicator.OperatorId.None || enabled == false) {
            return;
        }

        if (currentSelectionOperator?.operatorId) {
            hwv.operatorManager.set(currentSelectionOperator.operatorId, currentSelectionOperator.indexOf);
        } else {
            hwv.operatorManager.set(Communicator.OperatorId.Select, 1);
        }

        setEnabled(false);
    }, [hwv, operatorId, enabled, currentSelectionOperator]);

    useEffect(() => {
        if (!hwv || operatorId === Communicator.OperatorId.None) {
            return;
        }

        const operator = hwv.operatorManager.getOperator(operatorId) as SelectTerminalOperator;

        operator.getChannelType = getChannelType;

        operator.getMesh = getMesh;

        operator.onComplete = (face: Face | ChannelEndMesh, shiftModifier: boolean) => {
            try {
                if ('config' in face) {
                    setSelectedSyntheticChannelTerminal(face, shiftModifier ? ChannelEndType.Outlet : ChannelEndType.Inlet);
                } else {
                    setSelectedChannelTerminal(face, shiftModifier ? ChannelEndType.Outlet : ChannelEndType.Inlet);
                }
            } catch (e) {
                if (e instanceof Error) {
                    errorCb?.(e.message);
                }
            } finally {
                completeCb?.();
            }
        };

    }, [hwv, operatorId, getMesh, getChannelType, setSelectedSyntheticChannelTerminal, setSelectedChannelTerminal]);

    return {
        startTerminalSelection,
        endTerminalSelection,
        enabled
    }
}