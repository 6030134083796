import { useContext, useEffect, useRef, useState } from "react";
import * as cee from "@ceetron/common/CeeEnvisionWebComponents";
import { createView, getScalarResultByName, getVisibilityState, loadPartInfo } from "../../services/CeetronService";
import { Box } from "@mui/material";
import { CeetronActionType, CeetronContext, DisplayMode, DisplayPreset, VtfxCase, VtfxScalarResults } from "../../store/job/ceetron-context";
import './styles/CeetronScene.css';
import { Socket } from "socket.io-client";
import useIsMounted from '../../hooks/useIsMounted';
import { disconnectSocketAndClearModel } from "../../utils/ceetron.utils";
import AuthContext from "../../auth/AuthenticationContext";

interface ResultViewerProps {
    onInit(view: cee.View): void;
    onLoadProgress?(progress: number): void;
    isComparisonView?: boolean;
    socket: Socket | undefined;
    show: boolean;
}

type ScalarResultsInfoCache = {
    [key: string]: Promise<cee.ug.QueryBulkCalculationValues>
}

/* Cache must be unique for a set of partId, case and scalar results id */
function buildScalarResultsInfoCacheKey(partId: number, caseType: VtfxCase, resultId: number): string {
    return partId + '-' + caseType + '-' + resultId;
}

async function loadTemperatureInfo(model: cee.ug.RemoteModel, resultId: number, caseType: VtfxCase, partInfoCache: ScalarResultsInfoCache)
    : Promise<cee.ug.QueryBulkCalculationValues[]> {

    const promises = model.getPartSettingsArray().filter(p => p.visible)
        .map(p => {
            const cacheKey = buildScalarResultsInfoCacheKey(p.partId, caseType, resultId);
            if (!partInfoCache[cacheKey]) {
                partInfoCache[cacheKey] = loadPartInfo(model, p, resultId);
            }
            return partInfoCache[cacheKey];
        })

    return Promise.all(promises);
}

async function updateScalarResultsScale(model: cee.ug.RemoteModel, caseType: VtfxCase, scalarResults: VtfxScalarResults, cache: ScalarResultsInfoCache): Promise<number[] | null> {
    const scalarResult = getScalarResultByName(model, scalarResults);
    const settings = model.getScalarSettingsById(scalarResult!.id);
    const temperatureInfo = await loadTemperatureInfo(model, scalarResult!.id, caseType, cache);
    const min = getMinValue(temperatureInfo);
    const max = getMaxValue(temperatureInfo);

    settings.setRange(min, max);
    return [min, max];
}

function getMinValue(data: cee.ug.QueryBulkCalculationValues[]): number {
    return Math.min(...data.map(d => d.volumeMinimumValue));
}

function getMaxValue(data: cee.ug.QueryBulkCalculationValues[]) {
    return Math.max(...data.map(d => d.volumeMaximumValue));
}

export const CeetronScene = (props: ResultViewerProps) => {
    const [view, setView] = useState<cee.View>();
    const containerRef = useRef<HTMLDivElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const scalarResultsInfoCache = useRef<ScalarResultsInfoCache>({});
    const context = useContext(CeetronContext);
    const authContext = useContext(AuthContext);
    const isMounted = useIsMounted();
    //on unmount
    useEffect(() => {
        return () => {
            const mounted = isMounted();
            if (!mounted) {
                //Disconnect socket when component unmount
                if(props.socket && view)
                    disconnectSocketAndClearModel(props.socket, view)
            }
        }
    }, [props.socket, isMounted])

    const onLayoutModified = () => {
        setTimeout(() => {
            if (!view || !canvasRef.current || !containerRef.current)
                return;
            view?.ownerViewer?.resizeViewer(containerRef.current.clientWidth, containerRef.current.clientHeight);
        }, 0);
    }

    const updateScale = async (view: cee.View, cache: ScalarResultsInfoCache) => {
        const model = view.getModelArray().filter(m => m instanceof cee.ug.RemoteModel)[0] as cee.ug.RemoteModel
        if (!model) return;
        const scalarResult = getScalarResultByName(model, context.ceetronState.scalarResults);
        if (!scalarResult) return;
        // Freeze time results have only one state, no need to compute, just use default scale
        if (scalarResult.name !== VtfxScalarResults.FREEZE_TIME_RESULT) {
            try {
                const range = await updateScalarResultsScale(model, context.ceetronState.vtfxCase, context.ceetronState.scalarResults, cache);
                if (range && !props.isComparisonView) {
                    context.updateCeetronState({type: CeetronActionType.SetScalarResultsRange, payload: {min: range[0], max: range[1]}});
                }
            } finally { }
        }
    };

    useEffect(() => {
        if (!props.isComparisonView || !view) {
            return;
        }
        const model = view.getModelArray().filter(m => m instanceof cee.ug.RemoteModel)[0] as cee.ug.RemoteModel;
        if (model) {
            const scalarResult = getScalarResultByName(model, context.ceetronState.displayPreset === DisplayPreset.FREEZE_TIME ? VtfxScalarResults.FREEZE_TIME_RESULT : VtfxScalarResults.TEMPERATURE_RESULT);
            const settings = model.getScalarSettingsById(scalarResult!.id);
            settings.setRange(context.ceetronState.scalarResultsRange.min, context.ceetronState.scalarResultsRange.max);
        }
    }, [context.ceetronState.scalarResultsRange]);

    useEffect(() => {
        if (!props.show) {
            view?.ownerViewer?.resizeViewer(0, 0);
        } else {
            onLayoutModified();
        }
    }, [props.show]);

    useEffect(() => {
        document.addEventListener("layoutModified", onLayoutModified);

        return () => {
            document.removeEventListener("layoutModified", onLayoutModified);
        }
    }, [view]);

    useEffect(() => {
        if (!canvasRef.current || !containerRef.current) {
            throw new Error("Page not initialized in time!");
        }

        const v = createView(canvasRef.current, containerRef.current, authContext);
        props.onInit(v);
        setView(v);
        // Disable warning that we're missing dependency 'props' here - we know that props.onInit is static
        // eslint-disable-next-line
    }, []); // No dependencies - run only once



    useEffect(() => {
        const progress = props.onLoadProgress;
        if (progress) {
            progress(100);

        }
    }, [view]);  

    useEffect(() => {
        if (view && props.show && !context.ceetronState.isScaleUpToDate && context.ceetronState.isViewerReady) {
            context.updateCeetronState({ type: CeetronActionType.SetScaleUpToDateState, payload: true });
            updateScale(view, scalarResultsInfoCache.current);
        }
    }, [context.ceetronState.isScaleUpToDate, context.ceetronState.isViewerReady]);

    useEffect(() => {
        if (!view) {
            return;
        }
        const model = view.getModelArray().filter(m => m instanceof cee.ug.RemoteModel)[0] as cee.ug.RemoteModel;
        if (model) {
            if (context.ceetronState.displayMode === DisplayMode.ISOVOLUME) {
                model.getPartSettingsArray().forEach(p => {    
                    p.opacity = 0.02;
                    p.drawStyle = cee.ug.DrawStyle.SURFACE;
                });
            } else {
                model.getPartSettingsArray().forEach(p => p.opacity = 1);
                model.setDrawStyleAllParts(cee.usg.DrawStyle.SURFACE);
            }
        }
    }, [view, context.ceetronState.displayMode])

    return (
        <Box sx={{ display: props.show ? 'flex' : 'none' }} className="ceetronSceneView" id="ceetronSceneView" ref={containerRef}>
            <canvas ref={canvasRef} />
        </Box>
    );
}