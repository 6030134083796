import { Channel } from "../../../store/job/channel";
import { SyntheticFace, TreeviewFace } from "../../../store/job/job-data";
import { Edge, Face, MeshDataCopyElementIterable, SyntheticMeshInfo, clearPoints, drawFace, drawPoint, makePointKey } from "../../../utils/hoops.utils";
export class RepairBaffleOperator implements Communicator.Operator.Operator {
    protected readonly _hwv: Communicator.WebViewer;

    private _clickPosition?: Communicator.Point2;

    private _selectedEdges: Edge[] = [];

    private _allEdges: Edge[] = [];

    config: (TreeviewFace|SyntheticFace)[] = [];

    onClose?: () => void;

    onSurfaceCreated?: (mesh: SyntheticMeshInfo) => void;

    onSurfaceRemoved?: (meshes: SyntheticMeshInfo[]) => void;

    onError?: (message: string) => void;

    constructor(hwv: Communicator.WebViewer) {
        this._hwv = hwv;
    }

    async onActivate() {
        this._allEdges = [];
        this._selectedEdges = [];
        this._hwv.model.resetModelHighlight();
        this._hwv.setCallbacks({
            _inputInteraction: this.onCtxMenuOpen
        });
    };

    async onDeactivate() {
        this.config = [];
        this._hwv.unsetCallbacks({
            _inputInteraction: this.onCtxMenuOpen
        });
    }

    async onMouseDown(event: Communicator.Event.MouseInputEvent) {
        this._clickPosition = event.getPosition();
        this._hwv.focusInput(true);
    }

    onMouseMove(event: Communicator.Event.MouseInputEvent) {
        delete this._clickPosition;
    }

    async onMouseUp(event: Communicator.Event.MouseInputEvent) {
        event.setHandled(true);

        if (!this._clickPosition) {
            return;
        }

        const selectedItem = await this._hwv.view.pickFromPoint(this._clickPosition, new Communicator.PickConfig(Communicator.SelectionMask.Face));

        if (selectedItem && selectedItem.isFaceSelection()) {
            const edge = this.getClosestEdge(selectedItem.getNodeId(), selectedItem.getPosition());
            console.log(edge);

            if (this._selectedEdges.find(e => Edge.isEqual(e, edge) === true)) {
                this._selectedEdges = this._selectedEdges.filter(e => Edge.isEqual(e, edge) === false);
            } else {
                this._selectedEdges.push(edge);
            }
        } else {
            this._selectedEdges = [];
        }

        this._allEdges.forEach(e => {
            const isSelected = this._selectedEdges.some(se => Edge.isEqual(se, e));

            this._hwv.model.setNodeLineVisibility(e.nodeId, e.edgeIndex, isSelected);
            this._hwv.model.setNodeLineHighlighted(e.nodeId, e.edgeIndex, isSelected);
        });
    }

    async onKeyDown(e: Communicator.Event.KeyInputEvent) {
        if (e.getKeyCode() === 13) {
            const meshInfo = drawFace(this._selectedEdges.flatMap(e => e.vertices), this._hwv);

            if (meshInfo) {
                this.onSurfaceCreated?.(meshInfo);
            }
            this._selectedEdges = [];
        }

        if (e.getKeyCode() === Communicator.KeyCode.Delete) {
            let meshesToRemove: SyntheticMeshInfo[] = [];

            for (const edge of this._selectedEdges) {
                const meshes = this.config.filter(Channel.isSyntheticFace).filter(f =>  f.nodeId === edge.nodeId).map(f => f.config) as SyntheticMeshInfo[];

                meshesToRemove = [...meshesToRemove, ...meshes];
            }

            this.onSurfaceRemoved?.(meshesToRemove);
            this._selectedEdges = [];
        }

        if (e.getKeyCode() === Communicator.KeyCode.Escape) {
            this._selectedEdges = [];
            this.onClose?.();
        }
    }

    async buildEdgeMap() {
        const meshDataCache = new Map<number, {
            meshData: Communicator.MeshDataCopy,
            translationMatrix: Communicator.Matrix
        }>();
        const nodeIds = this.config.map(f => f.nodeId);
        const availableVertices = new Set<string>();

        this._allEdges = [];

        for (const nodeId of nodeIds) {
            const meshData = await this._hwv.model.getNodeMeshData(nodeId);
            const translationMatrix = this._hwv.model.getNodeNetMatrix(nodeId);

            meshDataCache.set(nodeId, {
                meshData, 
                translationMatrix
            });
        }

        for (const face of this.config) {
            const md = meshDataCache.get(face.nodeId)!.meshData;
            const tm = meshDataCache.get(face.nodeId)!.translationMatrix;
 

            for (const v of md!.faces.element(face.faceIndex) as MeshDataCopyElementIterable) {
                const vKey = makePointKey(tm.transform(
                    Communicator.Point3.createFromArray(v.position)
                ));

                availableVertices.add(vKey)
            }
        }

        for (const [nodeId, {meshData: md, translationMatrix: tm}] of meshDataCache.entries()) {
            for (let edgeIndex = 0; edgeIndex < md.lines.elementCount; edgeIndex++) {
                const vertices = [...md.lines.element(edgeIndex) as MeshDataCopyElementIterable]
                    .map(line => Communicator.Point3.createFromArray(line.position))
                    .map(v => tm.transform(v))
                    .filter(v => availableVertices.has(makePointKey(v)));

                if (vertices.length) {
                    this._allEdges.push(new Edge(nodeId, edgeIndex, vertices));
                }
            }
        }
    }

    getClosestEdge(refNodeId: number, refPoint: Communicator.Point3): Edge {
        const sortedEdges = this._allEdges.sort((e1, e2) => {
            const e1Dist = e1.minDistance(refPoint) - (e1.nodeId === refNodeId ? 0.1 : 0);
            const e2Dist = e2.minDistance(refPoint) - (e2.nodeId === refNodeId ? 0.1 : 0);
            
            return e1Dist - e2Dist;
        });

        return sortedEdges[0];
    }

    private onCtxMenuOpen = (event: Communicator.Event.InputEvent, type: Communicator.EventType) => {
        const me: Communicator.Event.MouseInputEvent = event as Communicator.Event.MouseInputEvent;

        if (Communicator.Event.isMouseEventType(type) === true && me.getEventType() === Communicator.MouseInputType.Up && me.getButton() === Communicator.Button.Right) {
            me.setHandled(true);
        }
    };
}