import { useContext, useEffect, useMemo, useRef, useState } from "react";
import * as cee from "@ceetron/common/CeeEnvisionWebComponents";
import { createRemoteModel, findDefaultTimeStepIndex, getScalarResultByName, getVisibilityState, hasDefaultCase, hasFreezeTimeCase, hasFreezeTimeResults, hasPlasticSkinCase, setTemperatureResultsSettings, setVisibilityByType, swapModelAndConstantRemoteModel } from "../../services/CeetronService";
import { CeetronScene } from "./CeetronScene";
import { CuttingPlane } from "./CuttingPlane";
import { PartList } from "./PartList";
import { IsoVolume } from "./IsoVolume";
import { Box, FormControl, FormControlLabel, Grid, Radio, createTheme, RadioGroup, Typography, CircularProgress, Button, Tooltip, InputAdornment, OutlinedInput } from "@mui/material";
import JobProjectSwitcher from "./JobProjectSwitcher";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import JobPage from "./JobPage";
import { CeetronActionType, CeetronContext, DisplayMode, DisplayPreset, PartType, VtfxCase, VtfxScalarResults } from "../../store/job/ceetron-context";
import { StatesControl } from "./StatesControl";
import Iteration from "../../store/project/iteration";
import IterationInfoPanel from "./IterationInfoPanel";
import routes from "../../router/routes";
import queryErrorHandler, { vizServerErrorHandler } from "../../react-query/queryErrorHandler";
import makeStyles from "@mui/styles/makeStyles";
import { Socket } from "socket.io-client";
import { ThemeProvider } from '@emotion/react';
import theme from "../../styles/main-theme";
import FileManagementService from "../../services/FileManagementService";
import { MutableRefObject } from "react";
import { PickInfo, PickInfoPanel } from "./PickInfoPanel";
import { LegendSettingsInfo, LegendSettingsPanel } from "./LegendSettingsPanel";
import { convertTemperature, getUnit } from "../../utils/units.utils";
import { TemperatureUnit, UiSettingsContext } from "../../store/uiSettings/UiSettingsContext";
import JobService from "../../services/JobService";
import { generateGeoModelFromChannelBodiesData } from "../../utils/geo.utils";
import AuthContext from '../../auth/AuthenticationContext';
import { truncate } from "../../utils/formatting";
import CloseIcon from '@mui/icons-material/Close';
import ProjectService from "../../services/ProjectsService";
import { getParametersFormatVersion, JobChannelsBodiesData, JobSolveInputsParameters } from "../../store/job/job-data";
import { TipsAndTricksHelper } from "../../services/TipsAndTricksHelper";
import { ProjectType } from "../../store/project/project-data";
import { useTranslation } from 'react-i18next';
import { getCuttingPlanePosition, updateClippingPlanePosition } from "../../utils/clippingPlane.utils";
import { computeInitialCuttingPlanePosition, disconnectSocketAndClearModel } from "../../utils/ceetron.utils";
import { Id, toast } from "react-toastify";

interface JobResultProps {
    resultPath: string,
    iteration: Iteration
}

const useStyles = makeStyles({
    headers: {
        marginTop: "15px"
    },
    wrapper: {
        padding: "5px 8px 8px 12px",
        borderBottom: "1px solid"
    },
    container: {
        padding: "0px 20px 20px 0px",
        borderBottom: "1px solid"
    },
    titleCompare: {
        color: theme.palette.primary.contrastText,
        fontSize: '1.5em',
        fontWeight: '400'
    },
    closeCompare: {
        left: '50%',
        transform: 'translateX(-50%)',
        textTransform: 'none',
        borderRadius: '1em',
        paddingTop: "0.5em",
        paddingBottom: "0.5em",
        paddingLeft: "1em",
        paddingRight: "1em",
        marginBottom: "10px",
        backgroundColor: theme.palette.primary.contrastText,
        cursor: 'pointer',
        color: theme.palette.secondary.light,
        "&:hover": {
            color: theme.palette.primary.contrastText,
            borderColor: theme.palette.primary.contrastText,
            boxShadow: 'inset 0px 0px 0px 1px'
        }
    }
});

const loadModelMessage = 'Full model is loading, please wait...';

const customTheme = createTheme({
    components: {
        MuiListItem: {
            styleOverrides: {
                root: {
                    paddingLeft: '0',
                    paddingRight: '0',
                    width: '27%'
                }
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    minWidth: '0'
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    "&.Mui-checked": {
                        color: theme.palette.primary.main
                    }
                }
            }
        },
    }
});

let minRange: number | null;
let maxRange: number | null;

export const JobResultPage = (props: JobResultProps) => {
    const [parameters, setParameters] = useState<JobSolveInputsParameters>();
    const { t } = useTranslation();
    const [mainModel, setMainModel] = useState<cee.ug.RemoteModel>();
    const [mainMoldConstantRemoteModel, setMainMoldConstantRemoteModel] = useState<cee.cug.ConstantRemoteModel>();
    const [comparisonModel, setComparisonModel] = useState<cee.ug.RemoteModel>();
    const [comparisonMoldConstantRemoteModel, setComparisonMoldConstantRemoteModel] = useState<cee.cug.ConstantRemoteModel>();
    const [comparisonEjectionTime, setComparisonEjectionTime] = useState<number>();
    const [comparisonIterationName, setComparisonIterationName] = useState<string>('');
    const [comparisonIteration, setComparisonIteration] = useState<Iteration>();
    const [coolingChannelsModel, setCoolingChannelsModel] = useState<cee.geo.GeometryModel | undefined>(new cee.geo.GeometryModel());
    const [comparisonCoolingChannelsModel, setComparisonCoolingChannelsModel] = useState<cee.geo.GeometryModel | undefined>(new cee.geo.GeometryModel());
    const [ioSocket, setIoSocket] = useState<Socket>();
    const [comparisonIoSocket, setComparisonIoSocket] = useState<Socket>();
    const tempView = useRef<cee.View>();
    const showTempView = useRef<boolean>(true);
    const showComparisonView = useRef<boolean>(false);
    const cugBlob = useRef<Promise<Blob>>();
    const [mainView, setMainView] = useState<cee.View>();
    const [comparisonView, setComparisonView] = useState<cee.View>();
    const [cuttingPlane, setCuttingPlane] = useState<cee.ug.CuttingPlane>();
    const [comparisonCuttingPlane, setComparisonCuttingPlane] = useState<cee.ug.CuttingPlane>();
    const [isoVolume, setIsovolume] = useState<cee.ug.Isovolume>();
    const [comparisonIsoVolume, setComparisonIsoVolume] = useState<cee.ug.Isovolume>();
    const [pickInfo, setPickInfo] = useState<PickInfo>();
    const [colorLegendSettingsInfo, setColorLegendSettingsInfo] = useState<LegendSettingsInfo>();
    const colorLegendSettingsSaveHandler = useRef<(min: number, max: number, unit: string) => void>(() => { });
    const colorLegendSettingsResetHandler = useRef<(max: number | null, min: number | null) => void>(() => { });
    const context = useContext(CeetronContext);
    const navigate = useNavigate();
    const location = useLocation();
    const errorToastId = useRef<Id | undefined>(undefined);
    const { id: projectId, jobId, container: containerNameParam } = useParams();
    const classes = useStyles();
    const [wallThickness, setWallThickness] = useState<number>();
    const authContext = useContext(AuthContext);
    const centerContainerRef = useRef<HTMLDivElement>(null);
    const jobProjectSwitcherContainerRef = useRef<HTMLDivElement>(null);
    const [ceetronScenesContainerHeight, setCeetronScenesContainerHeight] = useState<string>('1px');
    const [projectType, setProjectType] = useState<string>();
    const projectService = new ProjectService();
    const uiSettingsContext = useContext(UiSettingsContext); 
    const [iterationItem, setIterationItem] = useState<Iteration>();
    const [isLoadingChannelGeometry, setIsLoadingChannelGeometry] = useState<boolean>(false);
    
    function updateCeetronContainerSize() {
        if (centerContainerRef.current && jobProjectSwitcherContainerRef.current) {
            setCeetronScenesContainerHeight(centerContainerRef.current.clientHeight - jobProjectSwitcherContainerRef.current.clientHeight - 5 + 'px');
        }
    }

    useEffect(() => {
        window.addEventListener('resize', updateCeetronContainerSize);
        return () => {
            clearAllToasts();
            window.removeEventListener('resize', updateCeetronContainerSize);
        }
    }, []);

    function clearAllToasts() {
        errorToastId.current = undefined;
        toast.dismiss();
    }

    useEffect(() => {
        clearAllToasts();
    }, [location]);

    useEffect(() => updateCeetronContainerSize(), [centerContainerRef.current?.clientHeight, jobProjectSwitcherContainerRef.current?.clientHeight]);

    async function loadChannelsGeometryDetails(channelsBodiesData: JobChannelsBodiesData[], view: cee.View, isComparisonModel = false, overrideJobId?: string) {
        setIsLoadingChannelGeometry(true);
        const geoModel = await generateGeoModelFromChannelBodiesData(channelsBodiesData, props.iteration.containerName);
        geoModel.errors.forEach(e => queryErrorHandler(e));
        if (geoModel.result.partCount > 0) {
            view.addModel(geoModel.result);
            if (isComparisonModel) {
                setComparisonCoolingChannelsModel(geoModel.result);
            } else {
                setCoolingChannelsModel(geoModel.result);
            }
        }
        setIsLoadingChannelGeometry(false);
    }

    async function getFrozenWallThickness() {
        const categories = await JobService.getFrozenWallThickness(projectId!, jobId!, props.iteration.containerName);
        //UI shows wallthickness doubled the wallthickness from solver
        const UIwallThickness = categories?.frozenWallThickness ? categories.frozenWallThickness * 2 : undefined;
        setWallThickness(UIwallThickness);
    }

    function resetColorBarRange() {
        maxRange = null;
        minRange = null;
    }
    function unloadAdditionnalGeometryData() {
        if (mainView && mainMoldConstantRemoteModel) {
            mainView.removeModel(mainMoldConstantRemoteModel);
            setMainMoldConstantRemoteModel(undefined);
        }
        if (comparisonView && comparisonMoldConstantRemoteModel) {
            comparisonView.removeModel(comparisonMoldConstantRemoteModel)
            setComparisonMoldConstantRemoteModel(undefined);
        }
        unloadChannelsGeometryDetails();
    }

    function unloadChannelsGeometryDetails() {
        if (mainView && coolingChannelsModel) {
            coolingChannelsModel.deleteAllParts();
            mainView.removeModel(coolingChannelsModel);
        }
        if (comparisonView && comparisonCoolingChannelsModel) {
            comparisonCoolingChannelsModel.deleteAllParts();
            comparisonView.removeModel(comparisonCoolingChannelsModel);
        }
        setCoolingChannelsModel(undefined);
        setComparisonCoolingChannelsModel(undefined);
    }

    useEffect(() => {
        showTempView.current = true;
        const blobInfo = props.iteration.cugBundleBinaryBlobInfo;
        if (blobInfo) {
            cugBlob.current = new FileManagementService().downloadBlob(blobInfo, props.iteration.containerName);
        }
        resetColorBarRange();
    }, [props.iteration.id]);

    async function handleViewerInit(view: cee.View) {
        context.updateCeetronState({ type: CeetronActionType.SetViewerReadyState, payload: false });
        const token = await authContext.token().then();
        const { remoteModel, socket } = await createRemoteModel(token, handleCeetronError);
        view.addModel(remoteModel);
        openModel(view, tempView, remoteModel, props.resultPath);
        setMainView(view);
        setIoSocket(socket);
        view.ownerViewer.setPickHandler(pickHandler.bind(null, remoteModel));
        view.ownerViewer.setColorLegendClickHandler(colorLegendClickHandler.bind(null, remoteModel));
        loadProject();
        getFrozenWallThickness();
    }

    async function loadProject() {
        const project = await projectService.getProject(projectId!, containerNameParam!);
        if (project) {
            setProjectType(project.type);
        }
    }

    async function loadAdditionnalGeometryData(view: cee.View, isComparisonModel = false, overrideJobId?: string) {
        let data = await JobService.getJobParameters(projectId!, overrideJobId ? overrideJobId : jobId!, containerNameParam!);
        if (data && data.rawParameters) {
            if (getParametersFormatVersion(data.rawParameters) <= 1) { // version < 2 => need to convert to current format
                data.rawParameters = JobService.convertJobParameters(data.rawParameters);
            }
            data.rawParameters = JobService.convertJobParameters(data.rawParameters);
            if (data.rawParameters && !isComparisonModel) {
                const model = view.getModelArray().filter(m => m instanceof cee.ug.RemoteModel)[0] as cee.ug.RemoteModel;
                const vs = getVisibilityState(model, data.rawParameters);
                setParameters(data.rawParameters);
                context.updateCeetronState({ type: CeetronActionType.SetVisibilityState, payload: vs });
            }
            if (getParametersFormatVersion(data.rawParameters) > 1) {
                loadMoldConstantRemoteModels(projectId!, overrideJobId ? overrideJobId : jobId!, view, isComparisonModel);
            }           
            if (data.channelsBodiesData) {
                loadChannelsGeometryDetails(data.channelsBodiesData, view, isComparisonModel);
            }
        }
        return data;
    }

    async function loadMoldConstantRemoteModels(projectId: string, jobId: string, view: cee.View, isComparisonModel = false) {
        JobService.getMoldConstantRemoteModelData(projectId, jobId, containerNameParam!).then(blob => {
            if (blob) {
                const moldConstantRemoteModel = new cee.cug.ConstantRemoteModel();
                moldConstantRemoteModel.name = 'mold';
                if (isComparisonModel) {
                    setComparisonMoldConstantRemoteModel(moldConstantRemoteModel);
                    moldConstantRemoteModel.getPartArray().forEach(p => p.visible = false);
                } else {
                    setMainMoldConstantRemoteModel(moldConstantRemoteModel);
                    moldConstantRemoteModel.getPartArray().forEach(p => p.visible = false);
                }
                blob.arrayBuffer().then(buffer => {
                    moldConstantRemoteModel.openLocalBundle(cee.cug.BinaryModelBundle.fromArrayBuffer(buffer));
                    setTimeout(() => window.dispatchEvent(new Event('resize')), 0);
                });
            }
        });
    }

    function handleComparisonViewerInit(view: cee.View) {
        view.overlay.navigationCubeVisible = false;
        view.overlay.modelColorLegendsVisible = false;
        setComparisonView(view);
    }

    function colorLegendClickHandler(model: cee.ug.RemoteModel, resultsId: number) {
        setColorLegendSettingsInfo(undefined);
        const settings = model.getScalarSettingsById(resultsId);
        if (settings) { // when model is out of view, getScalarSettingsById returns null
            // when in autorange mode due to not settings range manually previously, range from settings is undefined
            const maxValueToUse = isNaN(settings.rangeMaximum) ? model.getScalarResultMaximumValue(resultsId) : settings.rangeMaximum;
            const minValueToUse = isNaN(settings.rangeMinimum) ? model.getScalarResultMinimumValue(resultsId) : settings.rangeMinimum;
            if (!minRange && !maxRange) {
                minRange = minValueToUse;
                maxRange = maxValueToUse;
            }
            const scalarResultInfo = model.modelDirectory.scalarResultArray.find(sra => sra.id === resultsId);
            colorLegendSettingsResetHandler.current = (max: number | null, min: number | null) => {
                let maxValue = max;
                let minValue = min;
                if (maxValue != null && minValue != null) {
                    settings.setRange(minValue, maxValue);
                    context.updateCeetronState({ type: CeetronActionType.SetScalarResultsRange, payload: { min: minValue, max: maxValue } });
                }
                colorLegendsCloseHandler();
            };
            colorLegendSettingsSaveHandler.current = (min: number, max: number, unit: string) => {
                const settings = model.getScalarSettingsById(resultsId);
                let maxValue = max;
                let minValue = min;
                if (scalarResultInfo?.name === VtfxScalarResults.TEMPERATURE_RESULT) {
                    maxValue = convertTemperature({
                        value: max, unit: unit as TemperatureUnit
                    }, getUnit(scalarResultInfo) as TemperatureUnit).value;
                    minValue = convertTemperature({
                        value: min, unit: unit as TemperatureUnit
                    }, getUnit(scalarResultInfo) as TemperatureUnit).value;
                }
                settings.setRange(minValue, maxValue);
                colorLegendsCloseHandler();
                context.updateCeetronState({ type: CeetronActionType.SetScalarResultsRange, payload: { min: minValue, max: maxValue } });
            };
            scalarResultInfo && setColorLegendSettingsInfo({
                min: minValueToUse,
                max: maxValueToUse,
                scalarResultInfo: scalarResultInfo
            });
        }
    }

    function colorLegendsCloseHandler() {
        setColorLegendSettingsInfo(undefined);
    }

    function pickHandler(model: cee.ug.RemoteModel, phd: cee.PickHandlerData) {
        const currentFrameIndex = model ? model.currentFrameIndex : -1;
        if (currentFrameIndex >= 0) {
            model.rayIntersect(currentFrameIndex, phd.ray, (rir: cee.ug.RayIntersectResult) => { onPickResult(rir.hitItem, model.modelDirectory.scalarResultArray) });
        }
    }

    function onPickResult(hitItem: cee.ug.HitItem, resultsInfoArray: readonly cee.ug.ResultInfo[]) {
        const scalarResultInfo = resultsInfoArray.find(ri => ri.name === hitItem.scalarResultName);
        if (hitItem.anythingHit && scalarResultInfo) {
            setPickInfo({
                value: hitItem.elementResult,
                scalarResultInfo: scalarResultInfo,
                partName: hitItem.objectName,
                hitItem: hitItem
            });
        } else {
            setPickInfo(undefined);
        }
    }

    useEffect(() => {
       loadIteration();
    }, []);

    useEffect(() => {
        if (!mainView || !pickInfo) {
            return;
        }
        let m_markupModel = new cee.mrk.MarkupModel();
        let pointParts = m_markupModel.addPointsPart();
        pointParts.color = new cee.Color3(1, 0.05, 1);
        pointParts.pointSize = 10;
        pointParts.setVertices([pickInfo.hitItem.intersectionPoint.x, pickInfo.hitItem.intersectionPoint.y, pickInfo.hitItem.intersectionPoint.z]);
        m_markupModel.name = "MarkupModel";
        mainView.addModel(m_markupModel);
        return () => {
            mainView.removeModel(m_markupModel);
        };
    }, [pickInfo]);

    async function handleTempViewerInit(view: cee.View) {
        if (cugBlob.current) {
            const constantRemoteModel = new cee.cug.ConstantRemoteModel();
            view.addModel(constantRemoteModel);
            constantRemoteModel.name = 'freeze_time';
            cugBlob.current.then(blob => {
                blob.arrayBuffer().then(buffer => {
                    constantRemoteModel.openLocalBundle(cee.cug.BinaryModelBundle.fromArrayBuffer(buffer));
                    tempView.current = view;
                    setTimeout(() => window.dispatchEvent(new Event('resize')), 0);
                });
            });
        }
    }

    useEffect(() => {
        if (!mainModel || !mainView) {
            return;
        } else {
            const currentCuttingPlane = mainModel.getCuttingPlaneAt(0);
            if (currentCuttingPlane) {
                currentCuttingPlane.normal = context.ceetronState.cuttingPlaneNormal;
                const normal = context.ceetronState.cuttingPlaneNormal;
                if (normal.x == 1 || normal.x == -1) {
                    context.ceetronState.cuttingPlanePosition = currentCuttingPlane.point.x;
                } else if (normal.y == 1 || normal.y == -1) {
                    context.ceetronState.cuttingPlanePosition = currentCuttingPlane.point.y;
                } else {
                    context.ceetronState.cuttingPlanePosition = currentCuttingPlane.point.z;
                }
                const currentComparisonCuttingPlane = comparisonModel?.getCuttingPlaneAt(0);
                if (currentComparisonCuttingPlane) {
                    currentComparisonCuttingPlane.normal = context.ceetronState.cuttingPlaneNormal;
                }

                //for channel bodies
                updateClippingPlane(mainView, mainModel, comparisonView, comparisonModel);  
            }
        }
    }, [context.ceetronState.cuttingPlaneNormal]);

    function updateClippingPlane(mainView: cee.View, mainModel: cee.ug.RemoteModel, comparisonView?: cee.View, comparisonModel?: cee.ug.RemoteModel,){
        if(mainView && mainModel){
            updateClippingPlanePosition(mainView, mainModel, context.ceetronState.cuttingPlanePosition, context.ceetronState.cuttingPlaneNormal);
        }
        
        if(comparisonView && comparisonModel){
            updateClippingPlanePosition(comparisonView, comparisonModel, context.ceetronState.cuttingPlanePosition, context.ceetronState.cuttingPlaneNormal);
        }
    }

    function updateCuttingPlane(cuttingPlane: cee.ug.CuttingPlane, normal: cee.Vec3Like, position: number) {
        
        const cuttingPlanePosition = {x: cuttingPlane.point.x, y: cuttingPlane.point.x, z: cuttingPlane.point.z}
        return getCuttingPlanePosition(cuttingPlanePosition, normal, position);
    }

    function addCuttingPlane(view: cee.View, model: cee.ug.RemoteModel, isComparisonModel = false) {
        model.deleteAllCuttingPlanes();
        const currentCuttingPlane = model.addCuttingPlane();
        // restoring normal
        currentCuttingPlane.clipping = true;
        currentCuttingPlane.normal = context.ceetronState.cuttingPlaneNormal;
        currentCuttingPlane.point = updateCuttingPlane(currentCuttingPlane, currentCuttingPlane.normal, context.ceetronState.cuttingPlanePosition);
        const scalarResults = getScalarResultByName(model, context.ceetronState.scalarResults);
        // restoring scalar mapping
        if (scalarResults) {
            currentCuttingPlane.mapScalarResultId = scalarResults.id;
        }
        if (isComparisonModel) {
            setComparisonCuttingPlane(currentCuttingPlane);
        } else {
            setCuttingPlane(currentCuttingPlane);
        }

        updateClippingPlanePosition(view, model, context.ceetronState.cuttingPlanePosition, context.ceetronState.cuttingPlaneNormal);
    }

    function removeCuttingPlane(view:cee.View, model: cee.ug.RemoteModel, isComparisonModel = false) {
        if(view)
            view.clipping.removeAllPlanes();

        model.deleteAllCuttingPlanes();
        if (isComparisonModel) {
            setComparisonCuttingPlane(undefined);
        } else {
            setCuttingPlane(undefined);
        }
    }

    function addIsovolume(model: cee.ug.RemoteModel, isComparisonModel = false) {
        model.deleteAllIsovolumes();
        const isoVolume = model.addIsovolume();
        isoVolume.computeFromVisibleParts = true;
        isoVolume.minimumIsoValue = isoVolume.maximumIsoValue; // initialize iso volume with the least computing possible
        if (isComparisonModel) {
            setComparisonIsoVolume(isoVolume);
        } else {
            setIsovolume(isoVolume);
        }
    }

    function removeIsovolume(model: cee.ug.RemoteModel, isComparisonModel = false) {
        model.deleteAllIsovolumes();
        if (isComparisonModel) {
            setComparisonIsoVolume(undefined);
        } else {
            setIsovolume(undefined);
        }
    }

    function openModel(view: cee.View, tempView: MutableRefObject<cee.View | undefined>, remoteModel: cee.ug.RemoteModel | undefined, modelKey: string) {
        if (!remoteModel) {
            return;
        }
        // Trigger prop change event in child components.
        setMainModel(undefined);
        remoteModel.openModel(modelKey, (error, model) => {
            if (error) {
                console.error(error);
                return;
            }
            model.name = modelKey;
            const loadModelPromise = loadAdditionnalGeometryData(view).then(data => {
                if (data) {
                    applyInitialSettings(view, model, data?.rawParameters);
                    setMainModel(model);
                    model.setOneShotDataStreamingCompleteCallback(() => {
                        if (showTempView.current) {
                            transferCameraSettings(view, tempView);
                            showTempView.current = false;
                        }
                    });
                    model.setProgressHandler(progressData => {
                        context.updateCeetronState({ type: CeetronActionType.SetViewerReadyState, payload: progressData.streamingStatus === cee.ug.StreamingStatus.DATA_STREAMING_COMPLETE })
                        // when loading is done, re-setup animation
                        if (progressData.streamingStatus === 2 && progressData.pctDone === 100
                            && !statesAreEqual(model.modelDirectory)) {
                            setupStates(model, 0);
                            if (showComparisonView.current && context.ceetronState.displayPreset != DisplayPreset.MOLD) {
                                matchScaleRange();
                            }
                        }
                    });
                }
            });
            TipsAndTricksHelper.ShowNextTipsAndTricksWhilePromise(loadModelPromise, uiSettingsContext.uiSettings.displayTipsAndTricks, "bottom-right");
        });
    }

    function transferCameraSettings(view: cee.View, tempView: MutableRefObject<cee.View | undefined>): void {
        const previousCamera = tempView?.current?.camera;
        const center = tempView.current?.getBoundingBox().getCenter();
        if (previousCamera && center) {
            const up = previousCamera.getUp();
            const position = previousCamera.getPosition();
            view.camera.setFromLookAt(position, center, up);
        }
    }

    function matchCameraPosition(view: cee.View, view2: cee.View): void {
            const up = view.camera.getUp();
            const position = view.camera.getPosition();
            const direction = view.camera.getDirection();
            view2.camera.setViewpoint(position, direction, up);
            view2.requestRedraw();
    }

    function linkCameraPosition(view: cee.View, view2: cee.View): void {
        const handler = () => {
            matchCameraPosition(view, view2);
        }
        const handler2 = () => {
            matchCameraPosition(view2, view);
        }
        view.camera.setViewChangeHandler(() => {
            view2.camera.setViewChangeHandler(() => { }, false);
            handler();
            view2.camera.setViewChangeHandler(handler2, false);
        }, false);
        view2.camera.setViewChangeHandler(() => {
            view.camera.setViewChangeHandler(() => { }, false);
            handler2();
            view.camera.setViewChangeHandler(handler, false);
        }, false);
    }

    function unlinkCameraPosition(view: cee.View, view2: cee.View): void {
        view2.camera.setViewChangeHandler(() => { }, false);
        view.camera.setViewChangeHandler(() => { }, false);
    }

    function applyInitialSettings(view: cee.View, model: cee.ug.RemoteModel, parameters: JobSolveInputsParameters) {
        const vs = setVisibilityByType(getVisibilityState(model, parameters), PartType.PLASTIC, true);
        vs.skipAction = true;
        context.updateCeetronState({ type: CeetronActionType.SetVisibilityState, payload: vs });
        model.modelSpec.visibleSetIdArray = [1];
        addCuttingPlane(view, model);
        context.updateCeetronState({type: CeetronActionType.SetCuttingPlanePosition, payload: computeInitialCuttingPlanePosition(model, context.ceetronState.cuttingPlaneNormal)});
        setTemperatureResultsSettings(model);
        const defaultTimeStepIndex = findDefaultTimeStepIndex(model, props.iteration.solveInfoEjectionTime);
        const defaultTimeStep = model.modelDirectory.stateInfoArray[defaultTimeStepIndex];
        context.ceetronState.vtfxStates[0].defaultIndex = defaultTimeStepIndex;
        context.ceetronState.vtfxStates[0].lastIndex = defaultTimeStepIndex;
        context.ceetronState.vtfxStates[0].currentIndex = defaultTimeStepIndex;
        model.modelSpec.stateIdArray = [defaultTimeStep.id];
    }

    function applyComparisonInitialSettings(view: cee.View, model: cee.ug.RemoteModel) {
        setTemperatureResultsSettings(model);
        const defaultTimeStepIndex = findDefaultTimeStepIndex(model, props.iteration.solveInfoEjectionTime);
        const defaultTimeStep = model.modelDirectory.stateInfoArray[defaultTimeStepIndex];
        context.ceetronState.vtfxStates[1] = {
            defaultIndex: defaultTimeStepIndex,
            lastIndex: defaultTimeStepIndex,
            currentIndex: defaultTimeStepIndex,
            stateInfoArray: []
        };
        model.modelSpec.stateIdArray = [defaultTimeStep.id];
        handleDisplayPreset(view, model, context.ceetronState.displayPreset, true);
        if (context.ceetronState.displayMode === DisplayMode.ISOVOLUME) {
            model.getPartSettingsArray().forEach(p => {
                p.opacity = 0.02;
                p.drawStyle = cee.ug.DrawStyle.SURFACE;
            });
        }
    }

    function matchScaleRange() {
        if (mainView && comparisonView && showComparisonView.current) {
            const mainModel = mainView.getModelArray().filter(m => m instanceof cee.ug.RemoteModel)[0] as cee.ug.RemoteModel;
            const comparisonModel = comparisonView.getModelArray().filter(m => m instanceof cee.ug.RemoteModel)[0] as cee.ug.RemoteModel;
            const resultId = mainModel.modelSpec.fringesResultId;
            if (mainModel && comparisonModel) {
                const mainSettings = mainModel.getScalarSettingsById(resultId);
                const refSettings = comparisonModel.getScalarSettingsById(resultId);
                if (typeof mainSettings.rangeMinimum === 'number'
                    && typeof mainSettings.rangeMaximum === 'number'
                    && (refSettings.rangeMinimum !== mainSettings.rangeMinimum
                        || refSettings.rangeMaximum !== mainSettings.rangeMaximum)) {
                    refSettings.setRange(mainSettings.rangeMinimum, mainSettings.rangeMaximum);
                }
            }
        }
    }

    function statesAreEqual(directory: cee.ug.ModelDirectory, modelIndex = 0): boolean {
        const stateIds = context.ceetronState.vtfxStates[modelIndex].stateInfoArray;
        const directoryStateIds = directory.stateInfoArray.map(state => state.id);

        return stateIds.length === directoryStateIds.length && stateIds.every(ssi => directoryStateIds.includes(ssi.id));
    }

    function setupStates(model: cee.ug.RemoteModel, modelIndex = 0) {
        const stateInfoArray = model.modelDirectory.stateInfoArray.map((si, index) => {
            return {
                label: `${t("Time")}: ${(si.referenceValue).toFixed(2)} s`,
                id: si.id,
                value: index
            }
        });
        context.updateCeetronState({ type: CeetronActionType.SetVtfxStateInfoArray, payload: { value: stateInfoArray, modelIndex } });
    }

    function handleModeChange(event: React.ChangeEvent<HTMLInputElement>) {
        const mode = parseInt(event.target.value) as DisplayMode;
        context.updateCeetronState({ type: CeetronActionType.SetDisplayMode, payload: mode });
    }

    function handlePresetChange(event: React.ChangeEvent<HTMLInputElement>) {
        const preset = parseInt(event.target.value) as DisplayPreset;
        context.updateCeetronState({ type: CeetronActionType.SetDisplayPreset, payload: preset });
    }

    useEffect(() => {
        if (!mainModel || !mainView) {
            return;
        } else {
            resetColorBarRange();
            colorLegendsCloseHandler();
            handleDisplayPreset(mainView, mainModel, context.ceetronState.displayPreset);
            if (showComparisonView.current && comparisonModel && comparisonView) {
                handleDisplayPreset(comparisonView, comparisonModel, context.ceetronState.displayPreset, true);
            }
            if (cuttingPlane && context.ceetronState.displayPreset == DisplayPreset.MOLD) {
                cuttingPlane.clipping = false;
            }
        }
    }, [context.ceetronState.displayPreset]);

    function handleDisplayPreset(view: cee.View, model: cee.ug.RemoteModel, displayPreset: DisplayPreset, isComparisonModel = false) {
        colorLegendsCloseHandler();
        let currentView = view;
        let currentMoldConstantRemoteModel = mainMoldConstantRemoteModel;
        if (isComparisonModel && comparisonView) {
            currentView = comparisonView;
            currentMoldConstantRemoteModel = comparisonMoldConstantRemoteModel;
        }
        switch (displayPreset) {
            case DisplayPreset.FREEZE_TIME:
                context.ceetronState.scalarResults = VtfxScalarResults.FREEZE_TIME_RESULT;
                context.updateCeetronState({type: isComparisonModel ? CeetronActionType.SetComparisonTimeStepsEnabled : CeetronActionType.SetMainTimeStepsEnabled, payload: true});
                context.updateCeetronState({type: CeetronActionType.SetDisplayModeEnabled, payload: true});
                // v2: swap cug with ug before proceeding as usual
                if (parameters && getParametersFormatVersion(parameters) > 1 && currentView && currentMoldConstantRemoteModel) {
                    swapModelAndConstantRemoteModel(false, view, model, isComparisonModel ? comparisonMoldConstantRemoteModel : mainMoldConstantRemoteModel, () => {
                        switchToVtfxCase(model, hasFreezeTimeCase(model.modelDirectory) ? VtfxCase.FREEZE_TIME : VtfxCase.PLASTIC_SKIN, () => {
                            createFreezingTimeCaseFromModel(model, isComparisonModel ? 1 : 0).applyToRemoteModel(model);
                            handleDisplayMode(view, model, context.ceetronState.displayMode, isComparisonModel);
                            if (!isComparisonModel) {
                                context.updateCeetronState({ type: CeetronActionType.SetScaleUpToDateState, payload: false });
                            }
                        });
                        context.updateCeetronState({ type: CeetronActionType.SetVtfxCase, payload: VtfxCase.FREEZE_TIME });
                    });
                }
                else if (context.ceetronState.vtfxCase === VtfxCase.PLASTIC_SKIN) {
                    createFreezingTimeCaseFromModel(model, isComparisonModel ? 1 : 0).applyToRemoteModel(model);
                    handleDisplayMode(view, model, context.ceetronState.displayMode, isComparisonModel);
                    if (!isComparisonModel) {
                        context.updateCeetronState({ type: CeetronActionType.SetScaleUpToDateState, payload: false });
                    }
                } else {
                    switchToVtfxCase(model, hasFreezeTimeCase(model.modelDirectory) ? VtfxCase.FREEZE_TIME : VtfxCase.PLASTIC_SKIN, () => {
                        createFreezingTimeCaseFromModel(model, isComparisonModel ? 1 : 0).applyToRemoteModel(model);
                        handleDisplayMode(view, model, context.ceetronState.displayMode, isComparisonModel);
                        if (!isComparisonModel) {
                            context.updateCeetronState({ type: CeetronActionType.SetScaleUpToDateState, payload: false });
                        }
                    });
                    context.updateCeetronState({ type: CeetronActionType.SetVtfxCase, payload: VtfxCase.FREEZE_TIME });
                }
                break;
            case DisplayPreset.PLASTIC_SURFACE:
                context.ceetronState.scalarResults = VtfxScalarResults.TEMPERATURE_RESULT;
                context.updateCeetronState({type: isComparisonModel ? CeetronActionType.SetComparisonTimeStepsEnabled : CeetronActionType.SetMainTimeStepsEnabled, payload: true});
                context.updateCeetronState({type: CeetronActionType.SetDisplayModeEnabled, payload: true});
                // v2: swap cug with ug before proceeding as usual
                if (parameters && getParametersFormatVersion(parameters) > 1 && currentView && currentMoldConstantRemoteModel) {
                    swapModelAndConstantRemoteModel(false, view, model, isComparisonModel ? comparisonMoldConstantRemoteModel : mainMoldConstantRemoteModel, () => {
                        switchToVtfxCase(model, VtfxCase.PLASTIC_SKIN, () => {
                            createSurfaceTemperatureCaseFromModel(model, isComparisonModel ? 1 : 0).applyToRemoteModel(model);
                            handleDisplayMode(view, model, context.ceetronState.displayMode, isComparisonModel);
                            restoreStateIdsArray(model, isComparisonModel ? 1 : 0);
                        });
                        context.updateCeetronState({ type: CeetronActionType.SetVtfxCase, payload: VtfxCase.PLASTIC_SKIN });
                    });
                } else {
                    switchToVtfxCase(model, VtfxCase.PLASTIC_SKIN, () => {
                        createSurfaceTemperatureCaseFromModel(model, isComparisonModel ? 1 : 0).applyToRemoteModel(model);
                        handleDisplayMode(view, model, context.ceetronState.displayMode, isComparisonModel);
                        restoreStateIdsArray(model, isComparisonModel ? 1 : 0);
                    });
                    context.updateCeetronState({ type: CeetronActionType.SetVtfxCase, payload: VtfxCase.PLASTIC_SKIN });
                }
                break;
            case DisplayPreset.PLASTIC_VOLUME:
                context.ceetronState.scalarResults = VtfxScalarResults.TEMPERATURE_RESULT;
                context.updateCeetronState({type: isComparisonModel ? CeetronActionType.SetComparisonTimeStepsEnabled : CeetronActionType.SetMainTimeStepsEnabled, payload: true});
                context.updateCeetronState({type: CeetronActionType.SetDisplayModeEnabled, payload: true});
                // v2: swap cug with ug before proceeding as usual
                if (parameters && getParametersFormatVersion(parameters) > 1 && currentView && currentMoldConstantRemoteModel) {
                    swapModelAndConstantRemoteModel(false, view, model, isComparisonModel ? comparisonMoldConstantRemoteModel : mainMoldConstantRemoteModel, () => {
                        switchToVtfxCase(model, VtfxCase.DEFAULT_VIEW, () => {
                            const vs = setVisibilityByType(context.ceetronState.visibility, PartType.PLASTIC, true);
                            vs.skipAction = true;
                            createPlasticVolumeCaseFromModel(model, isComparisonModel ? 1 : 0).applyToRemoteModel(model);
                            context.updateCeetronState({ type: CeetronActionType.SetVisibilityState, payload: vs });
                            handleDisplayMode(view, model, context.ceetronState.displayMode, isComparisonModel);
                            if (!isComparisonModel) {
                                context.updateCeetronState({ type: CeetronActionType.SetScaleUpToDateState, payload: false });
                            }
                            restoreStateIdsArray(model, isComparisonModel ? 1 : 0);
                        });
                        context.updateCeetronState({ type: CeetronActionType.SetVtfxCase, payload: VtfxCase.DEFAULT_VIEW });
                    });
                } else {
                    if (context.ceetronState.vtfxCase === VtfxCase.DEFAULT_VIEW) {
                        const vs = setVisibilityByType(context.ceetronState.visibility, PartType.PLASTIC, true);
                        vs.skipAction = true;
                        createPlasticVolumeCaseFromModel(model, isComparisonModel ? 1 : 0).applyToRemoteModel(model);
                        context.updateCeetronState({ type: CeetronActionType.SetVisibilityState, payload: vs });
                        handleDisplayMode(view, model, context.ceetronState.displayMode, isComparisonModel);
                        if (!isComparisonModel) {
                            context.updateCeetronState({ type: CeetronActionType.SetScaleUpToDateState, payload: false });
                        }
                    } else {
                        switchToVtfxCase(model, VtfxCase.DEFAULT_VIEW, () => {
                            const vs = setVisibilityByType(context.ceetronState.visibility, PartType.PLASTIC, true);
                            vs.skipAction = true;
                            createPlasticVolumeCaseFromModel(model, isComparisonModel ? 1 : 0).applyToRemoteModel(model);
                            context.updateCeetronState({ type: CeetronActionType.SetVisibilityState, payload: vs });
                            handleDisplayMode(view, model, context.ceetronState.displayMode, isComparisonModel);
                            if (!isComparisonModel) {
                                context.updateCeetronState({ type: CeetronActionType.SetScaleUpToDateState, payload: false });
                            }
                            restoreStateIdsArray(model, isComparisonModel ? 1 : 0);
                        });
                        context.updateCeetronState({ type: CeetronActionType.SetVtfxCase, payload: VtfxCase.DEFAULT_VIEW });
                    }
                }
                break;
            default: // case DisplayPreset.MOLD
                context.ceetronState.scalarResults = VtfxScalarResults.TEMPERATURE_RESULT;
                // v2: use cug
                if (parameters && getParametersFormatVersion(parameters) > 1 && currentView && currentMoldConstantRemoteModel) {
                    const vs = setVisibilityByType(context.ceetronState.visibility, PartType.MOLD, true);
                    vs.skipAction = false;
                    context.updateCeetronState({type: CeetronActionType.SetDisplayMode, payload: DisplayMode.CUTTING_PLANE});
                    context.updateCeetronState({type: isComparisonModel ? CeetronActionType.SetComparisonTimeStepsEnabled : CeetronActionType.SetMainTimeStepsEnabled, payload: false});
                    context.updateCeetronState({type: CeetronActionType.SetDisplayModeEnabled, payload: false});
                    swapModelAndConstantRemoteModel(true, view, model, isComparisonModel ? comparisonMoldConstantRemoteModel : mainMoldConstantRemoteModel, () => {
                        view.clipping.removeAllPlanes();
                        if (!isComparisonModel) {
                            context.updateCeetronState({ type: CeetronActionType.SetVisibilityState, payload: vs });
                            context.updateCeetronState({ type: CeetronActionType.SetVtfxCase, payload: VtfxCase.DEFAULT_VIEW });
                        }
                    });
                }
        }
    }

    useEffect(() => {
        if (context.ceetronState.isScaleUpToDate && context.ceetronState.displayPreset != DisplayPreset.MOLD) {
            matchScaleRange();
        }
    }, [context.ceetronState.isScaleUpToDate]);

    useEffect(() => {
        if (!mainModel || !mainView || context.ceetronState.visibility.skipAction) {
            return;
        } else {
            if (mainMoldConstantRemoteModel) {
                if (context.ceetronState.displayPreset === DisplayPreset.MOLD) {
                    mainMoldConstantRemoteModel.getPartArray().forEach(p => {
                        const part = context.ceetronState.visibility.state[p.name];
                        p.visible = part ? part.value : false; // hide parts that are unknown
                    });
                    mainModel.getPartSettingsArray().forEach(settings => {
                        settings.visible = false;
                    });
                } else {
                    mainMoldConstantRemoteModel.getPartArray().forEach(p => {
                        p.visible = false;
                    });
                    mainModel.getPartSettingsArray().forEach(settings => {
                        const name = mainModel.modelDirectory.getPartNameById(settings.geometryIndex, settings.partId);
                        settings.visible = context.ceetronState.visibility.state[name] ? context.ceetronState.visibility.state[name].value : false;
                    });
                }
            }
            if (comparisonMoldConstantRemoteModel && comparisonModel) {
                if (context.ceetronState.displayPreset === DisplayPreset.MOLD) {
                    comparisonMoldConstantRemoteModel.getPartArray().forEach(p => {
                        const part = context.ceetronState.visibility.state[p.name];
                        p.visible = part ? part.value : false; // hide parts that are unknown
                    });
                    comparisonModel.getPartSettingsArray().forEach(settings => {
                        settings.visible = false;
                    });
                } else {
                    comparisonMoldConstantRemoteModel.getPartArray().forEach(p => {
                        p.visible = false;
                    });
                    comparisonModel.getPartSettingsArray().forEach(settings => {
                        const name = comparisonModel.modelDirectory.getPartNameById(settings.geometryIndex, settings.partId);
                        settings.visible = context.ceetronState.visibility.state[name] ? context.ceetronState.visibility.state[name].value : false;
                    });
                }
            }
        }
    }, [context.ceetronState.visibility]);

    const loadIteration = async () => {
        const iteration = await projectService.getIteration(props.iteration.projectId, props.iteration.id, props.iteration.containerName, true);
        if (iteration) {
            setIterationItem(iteration);            
        }
    };

    function handleDisplayMode(view: cee.View, model: cee.ug.RemoteModel, displayMode?: DisplayMode, isComparisonModel = false) {
        if (!model || !view) {
            return;
        } else {
            switch (displayMode ? displayMode : context.ceetronState.displayMode) {
                case DisplayMode.ISOVOLUME:
                    view.ownerViewer.setPickHandler(() => {}); // disable pick in iso volume mode
                    removeCuttingPlane(view, model, isComparisonModel);
                    addIsovolume(model, isComparisonModel);
                    break;
                default:
                    view.ownerViewer.setPickHandler(pickHandler.bind(null, model));
                    if (context.ceetronState.displayPreset != DisplayPreset.MOLD) {
                        addCuttingPlane(view, model, isComparisonModel)
                    }
                    removeIsovolume(model, isComparisonModel);
            }
        }
    }

    function handleCeetronError(e: Error) {
        if (!props.iteration.cugBundleBinaryBlobInfo) {
            unloadAdditionnalGeometryData();
            navigate(routes.projects);
        }
        e.message = e.message + ' Please refresh to continue.'
        clearAllToasts();
        errorToastId.current = vizServerErrorHandler(e, errorToastId);
    }

    useEffect(() => {
        if (!mainModel || !mainView) {
            return;
        } else {
            handleDisplayMode(mainView, mainModel, context.ceetronState.displayMode);
            if (showComparisonView.current && comparisonModel && comparisonView) {
                handleDisplayMode(comparisonView, comparisonModel, context.ceetronState.displayMode, true);
            }
        }
    }, [context.ceetronState.displayMode])

    function handleScalarResultsChange() {
        if (!mainModel || !mainModel.modelDirectory || !mainView) {
            return;
        } else {
            if (!mainMoldConstantRemoteModel || !(context.ceetronState.displayPreset === DisplayPreset.MOLD || context.ceetronState.displayPreset === DisplayPreset.PLASTIC_VOLUME)) {
                const scalarResult = getScalarResultByName(mainModel, context.ceetronState.scalarResults);
                if (scalarResult) {
                    mainModel.modelSpec.fringesResultId = scalarResult.id;
                    const cuttingPlane = mainModel.getCuttingPlaneAt(0);
                    if (cuttingPlane) {
                        cuttingPlane.mapScalarResultId = scalarResult.id;
                    }
                }
            }
        }
    }

    useEffect(() => handleScalarResultsChange(), [context.ceetronState.scalarResults])

    function switchToVtfxCase(model: cee.ug.RemoteModel, caseName?: VtfxCase, cb?: () => void) {
        model.animation.runAnimation(false) // stops before changing case to avoid server crash due to bad index
        const vtfxCaseInfoArray = model.modelDirectory.vtfxCaseInfoArray;
        const vtfxCaseIdToSwitchTo = (vtfxCaseInfoArray.find(c => c.name === caseName) || vtfxCaseInfoArray[0]).caseId;
        model.applyVTFxCase(vtfxCaseIdToSwitchTo, false, cb);
    }

    function handleStateIndexChange(model: cee.ug.RemoteModel, modelIndex = 0) {
        if (context.ceetronState.displayPreset !== DisplayPreset.FREEZE_TIME) {
            context.ceetronState.vtfxStates[modelIndex].lastIndex = context.ceetronState.vtfxStates[modelIndex].currentIndex;
        }
        restoreStateIdsArray(model, modelIndex);
    }

    function restoreStateIdsArray(model: cee.ug.RemoteModel, modelIndex = 0) {
        const stepId = model.modelDirectory.stateInfoArray[context.ceetronState.vtfxStates[modelIndex].currentIndex].id;
        model.modelSpec.stateIdArray = [stepId];
    }

    useEffect(() => {
        if (mainModel && mainView) {
            handleStateIndexChange(mainModel, 0);
        }
    }, [context.ceetronState.vtfxStates[0].currentIndex]);

    useEffect(() => {
        if (comparisonModel && comparisonView) {
            handleStateIndexChange(comparisonModel, 1);
        }
    }, [context.ceetronState.vtfxStates[1].currentIndex]);

    function createMoldCaseFromModel(model: cee.ug.RemoteModel, modelIndex = 0): cee.ug.Case {
        const moldCase = new cee.ug.Case();
        const moldCaseParameters = createCaseParametersFromModel(model);
        const newPartsSettings = moldCaseParameters.partsSettings.map(ps => {
            const name = model.modelDirectory.getPartNameById(ps.geometryIndex, ps.partId);
            ps.visible = (name === PartType.CAVITY || name === PartType.CORE);
            return ps;
        });
        moldCaseParameters.modelSpec.visibleSetIdArray = [];
        moldCaseParameters.modelSpec.stateIdArray = [model.modelDirectory.stateInfoArray[context.ceetronState.vtfxStates[modelIndex].lastIndex].id];
        const newScalarResults = getScalarResultByName(model, VtfxScalarResults.TEMPERATURE_RESULT);
        if (newScalarResults) {
            moldCaseParameters.modelSpec.fringesResultId = newScalarResults.id;
            moldCaseParameters.scalarSettings.forEach(settings => {
                if (settings.resultId !== newScalarResults.id || modelIndex === 1) {
                    settings.legendVisibilityMode = cee.ug.LegendVisibilityMode.NEVER;
                } else {
                    settings.legendVisibilityMode = cee.ug.LegendVisibilityMode.ALWAYS;
                }
            });
        }
        moldCase.create(
            moldCaseParameters.modelSpec,
            moldCaseParameters.mirrorSettings,
            newPartsSettings,
            [],
            [],
            [],
            moldCaseParameters.particleTraceGroups,
            moldCaseParameters.scalarSettings,
            moldCaseParameters.vectorSettings,
            moldCaseParameters.displacementSettings
        );
        return moldCase;
    }

    function createPlasticVolumeCaseFromModel(model: cee.ug.RemoteModel, modelIndex = 0): cee.ug.Case {
        const plasticVolumeCase = new cee.ug.Case();
        const plasticVolumeCaseParameters = createCaseParametersFromModel(model);
        const newPartsSettings = plasticVolumeCaseParameters.partsSettings.map(ps => {
            const name = model.modelDirectory.getPartNameById(ps.geometryIndex, ps.partId);
            ps.visible = (name === PartType.PLASTIC);
            return ps;
        });
        plasticVolumeCaseParameters.modelSpec.visibleSetIdArray = [];
        plasticVolumeCaseParameters.modelSpec.stateIdArray = [model.modelDirectory.stateInfoArray[context.ceetronState.vtfxStates[modelIndex].lastIndex].id];
        const newScalarResults = getScalarResultByName(model, VtfxScalarResults.TEMPERATURE_RESULT);
        if (newScalarResults) {
            plasticVolumeCaseParameters.modelSpec.fringesResultId = newScalarResults.id;
            plasticVolumeCaseParameters.scalarSettings.forEach(settings => {
                if (settings.resultId !== newScalarResults.id || modelIndex === 1) {
                    settings.legendVisibilityMode = cee.ug.LegendVisibilityMode.NEVER;
                } else {
                    settings.legendVisibilityMode = cee.ug.LegendVisibilityMode.ALWAYS;
                }
            });
        }
        plasticVolumeCase.create(
            plasticVolumeCaseParameters.modelSpec,
            plasticVolumeCaseParameters.mirrorSettings,
            newPartsSettings,
            [],
            [],
            [],
            plasticVolumeCaseParameters.particleTraceGroups,
            plasticVolumeCaseParameters.scalarSettings,
            plasticVolumeCaseParameters.vectorSettings,
            plasticVolumeCaseParameters.displacementSettings
        );
        return plasticVolumeCase;
    }

    function createSurfaceTemperatureCaseFromModel(model: cee.ug.RemoteModel, modelIndex = 0): cee.ug.Case {
        const surfaceTemperatureCase = new cee.ug.Case();
        const surfaceTemperatureCaseParameters = createCaseParametersFromModel(model);
        surfaceTemperatureCaseParameters.modelSpec.visibleSetIdArray = [1];
        surfaceTemperatureCaseParameters.modelSpec.stateIdArray = [model.modelDirectory.stateInfoArray[context.ceetronState.vtfxStates[modelIndex].lastIndex].id];
        const newScalarResults = getScalarResultByName(model, VtfxScalarResults.TEMPERATURE_RESULT);
        if (newScalarResults) {
            surfaceTemperatureCaseParameters.modelSpec.fringesResultId = newScalarResults.id;
            surfaceTemperatureCaseParameters.scalarSettings.forEach(settings => {
                if (settings.resultId !== newScalarResults.id || modelIndex === 1) {
                    settings.legendVisibilityMode = cee.ug.LegendVisibilityMode.NEVER;
                } else {
                    settings.legendVisibilityMode = cee.ug.LegendVisibilityMode.ALWAYS;
                }
            });
        }
        surfaceTemperatureCase.create(
            surfaceTemperatureCaseParameters.modelSpec,
            surfaceTemperatureCaseParameters.mirrorSettings,
            surfaceTemperatureCaseParameters.partsSettings,
            [],
            [],
            [],
            surfaceTemperatureCaseParameters.particleTraceGroups,
            surfaceTemperatureCaseParameters.scalarSettings,
            surfaceTemperatureCaseParameters.vectorSettings,
            surfaceTemperatureCaseParameters.displacementSettings
        );
        return surfaceTemperatureCase;
    }

    function createFreezingTimeCaseFromModel(model: cee.ug.RemoteModel, modelIndex = 0): cee.ug.Case {
        const freezingTimeCase = new cee.ug.Case();
        const freezingTimeCaseParameters = createCaseParametersFromModel(model);
        freezingTimeCaseParameters.modelSpec.visibleSetIdArray = [1];
        freezingTimeCaseParameters.modelSpec.stateIdArray = [model.modelDirectory.stateInfoArray[context.ceetronState.vtfxStates[modelIndex].defaultIndex].id];
        const newScalarResults = getScalarResultByName(model, VtfxScalarResults.FREEZE_TIME_RESULT);
        if (newScalarResults) {
            freezingTimeCaseParameters.modelSpec.fringesResultId = newScalarResults.id;
            freezingTimeCaseParameters.scalarSettings.forEach(settings => {
                if (settings.resultId !== newScalarResults.id || modelIndex === 1) {
                    settings.legendVisibilityMode = cee.ug.LegendVisibilityMode.NEVER;
                } else {
                    settings.legendVisibilityMode = cee.ug.LegendVisibilityMode.ALWAYS;
                }
            });
        }
        freezingTimeCase.create(
            freezingTimeCaseParameters.modelSpec,
            freezingTimeCaseParameters.mirrorSettings,
            freezingTimeCaseParameters.partsSettings,
            [],
            [],
            [],
            freezingTimeCaseParameters.particleTraceGroups,
            freezingTimeCaseParameters.scalarSettings,
            freezingTimeCaseParameters.vectorSettings,
            freezingTimeCaseParameters.displacementSettings
        );
        return freezingTimeCase;
    }

    function createCaseParametersFromModel(model: cee.ug.RemoteModel) {
        const modelSpec = model.modelSpec.getAsProperties();
        const mirrorSettings = model.mirrorSettings;
        const partsSettings = model.getPartSettingsArray().map(ps => ps.getAsProperties());
        const cuttingPlanes = model.getCuttingPlaneArray().map(cps => cps.getAsProperties());
        const isosurfaces = model.getIsosurfaceArray().map(is => is.getAsProperties());
        const isovolumes = model.getIsovolumeArray().map(iv => iv.getAsProperties());
        const particleTraceGroups = model.getParticleTraceGroupArray().map(ptg => ptg.getAsProperties());
        const scalarSettings = model.getScalarSettingsArray().map(ss => ss.getAsProperties());
        const vectorSettings = model.getVectorSettingsArray().map(vs => vs.getAsProperties());
        const displacementSettingsArray = model.getDisplacementSettingsArray();
        const displacementSettings = displacementSettingsArray.length > 0 ? displacementSettingsArray[0].getAsProperties() : {
            resultId: 0,
            scaleFactor: 1
        };
        return {
            modelSpec, mirrorSettings, partsSettings, cuttingPlanes, isosurfaces, isovolumes, particleTraceGroups, scalarSettings, vectorSettings, displacementSettings
        };
    }

    const [viewLoadProgress, setViewLoadProgress] = useState<number>(0);
    useEffect(() => {
        if (!showTempView.current && tempView?.current) {
            tempView.current.removeAllModels();
        }
    }, [showTempView]);

    async function onJobCompare(iteration: Iteration) {
        if (context.ceetronState.displayPreset === DisplayPreset.MOLD) { // reset to freeze time if in mold preset to avoid issue with job where CUG is not available
            context.updateCeetronState({ type: CeetronActionType.SetDisplayPreset, payload: DisplayPreset.FREEZE_TIME});
        }
        if (showComparisonView.current) {
            onJobCompareClose();
        }
        if (mainView && comparisonView) {
            showComparisonView.current = true;
            setComparisonEjectionTime(iteration.solveInfoEjectionTime);
            context.updateCeetronState({ type: CeetronActionType.SetViewerReadyState, payload: false });
            await new JobService().loadResults(projectId!, iteration.id, containerNameParam!).then(async (resultPath) => {
                //Disconnect current Ceetron socket for remote model
                if(comparisonIoSocket)
                    disconnectSocketAndClearModel(comparisonIoSocket, comparisonView)

                const token = await authContext.token().then();
                const { remoteModel, socket } = await createRemoteModel(token, handleCeetronError);
                comparisonView.removeAllModels();
                comparisonView.addModel(remoteModel);
                openComparisonModel(comparisonView, remoteModel, resultPath.data, () => {
                    setComparisonIoSocket(socket);
                    if (context.ceetronState.displayMode != DisplayMode.ISOVOLUME) {
                        comparisonView.ownerViewer.setPickHandler(pickHandler.bind(null, remoteModel));
                    }
                    const mainModel = mainView.getModelArray().filter(m => m instanceof cee.ug.RemoteModel)[0] as cee.ug.RemoteModel;
                    mainModel.setResultRangeChangedHandler(() => {
                        const resultId = mainModel.modelSpec.fringesResultId;
                        const mainSettings = mainModel.getScalarSettingsById(resultId);
                        const refSettings = remoteModel.getScalarSettingsById(resultId);
                        if (typeof mainSettings.rangeMinimum === 'number' && typeof mainSettings.rangeMaximum === 'number') {
                            refSettings.setRange(mainSettings.rangeMinimum, mainSettings.rangeMaximum);
                        }
                    });
                    matchCameraPosition(mainView, comparisonView);
                    linkCameraPosition(mainView, comparisonView);
                    loadAdditionnalGeometryData(comparisonView, true, iteration.id);
                    setComparisonIterationName(iteration.name);
                    setComparisonIteration(iteration);
                });
            });
        }
    }

    function onJobCompareClose() {
        context.updateCeetronState({ type: CeetronActionType.SetViewerReadyState, payload: true });
        setComparisonEjectionTime(undefined);
        setComparisonIoSocket(undefined);
        setComparisonMoldConstantRemoteModel(undefined);
        setComparisonCuttingPlane(undefined);
        setComparisonIsoVolume(undefined);
        setComparisonIterationName('');
        setComparisonIteration(undefined);
        if (comparisonView && comparisonIoSocket) {
            //Disconnect current Ceetron socket for remote model
            disconnectSocketAndClearModel(comparisonIoSocket, comparisonView)
        }
        setComparisonModel(undefined);
        setComparisonCoolingChannelsModel(undefined);
        showComparisonView.current = false;
        setTimeout(() => window.dispatchEvent(new Event('resize')), 0);
        if (mainView && comparisonView) {
            unlinkCameraPosition(mainView, comparisonView);
            const mainModel = mainView.getModelArray().filter(m => m instanceof cee.ug.RemoteModel)[0] as cee.ug.RemoteModel;
            mainModel.setResultRangeChangedHandler(() => { });
        }
    }

    function openComparisonModel(view: cee.View, remoteModel: cee.ug.RemoteModel | undefined, modelKey: string, cb?: () => void) {
        if (!remoteModel) {
            return;
        }
        // Trigger prop change event in child components.
        remoteModel.openModel(modelKey, (error, model) => {
            if (error) {
                console.error(error);
                return;
            }
            remoteModel.name = modelKey;
            setComparisonModel(remoteModel);
            setTimeout(() => window.dispatchEvent(new Event('resize')), 0);
            applyComparisonInitialSettings(view, remoteModel);
            remoteModel.setProgressHandler(data => {
                context.updateCeetronState({ type: CeetronActionType.SetViewerReadyState, payload: data.streamingStatus === cee.ug.StreamingStatus.DATA_STREAMING_COMPLETE })
                // when loading is done, re-setup animation
                if (data.streamingStatus === 2 && data.pctDone === 100
                    && !statesAreEqual(model.modelDirectory, 1)) {
                    setupStates(model, 1);
                    if (context.ceetronState.displayPreset != DisplayPreset.MOLD) {
                        matchScaleRange();
                    }
                }
            });
            if (cb) {
                cb();
            }
        });
    }
    const onJobCancel = (projectId: string, jobId: string) => {

    };

    function getDefaultDisplayModeValue() {
        return (projectType == ProjectType.Feasibility) && context.ceetronState.displayPreset == DisplayPreset.MOLD ?
            DisplayPreset.FREEZE_TIME : context.ceetronState.displayPreset;
    }

    function getIterationMaterialEjectionTemperature(){
        return iterationItem && iterationItem.parameters?.categories?.materials?.parts?.length ? iterationItem.parameters.categories.materials.parts[0].ejectionTemperature ? 
            iterationItem.parameters?.categories.materials.parts[0].ejectionTemperature : null : null;
    }

    return (
        <JobPage
            openBackdrop={false}
            onJobCancel={onJobCancel}
            backdropProgress={viewLoadProgress}
            onJobCompareClick={showTempView.current ? undefined : onJobCompare}
            content={
                <Grid ref={centerContainerRef} container direction='column' sx={{ height: '100%', position: "relative" }}>
                    <div ref={jobProjectSwitcherContainerRef}>
                        {!showComparisonView.current && <JobProjectSwitcher onJobSubmit={() => { }} onJobCancel={() => { }}
                            selected='results' projectType={projectType as ProjectType} project={projectId!} job={props.iteration} jobId={jobId} hwv={null} backdropFunc={null} />}

                        {showComparisonView.current && <Button startIcon={<CloseIcon />} className={classes.closeCompare}
                            onClick={onJobCompareClose}>{t("Close comparison")}</Button>}
                    </div>
                    <Grid container direction='row' justifyContent={showComparisonView.current ? 'space-evenly' : 'center'} alignItems='center' sx={{ position: 'absolute', top: '5em' }}>
                        {colorLegendSettingsInfo && <LegendSettingsPanel settings={colorLegendSettingsInfo} precision={5}
                            min={minRange}
                            max={maxRange}
                            ejectionTemperature={getIterationMaterialEjectionTemperature()}
                            displayPreset={context.ceetronState.displayPreset}
                            onClose={colorLegendsCloseHandler}
                            onReset={colorLegendSettingsResetHandler.current}
                            onSave={colorLegendSettingsSaveHandler.current} />}
                        {pickInfo && <PickInfoPanel pickInfo={pickInfo} precision={5} onClose={setPickInfo.bind(null, undefined)} />}
                        {!showComparisonView.current && 
                            <Grid item>
                                <IterationInfoPanel iteration={props.iteration} />
                            </Grid>
                        }
                        {showComparisonView.current && comparisonIteration &&
                            <Box sx={{width: "100%", display: "flex", justifyContent: "space-around"}}>
                                <Box>
                                    <Tooltip title={props.iteration.name} placement='top'>
                                        <Grid item className={classes.titleCompare} sx={{textAlign: "center"}}>{truncate(props.iteration.name, 15)}</Grid>
                                    </Tooltip>
                                    <IterationInfoPanel iteration={props.iteration} />
                                </Box>
                                <Box>
                                    <Tooltip title={comparisonIterationName} placement='top'>
                                        <Grid item className={classes.titleCompare} sx={{textAlign: "center"}}>{truncate(comparisonIterationName, 15)}</Grid>
                                    </Tooltip>
                                    <IterationInfoPanel iteration={comparisonIteration} />
                                </Box>
                            </Box>
                        }
                    </Grid>
                    <Box style={{ width: '100%', height: ceetronScenesContainerHeight, display: 'flex', flexDirection: 'row' }}>
                        <CeetronScene show={showTempView.current} onInit={handleTempViewerInit} onLoadProgress={setViewLoadProgress} socket={ioSocket} />
                        <CeetronScene show={!showTempView.current || showComparisonView.current} onInit={handleViewerInit} onLoadProgress={setViewLoadProgress} socket={ioSocket} />
                        <CeetronScene show={showComparisonView.current} isComparisonView={true} onInit={handleComparisonViewerInit} onLoadProgress={setViewLoadProgress} socket={comparisonIoSocket} />
                    </Box>
                </Grid>
            }
            rightControls={mainModel && mainView ?
                <>
                    {showTempView.current && <Box sx={{ height: '100%', overflowX: 'hidden', overflowY: 'auto', color: theme.palette.info.light }}>
                        <Typography variant="h6">{t(loadModelMessage)}</Typography>
                    </Box>}
                    {!showTempView.current && <Box sx={{ height: '100%', overflowX: 'hidden', overflowY: 'auto', paddingTop: '3.5em', position: "relative" }}>
                        <Typography variant="h6">{t("Display Mode")}</Typography>
                        {(!context.ceetronState.isViewerReady || isLoadingChannelGeometry) && <Box sx={{ display: 'flex', alignItems: "center", position: "absolute", top: "10px", color: theme.palette.text.primary }}>
                            <CircularProgress size="1.3em" sx={{ marginRight: "10px", color: theme.palette.grey[700] }} />
                            <Typography sx={{ color: theme.palette.grey[700] }}>{t("Working to update view...")}</Typography>
                        </Box>}
                        <Box id="results-display-modes">
                            <div className={classes.wrapper}>
                                <FormControl>
                                    <RadioGroup defaultValue={getDefaultDisplayModeValue()} value={context.ceetronState.displayPreset} onChange={handlePresetChange} >
                                        {(projectType == ProjectType.Design || (authContext.isSupervisor && projectType == ProjectType.Feasibility)) && (!showComparisonView.current) && <FormControlLabel id="mold-display-mode" value={DisplayPreset.MOLD} control={<Radio />} label={t("Mold")} disabled={!context.ceetronState.isViewerReady || !mainMoldConstantRemoteModel || (showComparisonView.current && !comparisonMoldConstantRemoteModel)} />}
                                        {(hasDefaultCase(mainModel.modelDirectory) || mainMoldConstantRemoteModel) && <FormControlLabel id="plastic-volume-display-mode" value={DisplayPreset.PLASTIC_VOLUME} control={<Radio />} label={t("Plastic Volume")} disabled={!context.ceetronState.isViewerReady} />}
                                        {(hasPlasticSkinCase(mainModel.modelDirectory) || mainMoldConstantRemoteModel) && <FormControlLabel id="plastic-surface-display-mode" value={DisplayPreset.PLASTIC_SURFACE} control={<Radio />} label={t("Plastic Surface")} disabled={!context.ceetronState.isViewerReady} />}
                                        {(hasFreezeTimeResults(mainModel.modelDirectory) || mainMoldConstantRemoteModel) && <FormControlLabel id="freeze-time-display-mode" value={DisplayPreset.FREEZE_TIME} control={<Radio />} label={t("Freeze Time")} disabled={!context.ceetronState.isViewerReady} />}
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </Box>
                        {((context.ceetronState.displayPreset === DisplayPreset.MOLD) || !!(coolingChannelsModel?.partCount || comparisonCoolingChannelsModel?.partCount)) && <ThemeProvider theme={customTheme}>
                            <Box id="category-types-list">
                                <div className={classes.wrapper}>
                                    <PartList model={mainModel} parameters={parameters} partsVisibility={context.ceetronState.visibility} coolingChannelsModel={coolingChannelsModel} comparisonCoolingChannelsModel={comparisonCoolingChannelsModel} isLoadingGeometry={isLoadingChannelGeometry}/>
                                </div>
                            </Box>
                        </ThemeProvider>}
                        {context.ceetronState.isDisplayModeEnabled && <Box id="cutplane-isovolume-display-modes">
                            <div className={classes.wrapper}>
                                <FormControl>
                                    <RadioGroup row defaultValue={DisplayMode.CUTTING_PLANE} onChange={handleModeChange}>
                                        <FormControlLabel id="cutting-plane-display-mode" value={DisplayMode.CUTTING_PLANE} control={<Radio />} label={t("Cutting plane")} disabled={!context.ceetronState.isViewerReady}/>
                                        <FormControlLabel id="isovolume-display-mode" value={DisplayMode.ISOVOLUME} sx={{ marginLeft: '-2px', marginRight: '0' }} control={<Radio />} label={t("FilterPlastic")} disabled={!context.ceetronState.isViewerReady}/>
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </Box>
                        }
                        {context.ceetronState.isDisplayModeEnabled && context.ceetronState.displayMode === DisplayMode.CUTTING_PLANE
                            && cuttingPlane && <Box id="cutplane-slider-section"><CuttingPlane view={mainView} 
                                                                                                projectType={projectType as ProjectType}
                                                                                                cuttingPlane={cuttingPlane} 
                                                                                                model={mainModel} 
                                                                                                comparisonView={comparisonView} 
                                                                                                comparisonModel={comparisonModel} 
                                                                                                comparisonCuttingPlane={comparisonCuttingPlane}
                                                                                                updateCuttingPlane={updateCuttingPlane} /></Box>}
                        {context.ceetronState.isDisplayModeEnabled && context.ceetronState.displayMode === DisplayMode.ISOVOLUME
                            && isoVolume && <Box id="isovolume-slider-section"><IsoVolume model={mainModel} isoVolume={isoVolume} comparisonIsoVolume={comparisonIsoVolume} ejectionTemperature={getIterationMaterialEjectionTemperature()}/></Box>}
                        {context.ceetronState.isMainTimeStepsEnabled && context.ceetronState.vtfxStates[0].stateInfoArray.length > 1 &&
                            <Box id="simulation-time-control">
                                <StatesControl model={mainModel} ejectionTime={props.iteration.solveInfoEjectionTime} modelIndex={0} name={showComparisonView.current ? truncate(props.iteration.name, 30) : ''} />
                            </Box>}
                        {showComparisonView.current && context.ceetronState.isComparisonTimeStepsEnabled && context.ceetronState.vtfxStates[1].stateInfoArray.length > 1 &&
                            <Box id="simulation-time-control">
                                <StatesControl model={comparisonModel!} ejectionTime={comparisonEjectionTime!} modelIndex={1} name={showComparisonView.current ? truncate(comparisonIterationName, 30) : ''} />
                            </Box>}
                        {authContext.isSupervisor && context.ceetronState.displayPreset == DisplayPreset.FREEZE_TIME &&
                            <Box className={classes.container}>
                                <Typography variant="h6" className={classes.headers}>{t("Frozen Wall Thickness")}</Typography>
                                <OutlinedInput disabled id="outlined-adornment-weight"
                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                    aria-describedby="outlined-weight-helper-text"
                                    sx={{
                                        width: '100%',
                                        marginRight: '1em',
                                        marginTop: '1em'
                                    }}
                                    value={wallThickness} />
                            </Box>}
                    </Box>}
                </> : <>
                    <Box sx={{ height: '100%', overflowX: 'hidden', overflowY: 'auto', color: theme.palette.info.light }}>
                        <Typography variant="h6">{t(loadModelMessage)}</Typography>
                    </Box>
                </>
            }
        />
    )
}

