import * as cee from "@ceetron/common/CeeEnvisionWebComponents";
import { io } from "socket.io-client";
import { PartType, VisibilityState, VtfxCase, VtfxScalarResults } from "../store/job/ceetron-context";
import { JobSolveInputsParameters } from "../store/job/job-data";
import { AuthContextType } from "../auth/AuthenticationContext";


export const NUMBER_OF_LEVELS = 40;
export const aboveRangeColor = new cee.Color3(0.5, 0, 0);
export const belowRangeColor = new cee.Color3(0.5, 0, 0.5);


export function createView(canvas: HTMLCanvasElement, container: HTMLElement, userData: AuthContextType) {
    const session = new cee.CloudSession();

    const viewer = session.addViewer(canvas, {
        highlightMode: cee.HighlightMode.SIMPLE,
    });

    if (!viewer) {
        throw new Error("No WebGL support");
    }

    // Resize to fill window
    const handleWindowResizeEvent = () => {
        viewer.resizeViewer(container.clientWidth, container.clientHeight);
    };

    window.addEventListener("resize", handleWindowResizeEvent);
    handleWindowResizeEvent();

    const view = viewer.addView();
    view.backgroundColor = new cee.Color3(1, 1, 1);

    //no blue box
    view.overlay.infoBoxVisible = userData.isEmployee ?? false;

    // Setup timer to keep the WebViz viewer up-to-date
    const myAnimationFrameCallback = (highResTimestamp: any) => {
        session.handleAnimationFrameCallback(highResTimestamp);
        requestAnimationFrame(myAnimationFrameCallback);
    };
    requestAnimationFrame(myAnimationFrameCallback);

    return view;
}

export async function createRemoteModel(authToken: string | undefined, errorCallback: (error: Error) => void) {

    const vizServerUrl = C3ServerUrl();
    console.log("Connecting to CeeCloudServer using url: " + vizServerUrl);

    const socket = io(vizServerUrl, {
        rejectUnauthorized: false,
        transports: ['websocket'],
        upgrade: false,
        withCredentials: true,
        reconnection: true,
        auth: {
            token: authToken
        }
    });

    socket.on("connect_error", (_error: Error) => {
        socket.disconnect();
        console.error("Error with connection to CeeCloudServer. " + vizServerUrl);
        errorCallback(new Error("Error connecting to SimForm visualization server. If error persists please contact SimForm support."));
        console.error(_error.message);
    });

    socket.on("server_message", (message) => {
        console.error(message);
        const error = new Error(message);
        errorCallback(error);
    });

    return {
        remoteModel: new cee.ug.RemoteModel(socket),
        socket: socket
    };
}

export function getScalarResultByName(model: cee.ug.RemoteModel, name: string): cee.ug.ResultInfo | null {
    const results = model.modelDirectory?.scalarResultArray.filter(r => r.name === name);
    return results?.length ? results[0] : null;
}

export function hasDefaultCase(modelDirectory?: cee.ug.ModelDirectory): boolean {
    return modelDirectory ? modelDirectory.vtfxCaseInfoArray.some(i => i.name === VtfxCase.DEFAULT_VIEW) : false;
}

export function hasPlasticSkinCase(modelDirectory?: cee.ug.ModelDirectory): boolean {
    return modelDirectory ? modelDirectory.vtfxCaseInfoArray.some(i => i.name === VtfxCase.PLASTIC_SKIN) : false;
}

export function hasFreezeTimeCase(modelDirectory?: cee.ug.ModelDirectory): boolean {
    return modelDirectory ? modelDirectory.vtfxCaseInfoArray.some(i => i.name === VtfxCase.FREEZE_TIME) : false;
}

export function hasFreezeTimeResults(modelDirectory?: cee.ug.ModelDirectory): boolean {
    return modelDirectory ? modelDirectory.scalarResultArray.some(r => r.name === VtfxScalarResults.FREEZE_TIME_RESULT) : false;
}

export function getVisibilityState(model: cee.ug.RemoteModel, parametersData: JobSolveInputsParameters): VisibilityState {
    const result = {
        state: {},
        skipAction: false
    } as VisibilityState;
    model.getPartSettingsArray().forEach(p => {
        const name = model.modelDirectory.getPartNameById(p.geometryIndex, p.partId);
        const item = {
            type: getPartTypeByName(name, parametersData),
            value: p.visible
        };
        if (result.state) {
            result.state[name] = item;
        }
    });
    return result;
}

export function setVisibilityByType(visibilityState: VisibilityState, type: PartType, visibility: boolean): VisibilityState {
    const result: VisibilityState = {
        skipAction: visibilityState.skipAction,
        state: {}
    }
    Object.keys(visibilityState.state).forEach(k => {
        result.state[k] = {
            type: visibilityState.state[k].type,
            value: visibilityState.state[k].type === type ? visibility : visibilityState.state[k].value
        }
    });
    return result;
}

export function setVisibilityByName(visibilityState: VisibilityState, name: string, visibility: boolean): VisibilityState {
    const result: VisibilityState = {
        skipAction: visibilityState.skipAction,
        state: {}
    }
    Object.keys(visibilityState.state).forEach(k => {
        result.state[k] = {
            type: visibilityState.state[k].type,
            value: k === name ? visibility : visibilityState.state[k].value
        }
    });
    return result;
}

export function getPartTypeByName(name: string, parametersData: JobSolveInputsParameters): PartType {
    return parametersData.Model.Molds.map(m => m.name).includes(name) ? PartType.MOLD : PartType.PLASTIC;
}

export async function loadPartInfo(model: cee.ug.RemoteModel, part: cee.ug.PartSettings, resultId: number): Promise<cee.ug.QueryBulkCalculationValues> {
    const query = new cee.ug.QueryBulkCalculation(model);

    return new Promise((resolve, reject) => {
        query.executePartQuery(model.currentFrameIndex,
            part.geometryIndex, part.partId, cee.ug.ResultType.SCALAR, resultId, (error, data) => {
                if (data) {
                    resolve(data[0]);
                }
                reject(error);
            });
    })
}

export function findDefaultTimeStepIndex(model: cee.ug.RemoteModel, solveInfoEjectionTime: number): number {
    if (typeof solveInfoEjectionTime != 'number') {
        return model.modelDirectory.stateInfoArray.length - 1;
    }
    const safeEjectionTimeStep = model.modelDirectory.stateInfoArray
        .map((si, index) => {
            return {
                scalarResultIdArr: si.scalarResultIdArr,
                index
            };
        })
        .filter(state => state.scalarResultIdArr.includes(2))[0]; // look for 1st time step with a freeze time value mapped
    return safeEjectionTimeStep ? safeEjectionTimeStep.index : model.modelDirectory.stateInfoArray.length - 1;
}

function C3ServerUrl() {

    if (process.env.REACT_APP_VISUALIZATION_URL) {
        return process.env.REACT_APP_VISUALIZATION_URL;
    }

    const c3Port = 8998;
    const protocol = window.location.protocol === "https:" ? "https://" : "http://";
    const hostname = window.location.hostname !== 'localhost' ? window.location.hostname : '127.0.0.1';
    return protocol + hostname + ":" + c3Port;

}

export function setTemperatureResultsSettings(model: cee.ug.RemoteModel) {
    const temperatureResult = getScalarResultByName(model, VtfxScalarResults.TEMPERATURE_RESULT);
    const settings = temperatureResult ? model.getScalarSettingsById(temperatureResult.id) : model.getScalarSettingsArray()[0];
    settings.levelCount = NUMBER_OF_LEVELS;
    model.getScalarSettingsArray().forEach(ss => {
        ss.aboveRangeColor = aboveRangeColor;
        ss.belowRangeColor = belowRangeColor;
    });
}

export const swapModelAndConstantRemoteModel = (
    toConstantRemoteModel: boolean,
    view: cee.View | undefined,
    remoteModel: cee.ug.RemoteModel | undefined,
    constantRemoteModel: cee.cug.ConstantRemoteModel | undefined,
    cb?: () => void
) => {
    if (view && remoteModel && constantRemoteModel) {
        const modelToSwapOut = toConstantRemoteModel ? remoteModel : constantRemoteModel;
        const modelToSwapIn = !toConstantRemoteModel ? remoteModel : constantRemoteModel;
        const allModelsInView = view.getModelArray();
        const up = view.camera.getUp();
        const position = view.camera.getPosition();
        const direction = view.camera.getDirection();
        if (allModelsInView.includes(modelToSwapOut)) {
            view.removeModel(modelToSwapOut);
        }
        if (!allModelsInView.includes(modelToSwapIn)) {
            if (modelToSwapIn instanceof cee.ug.RemoteModel) {
                view.addModel(modelToSwapIn);
                const openCompletedCallback = (view: cee.View, error: Error, model: cee.ug.RemoteModel) => {
                    setTemperatureResultsSettings(model);
                    // reposition the camera where it used to be
                    view.camera.setViewpoint(position, direction, up);
                    if (cb) {
                        cb();
                    }
                };
                modelToSwapIn.openModel(modelToSwapIn.name, openCompletedCallback.bind(null, view));
            } else {
                view.addModel(modelToSwapIn);
                if (cb) {
                    cb();
                }
            }
        } else {
            if (cb) {
                cb();
            }
        }
    }
}