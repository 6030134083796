import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Channel } from "../../../store/job/channel";
import JobContext from "../../../store/job/job-context";
import { RepairBaffleOperator } from "../operators/RepairBaffleOperator";
import { GetNodeFromId } from "../../../services/TreeviewFunctions";
import { HoopsEntitiesContext } from "../../../store/job/hoops-entities-context";
import { Face, areSyntheticMeshesEqual } from "../../../utils/hoops.utils";
import { SyntheticFace, TreeviewFace } from "../../../store/job/job-data";
import clone from "../../../utils/clone";

export type RepairBaffleStartCallback = () => void;
export type RepairBaffleCompleteCallback = () => void;
export type RepairBaffleErrorCallback = (message: string) => void;

export function useRepairBaffle(hwv: Communicator.WebViewer) {
    const jobContext = useContext(JobContext);
    const { getNodeIdFromMesh, unregisterMeshes, registerColoredMesh } = useContext(HoopsEntitiesContext);
    const [enabled, setEnabled] = useState<boolean>(false);

    const operatorId = useMemo(() => {
        if (hwv && jobContext.IsTreeLoaded) {
            return hwv.registerCustomOperator(new RepairBaffleOperator(hwv));
        }
        return Communicator.OperatorId.None;
    }, [jobContext.IsTreeLoaded, hwv]);

    useEffect(() => {
        return () => {
            hwv && hwv.unregisterCustomOperator(operatorId);
        }
    }, [hwv, operatorId]);


    const updateSyntheticNodeIds = useCallback((faces: (Face | SyntheticFace)[]) => {
        for (const face of faces) {
            if (Channel.isSyntheticFace(face)) {
                const nodeId = getNodeIdFromMesh(face.config);

                if (nodeId) {
                    face.nodeId = nodeId;
                    face.faceIndex = 0;
                }
            }
        }
    }, [getNodeIdFromMesh]);

    const start = useCallback(async (baffleId: string, channel: Channel, completeCb: RepairBaffleCompleteCallback, errorCb: RepairBaffleErrorCallback) => {
        if (!hwv || operatorId === Communicator.OperatorId.None || enabled) {
            return;
        }
        const operator = hwv.operatorManager.getOperator(operatorId) as RepairBaffleOperator;
        const baffle = channel.baffles.find(b => b.id === baffleId);

        if (!baffle) {
            return;
        }


        operator.config = clone(baffle.config);

        operator.onSurfaceCreated = async mesh => {
            const nodeId = await registerColoredMesh({ mesh, color: Communicator.Color.blue() })
            const face = {
                nodeId,
                faceIndex: 0,
                path: Object.values(jobContext.Tree).find(i => i.isRoot === true)?.path ?? '',
                config:mesh
            }

            operator.config.push(face);
            await operator.buildEdgeMap();
            jobContext.editChannelBaffle(channel.id, baffleId, operator.config);
        };

        operator.onSurfaceRemoved = async meshes => {
            const newConfig: (SyntheticFace | TreeviewFace)[] = [];

            for (const face of operator.config) {
                if (Channel.isSyntheticFace(face) && meshes.some(m => areSyntheticMeshesEqual(m, face.config))) {
                    await unregisterMeshes([face.config]);
                } else {
                    newConfig.push(face);
                }
            }

            operator.config = newConfig;
            await operator.buildEdgeMap();
            jobContext.editChannelBaffle(channel.id, baffleId, operator.config);
        }

        operator.onClose = () => {
            hwv.operatorManager.remove(operatorId);
            setEnabled(false);
            completeCb();
        };

        setEnabled(true);

        hwv.operatorManager.push(operatorId);

        await operator.buildEdgeMap();
    }, [hwv, operatorId, enabled, jobContext, updateSyntheticNodeIds]);


    return {
        start,
        enabled
    }
}
