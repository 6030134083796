import { Grid, Stack, Typography, useTheme } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { PartType, VtfxScalarResults } from "../../store/job/ceetron-context";
import { useContext } from "react";
import { TemperatureUnit, UiSettingsContext } from "../../store/uiSettings/UiSettingsContext";
import { convertTemperature, getUnit } from "../../utils/units.utils";
import * as cee from "@ceetron/common/CeeEnvisionWebComponents";
import { useTranslation } from 'react-i18next';

export interface PickInfo {
    value: number;
    scalarResultInfo: cee.ug.ResultInfo;
    partName: PartType | string;
    hitItem: cee.ug.HitItem;
}

interface PickInfoPanelProps {
    pickInfo: PickInfo,
    precision: number,
    onClose: () => void
}

function formatValue(pickInfo: PickInfo, precision: number, preferedUnit: TemperatureUnit): string {    
        const convertedValue = convertTemperature({
            value: pickInfo.value,
            unit: getUnit(pickInfo.scalarResultInfo) as TemperatureUnit
        }, preferedUnit);
        return convertedValue.value ? convertedValue.value.toFixed(precision): '' + ' ' + convertedValue.unit;
    }

export const PickInfoPanel = (props: PickInfoPanelProps) => {
    const theme = useTheme();
    const uiSettings = useContext(UiSettingsContext);
    const { t } = useTranslation();
    return (
        <Grid container id="pick_info_panel_container" sx={{
            position: 'absolute',
            top: '0.3em',
            maxWidth: '210px',
            right: '3px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItem: 'flex-end',
            padding: '0.5em 1.25em 0.5em 1.25em',
            borderRadius: '8px',
            boxShadow: `0px 2px 1px -1px ${theme.palette.primary.dark}, 0px 1px 1px 0px ${theme.palette.primary.dark}, 0px 1px 3px 0px rgba(0,0,0,0.12)`,
            border: '1px solid transparent',
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.info.dark,
            zIndex: 2
        }}>
            <Grid item>
                <Stack alignItems="left" direction="row">
                    <Typography sx={{
                        width: '100%',
                        fontWeight: 'bold',
                        fontSize: '1.2em',
                        padding: '0.2em 0em 0.5em 0em',
                        color: theme.palette.primary.main
                    }}>
                        <CloseIcon sx={{
                            width: '0.75em',
                            height: '0.75em',
                            marginLeft: '1.5em',
                            marginRight: '-0.25em',
                            borderRadius: '0.75em',
                            position: 'relative',
                            cursor: 'pointer',
                            float: 'right',
                            color: theme.palette.error.contrastText,
                            backgroundColor: theme.palette.primary.dark,
                        }} onClick={props.onClose} />
                        {t(props.pickInfo.scalarResultInfo.name)}
                    </Typography>
                </Stack>
            </Grid>
            <Grid item>
                <Stack alignItems="left" direction="column">
                    <Typography sx={{
                        width: '100%',
                        marginBotton: '1em',
                        marginTop: '1em'
                    }}>
                        {formatValue(props.pickInfo, props.precision, uiSettings.uiSettings.temperatureUnit)} <br />
                        {t(props.pickInfo.partName)}
                    </Typography>
                </Stack>
            </Grid>
        </Grid>
    )
}