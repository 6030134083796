import { Button, Grid, Input, InputAdornment, Stack, Typography, useTheme, DialogActions, Tooltip, Fab } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { useContext, useEffect, useState } from "react";
import { TemperatureUnit, UiSettingsContext } from "../../store/uiSettings/UiSettingsContext";
import { VtfxScalarResults } from "../../store/job/ceetron-context";
import { convertTemperature, getUnit } from "../../utils/units.utils";
import { makeStyles } from "@mui/styles";
import { DisplayPreset } from "../../store/job/ceetron-context";
import { useTranslation } from 'react-i18next';
import DeviceThermostatOutlinedIcon from '@mui/icons-material/DeviceThermostatOutlined';

export interface LegendSettingsInfo {
    scalarResultInfo: cee.ug.ResultInfo;
    min: number;
    max: number
}

export interface ColorSettingsInfo {
    min: number | undefined, max: number | undefined
}

interface LegendSettingsPanelProps {
    settings: LegendSettingsInfo,
    precision: number,
    max: number | null,
    min: number | null,
    ejectionTemperature: number | null,
    displayPreset: DisplayPreset,
    onSave: (min: number, max: number, displayedUnit: string) => void,
    onReset: (max: number | null, min: number | null) => void,
    onClose: () => void
}

export const LegendSettingsPanel = (props: LegendSettingsPanelProps) => {
    const theme = useTheme();
    const uiSettingsContext = useContext(UiSettingsContext);
    const [min, setMin] = useState<number>(0);
    const [max, setMax] = useState<number>(0);
    const [displayedUnit, setDisplayedUnit] = useState<string>('');
    const { t } = useTranslation();

    const classes = makeStyles({
        icon: {
            position: "relative",
            padding: "5px 10px 5px 10px",
            color: theme.palette.primary.dark,
            marginLeft: "10px"
        },
        btn: {
            color: theme.palette.primary.dark,
            backgroundColor: theme.palette.secondary.dark,
            textTransform: 'none',
            fontSize: '1.1em',
            cursor: 'pointer',
            borderRadius: "20px",
            margin: "5px",
            "&:hover": {
                backgroundColor: theme.palette.info.dark
            }
        }
    })();

    useEffect(() => {
        if (props.settings.scalarResultInfo.name === VtfxScalarResults.TEMPERATURE_RESULT) {
            const convertedMax = convertTemperature({
                value: props.settings.max,
                unit: getUnit(props.settings.scalarResultInfo) as TemperatureUnit
            }, uiSettingsContext.uiSettings.temperatureUnit);
            const convertedMin = convertTemperature({
                value: props.settings.min,
                unit: getUnit(props.settings.scalarResultInfo) as TemperatureUnit
            }, uiSettingsContext.uiSettings.temperatureUnit);
            setMax(parseFloat(convertedMax.value.toFixed(props.precision)));
            setMin(parseFloat(convertedMin.value.toFixed(props.precision)));
            setDisplayedUnit(convertedMax.unit);
        } else {
            setMin(parseFloat(props.settings.min.toFixed(props.precision)));
            setMax(parseFloat(props.settings.max.toFixed(props.precision)));
            setDisplayedUnit(getUnit(props.settings.scalarResultInfo));
        }
    }, []);

    return (
        <Grid id="legend_settings_panel_container" container sx={{
            position: 'absolute',
            top: '0.3em',
            maxWidth: '16.5em',
            left: '0.4em',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItem: 'flex-start',
            backgroundColor: theme.palette.info.dark,
            padding: '0.5em 1.25em 0.5em 1.25em',
            borderRadius: '8px',
            boxShadow: `0px 2px 1px -1px ${theme.palette.primary.dark}, 0px 1px 1px 0px ${theme.palette.primary.dark}, 0px 1px 3px 0px rgba(0,0,0,0.12)`,
            border: '1px solid transparent',
            color: theme.palette.primary.contrastText,
            zIndex: 2
        }}>
            <Grid item>
                <Stack alignItems="left" direction="row">
                    <Typography sx={{
                        width: '100%',
                        fontWeight: 'bold',
                        fontSize: '1.2em',
                        padding: '0.2em 0em 0.5em 0em',
                        color: theme.palette.primary.main
                    }}>
                        <CloseIcon sx={{
                            width: '0.75em',
                            height: '0.75em',
                            marginLeft: '1.5em',
                            marginRight: '-0.2em',
                            marginTop: '-0.2em',
                            borderRadius: '0.75em',
                            position: 'relative',
                            cursor: 'pointer',
                            float: 'right',
                            color: theme.palette.error.contrastText,
                            backgroundColor: theme.palette.primary.dark,
                        }} onClick={props.onClose} />
                        {t("Legend Settings")}
                    </Typography>
                </Stack>
            </Grid>
            <Grid item>
                <Stack direction="column">
                    {t("Max")}:
                    <Stack direction="row">
                        <Input
                            sx={{
                                width: '150px',
                                padding: '0em 0.5em 0em 0.5em',
                                backgroundColor: theme.palette.info.contrastText,
                                color: theme.palette.primary.dark
                            }}
                            value={max}
                            id='legend_settings_panel_max_input'
                            onChange={(event: any) => setMax(parseFloat(event.target.value))}
                            inputProps={{
                                type: 'number'
                            }}
                            endAdornment={<InputAdornment position="end">{displayedUnit}</InputAdornment>}
                        />

                        {props.ejectionTemperature &&
                            (props.displayPreset == DisplayPreset.PLASTIC_SURFACE ||
                                props.displayPreset == DisplayPreset.PLASTIC_VOLUME) &&
                            <Tooltip title={t("Set Safe Ejection Temperature")}>
                                <Fab id="thermostat-icon-id" className={classes.icon} variant="extended" size="small" color="default" >
                                    <DeviceThermostatOutlinedIcon id ="legend_settings_safe_ejection_icon" onClick={() => { setMax(Number(props.ejectionTemperature)); }} />
                                </Fab>
                            </Tooltip>}

                    </Stack>
                    {t("Min")}: <Input
                        sx={{
                            width: '150px',
                            padding: '0em 0.5em 0em 0.5em',
                            backgroundColor: theme.palette.info.contrastText,
                            color: theme.palette.primary.dark
                        }}
                        value={min}
                        onChange={(event: any) => setMin(parseFloat(event.target.value))}
                        inputProps={{
                            type: 'number'
                        }}
                        endAdornment={<InputAdornment position="end">{displayedUnit}</InputAdornment>} />
                    <Typography sx={{
                        width: '100%',
                        marginBotton: '1em',
                        fontSize: '0.95em',
                        marginTop: '1em'
                    }}>{t("Values above the range are shown in dark red, below in purple.")}</Typography>
                    <DialogActions>
                        <Button onClick={() => props.onReset(props.max, props.min)}
                            className={classes.btn} variant={'contained'} size="small">{t("Reset")}</Button>
                        <Button onClick={() => props.onSave(min, max, displayedUnit)}
                            className={classes.btn} variant={'contained'} size="small">{t("Save")}</Button>
                    </DialogActions>
                </Stack>
            </Grid>
        </Grid>
    )
}