import { PropsWithChildren, useEffect } from "react";
import { HoopsColorContext, useColorContext } from "./hoops-color-context";
import { HoopsVisibilityContext, useVisibilityContext } from "./hoops-visibility-context";
import { VisibilityStateActionType } from "./hoops-visibility-reducer";
import JobData from "./job-data";
import { HoopsEntitiesContext, useEntitiesContext } from "./hoops-entities-context";

export type HoopsContextProps = {
    hwv: Communicator.WebViewer | null,
    jobContext: JobData
}

export function HoopsContext(props: PropsWithChildren<HoopsContextProps>) {
    const { updateColor, getFaceOriginalColor } = useColorContext(props.jobContext, props.hwv);
    const { registerChannelMeshes, registerColoredMesh, getNodeIdFromMesh, meshes, getMeshForNodeId, unregisterMeshes } = useEntitiesContext(props.jobContext, props.hwv);
    const { state: visibilityState, updateVisibility, getVisibility } = useVisibilityContext(meshes, props.hwv);

    useEffect(() => {
        if(!props.jobContext.IsTreeLoaded){
            updateVisibility({
                type: VisibilityStateActionType.REINITIALISE
            });
        }
    }, [props.jobContext.IsTreeLoaded])

    return (
        <HoopsEntitiesContext.Provider value={{ registerChannelMeshes, getNodeIdFromMesh, getMeshForNodeId, registerColoredMesh, unregisterMeshes }}>
            <HoopsVisibilityContext.Provider value={{ mode: visibilityState.mode, updateVisibility, getVisibility }}>
                <HoopsColorContext.Provider value={{ getFaceOriginalColor, updateColor }}>
                    {props.children}
                </HoopsColorContext.Provider>
            </HoopsVisibilityContext.Provider>
        </HoopsEntitiesContext.Provider>
    )
}
