import axios, { AxiosResponse } from "axios";
import BlobInfo from "../store/job/blob-info";
import CadInfo from "../store/job/cad-info";
import FileInfo from "../store/job/file-info";
import ResourceCacheService from "./ResourceCacheService";

export interface ImportJobResults {
    errors: ImportJobResult[] | undefined,
    warnings: ImportJobResult[] | undefined,
    partCategories: ImportJobPathCategory[] | undefined,
    imported: boolean,
    jobId: string
}

export interface ImportJobPathCategory {
    category: string;
    paths: ImportJobPath[] | undefined;
}

export interface ImportJobResult {
    message: string;
    detail: string;
}

export interface ImportJobPath {
    name: string;
    values: string[] | undefined;
}

export default class FileManagementService {
    readonly resourceCache?: ResourceCacheService;

    constructor() {
        if ("caches" in globalThis) {
            this.resourceCache = new ResourceCacheService();
        }
    }

    async uploadData(formData: FormData, projectId: string, jobId: string): Promise<FileInfo> {
        try {
            const response = await axios.post('/api/file', formData, {
                headers: { "Content-Type": "multipart/form-data" },
                params: {
                    projectId,
                    jobId
                }
            });
            return response.data;

        } catch (error) {
            throw new Error('Error uploading file');
        }
    }

    async invokeConvertion(projectId: string, jobId: string, filename: string): Promise<CadInfo> {
        try {
            let encodedProjectId = encodeURI(projectId);
            let encodedJobId = encodeURI(jobId);
            const response = await axios.get('/api/file/convert', { params: { filename, projectId: encodedProjectId, jobId: encodedJobId } });
            return response.data;
        }
        catch (error) {
            throw new Error('Error converting file');
        }
    }

    async uploadCompression(projectid: string, jobname: string, description: string, data: string | ArrayBuffer | null): Promise<ImportJobResults> {
        try {
            const params = { projectid, jobname, description, data };
            const config = { headers: { 'Content-Type': 'application/json' } };
            const response = await axios.post('/api/file/uploadcompression', params, config);
            return response.data;
        } catch (error: any) {
            if (error.response == undefined) {
                throw new Error("Error uploading zip file");
            } else throw Error(error.response.data);
        }
    }

    async uploadGeometries(formData: FormData): Promise<any[]> {
        try {
            const response = await axios.post('/api/file/uploadgeometries', formData);
            return response.data
        } catch (error) {
            throw new Error('Error uploading geometrical files');
        }
    }

    async downloadFile(path: string, containerName: string, version: string | null = null): Promise<Blob> {
        try {
            if (version && this.resourceCache) {
                return this.downloadWithCache(this.resourceCache, path, version, containerName);
            }
            else {
                const response = await this.downloadNoCache(path, containerName);
                return response.data
            }
        } catch (error) {
            throw new Error('Error downloading file stream.');
        }
    }

    async downloadBlob(blobInfo: BlobInfo, containerName: string): Promise<Blob> {
        return await this.downloadFile(blobInfo.uri, containerName, blobInfo.versionId);
    }

    async removeBlobs(urls: string[], containerName: string){
        try {
            const response = await axios.delete(`/api/file/removeBlobs/${containerName}`, {
                data: urls
            });
            return response.data
        } catch (error) {
            throw new Error('Error removing files');
        }
    }

    private async downloadNoCache(path: string, containerName: string): Promise<AxiosResponse> {
        return await axios.get('/api/file/downloadBlob', {
            params: { path, containerName},
            responseType: 'blob'
        });
    }

    private async downloadWithCache(cache: ResourceCacheService, path: string, version: string, containerName: string,): Promise<Blob> {
        var urlWithVersion = new URL(path);
        urlWithVersion.searchParams.set("version", version.toString());

        const cachedBlob = await cache.tryGetAsBlob(urlWithVersion);
        if (cachedBlob) {
            return cachedBlob;
        }

        const response = await this.downloadNoCache(path, containerName);
        if (response.status === 200) {
            await cache.put(urlWithVersion, response);
        }
        return response.data;
    }
}