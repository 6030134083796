import axios, { AxiosResponse } from 'axios';
import CadInfo from '../store/job/cad-info';
import { Channel, ChannelProps } from '../store/job/channel';
import { serializeJobContextState } from '../store/job/job-context';
import Iteration from '../store/project/iteration';
import JobData, { jobCategoriesData, JobChannelsBodiesData, JobDetailsBase, JobSolveInputsParameters, JobForm, Status, updateJobInfo, SampleJobType, CategoriesData, CategoriesData_LegacyV1, MaterialsMap } from '../store/job/job-data';
import FileManagementService from './FileManagementService';
import ProjectService from './ProjectsService';
import CoolingProperties from '../store/job/cooling-properties';

export default class JobService {

    async save(projectId: string, jobId: string, containerName: string, content: string): Promise<string> {
        try {
            const response = await axios.post('/api/job',
                { content: content },
                {
                    headers: { 'Content-Type': 'application/json' },
                    params: {
                        projectId,
                        jobId,
                        containerName
                    }
                }
            );
            return response.data;
        } catch (error) {
            throw new Error('An error has occurred while saving the job.');
        }
    }

    public static async saveContent(projectId: string, jobId: string, containerName: string, content: string): Promise<string> {
        try {
            const response = await axios.post('/api/job/saveContent',
                { content: content },
                {
                    headers: { 'Content-Type': 'application/json' },
                    params: {
                        projectId,
                        jobId,
                        containerName
                    }
                }
            );
            return response.data;
        } catch (error) {
            throw new Error('An error has occurred while saving the job.');
        }
    }

    async submit(projectId: string, jobId: string, containerName: string, content: string): Promise<string> {
        try {
            const response = await axios.post('/api/job/submit',
                { content: content },
                {
                    headers: { 'Content-Type': 'application/json' },
                    params: {
                        projectId,
                        jobId,
                        containerName
                    }
                }
            );
            return response.data;
        } catch (error) {
            throw new Error('An error has ocurred while submitting of the job.');
        }
    }

    async getJobs(): Promise<any[]> {
        try {
            const response = await axios.get('/api/job');
            return response.data;
        } catch (error) {
            throw new Error('Error getting jobs');
        }
    }

    async getJobStatus(projectId: string, jobId: string, containerName: string): Promise<Status> {
        try {
            const response = await axios.get('/api/job/status', {
                params: {
                    projectId,
                    jobId,
                    containerName
                }
            });
            return response.data;
        } catch (error) {
            throw new Error('Error getting jobs');
        }
    }

    async loadResults(projectId: string, jobId: string, containerName: string): Promise<AxiosResponse<string>> {
        try {
            return await axios.post('/api/VisualizationCache/LoadSolverResults', {
                "JobId": jobId, "Project": projectId, containerName
            });
        } catch (error) {
            throw new Error('Error getting url');
        }
    }

    async updateGeometricalDetails(project: string, jobId: string, info: string, containerName: string, customSolverSettings: string) {
        try {
            const data = { project, jobId, info, containerName, customSolverSettings };
            const response = await axios.post('/api/job/addsolverparameters', data);
            return response.data;
        }
        catch (error) {
            throw new Error('Error converting file to blob');
        }
    }

    public static async saveThumbnail(imgData: Blob, projectId: string, jobId: string, containerName: string){
        try {
            const formData = new FormData();
            formData.append('projectThumbnail', imgData);

            const response = await axios.post(`/api/job/${projectId}/${jobId}/${containerName}/saveThumbnail`, formData);
            return response.data;
        } catch {
            throw new Error('Error getting jobs');
        }
    }

    public static async isThumbnailExists(projectId: string, jobId: string, containerName: string){
        try {
            const response = await axios.get(`/api/job/${projectId}/${jobId}/${containerName}/isThumbnailExists`);
            return response.data;
        } catch (error) {
            throw new Error('Error retrieving thumbnail');
        }
    }    

    public static saveJob = async (jobContext: JobData, containerName: string) => {
        try {
            const jobService = new JobService();
            const content = serializeJobContextState(jobContext);

            await jobService.save(jobContext.Project, jobContext.JobId, containerName, content);
        } catch (error) {
            window.console.error(error);
        }
    }

    private static migrateOldJobDetails = async (projectId: string, jobId: string, containerName: string, content: string) => {
        const jobData = JSON.parse(content, Channel.jsonReviver);

        const newData = JobService.convertJobFormat_V1ToV2_SetupPage(jobData.Categories);
        jobData.Categories = newData;
        if(jobData.CadInfo != undefined){
            jobData.CadInfos = [{...jobData.CadInfo}];
            delete jobData.CadInfo;

            JobService.setDefaultFrozenWallThickness(jobData);

            //save new format(current.json or iteration.json)
            await JobService.saveContent(projectId, jobId, containerName, JSON.stringify(jobData));
        }

        return jobData;
    }

    private static setDefaultFrozenWallThickness = (jobData: JobData) => {
        if(!jobData.Categories?.Cooling?.frozenWallThickness){
            const defaultFrozenWallValue = 20;

            if(jobData.Categories == undefined){
                jobData.Categories = new CategoriesData();
            }else if(jobData.Categories.Cooling == undefined){
                jobData.Categories.Cooling = new CoolingProperties();
            }

            jobData.Categories.Cooling.frozenWallThickness = defaultFrozenWallValue;
        }
    }

    public static async getJobDetails(projectId: string, jobId: string, containerName: string): Promise<JobDetailsBase | null> {
        const url = await new ProjectService().getIterationParametersUrl(projectId, jobId, containerName);
        if (!url?.trim().length) {
            return null;
        }

        const blob = await new FileManagementService().downloadFile(url, containerName);
        const content = await blob.text();

        //Do not remove, migrating old file format
        let migratedContent = await JobService.migrateOldJobDetails(projectId, jobId, containerName, content);
        return migratedContent as JobDetailsBase;
    }

    private static async getSolveInputsUrls(projectId: string, jobId: string, containerName: string): Promise<{
        parameters: string,
        solveInputs: string,
        resultsRoot: string
    } | null> {
        const iterationUrl: string = await new ProjectService().getIterationParametersUrl(projectId, jobId, containerName);
        if (!iterationUrl?.trim().length) {
            return null;
        }
        const solveInputs = iterationUrl.replace('iteration.json', 'solve/inputs/');
        const parameters = solveInputs + 'parameters.json';
        const resultsRoot = iterationUrl.replace('iteration.json', 'solve/results/');
        return { parameters, solveInputs, resultsRoot };
    }

    public static async getJobParameters(projectId: string, jobId: string, containerName: string): Promise<{rawParameters: JobSolveInputsParameters, channelsBodiesData: JobChannelsBodiesData[] | null} | null> {
        const urls = await JobService.getSolveInputsUrls(projectId, jobId, containerName);
        if (!urls) { return null; }
        const rawParameters = JSON.parse(await (await new FileManagementService().downloadFile(urls.parameters, containerName)).text()) as JobSolveInputsParameters;
        const channelsBodiesData = JobService.getChannelsBodiesData(rawParameters, urls.solveInputs);
        return { rawParameters, channelsBodiesData};
    }

    static async getMoldConstantRemoteModelData(projectId: string, jobId: string, containerName: string) {
        const urls = await JobService.getSolveInputsUrls(projectId, jobId, containerName);
        if (!urls) { return null; }
        return new FileManagementService().downloadFile(urls.resultsRoot + 'PhoenixResultsMoldTemp.cugbundle.bin', containerName);
    }

    private static getChannelsBodiesData(parameters: JobSolveInputsParameters, solveInputsUrl: string): JobChannelsBodiesData[] | null {
        if (parameters.Model?.Channels?.length > 0) {
            const result = parameters.Model.Channels.map(c => {
                return {
                    id: c.id,
                    name: c.name,
                    bodiesDataUrl: c.bodies.map(b => solveInputsUrl + b.GeometricalInfo.dataUrl)
                };
            });
            return result;
        }
        return null;
    }

    public static convertJobParameters(data: JobSolveInputsParameters) {
        // v1 => v2 format
        if (data.Model.Cavities && data.Model.Cores && data.Model.Cavities.length > 0 && data.Model.Cores.length > 0) {
            const cavityGroup = {
                id: '0',
                name: 'Cavity',
                materialIndex: data.Model.Cavities[0].materialIndex,
                bodies: data.Model.Cavities
            };
            const coreGroup = {
                id: '1',
                name: 'Core',
                materialIndex: data.Model.Cores[0].materialIndex,
                bodies: data.Model.Cores
            };
            data.Model.Molds = [cavityGroup, coreGroup];
            if (data.Categories.Materials.Cavities && data.Categories.Materials.Cores && data.Categories.Materials.Cavities.length > 0 && data.Categories.Materials.Cores.length > 0) {
                const cavityMaterialGroup = Object.assign({moldGroupId: cavityGroup.id}, data.Categories.Materials.Cavities[0]);
                const coreMaterialGroup = Object.assign({moldGroupId: coreGroup.id}, data.Categories.Materials.Cores[0]);
                data.Categories.Materials.Molds = [cavityMaterialGroup, coreMaterialGroup];
            }
        }
        return data;
    }

    public static convertJobFormat_V1ToV2_SetupPage(Categories: CategoriesData_LegacyV1) {
        // v1 => v2 format for setup page
        if (Categories?.Cavity && Categories?.Core) {
            const newCategories = new CategoriesData();
            const cavityGroup = {
                id: '0',
                name: 'Mold Group 1',
                bodies: Categories.Cavity,
                path:'', 
                nodesIds: [], 
                cadFileName: ''
            };
            const coreGroup = {
                id: '1',
                name: 'Mold Group 2',
                bodies: Categories.Core,
                path:'', 
                nodesIds: [], 
                cadFileName: ''
            };
            newCategories.Mold = [cavityGroup, coreGroup];
            newCategories.Part = Categories.Part;
            newCategories.Channel = Categories.Channel;
            newCategories.Cooling = Categories.Cooling;
            newCategories.Runner = Categories.Runner;
            newCategories.Selected = Categories.Selected ? Categories.Selected : [];
            newCategories.SelectedChannel = Categories.SelectedChannel;
            if (Categories.Materials.Cavity || Categories.Materials.Core || Categories.Materials.Part || Categories.Materials.BasicMold) {
                Categories.Materials.Cavity ? newCategories.Materials.Molds.push(Object.assign({moldGroupId: cavityGroup.id}, Categories.Materials.Cavity)) : null;
                Categories.Materials.Core ? newCategories.Materials.Molds.push(Object.assign({moldGroupId: coreGroup.id}, Categories.Materials.Core)) : null;
                Categories.Materials.Part ? newCategories.Materials.Parts.push(Object.assign({moldGroupId: '0'}, Categories.Materials.Part)) : null;
                Categories.Materials.BasicMold ? newCategories.Materials.BasicMold.push(Object.assign({moldGroupId: '0'}, Categories.Materials.BasicMold)) : null;
                Categories.Materials.Channel ? newCategories.Materials.Channels.push(Object.assign({moldGroupId: '0'}, Categories.Materials.Channel)) : null;
            }
            return newCategories;
        }
        return Categories;
    }

    public static async createJob(jobForm: JobForm, files: Array<File>): Promise<Iteration> {
        try {
            const formData = new FormData();
            formData.append("jobForm", JSON.stringify(jobForm));

            files.forEach((file) => {
                formData.append("formFiles", file);
            })

            const response = await axios.post(`/api/project/${jobForm.projectId}/job`, formData, {
                headers: {
                    "content-type": "multipart/form-data",
                }
            });
            return response.data;
        } catch (error: any) {
            if (error.response == undefined) {
                throw new Error("Error creating job");
            }
            throw new Error(error.response.data);
        }
    }

    public static async getFrozenWallThickness(projectId: string, jobId: string, containerName: string) {
        const urls = await JobService.getSolveInputsUrls(projectId, jobId, containerName);
        if (!urls) { return null; }
        const parameters = JSON.parse(await (await new FileManagementService().downloadFile(urls.parameters, containerName)).text()) as jobCategoriesData;
        if (parameters?.Categories?.Cooling?.frozenWallThickness) {
            return {
                frozenWallThickness: parameters.Categories.Cooling.frozenWallThickness
            }
        }
        return {frozenWallThickness: undefined};
    }

    public static async editJobInfo(updateJobInfo: updateJobInfo){
        //Edit job file(current.json or iteration.json) meta data
        await JobService.editJob(updateJobInfo);

        return updateJobInfo;
    }

    public static async editJob(updateJobInfo: updateJobInfo) {
        try {
            const formData = new FormData();
            formData.append("jobForm", JSON.stringify(updateJobInfo));

            updateJobInfo.cadFilesToAdd.forEach((file) => {
                formData.append("formFiles", file);
            })

            const response = await axios.post(`/api/project/${updateJobInfo.projectId}/job/edit`, formData, {
                headers: {
                    "content-type": "multipart/form-data",
                }
            });

        } catch (error) {
            throw new Error('Error editing job');
        }
    }   

    public static async getJobCreationConfig(){
        try {
            const response = await axios.get("/api/job/getJobCreationOptions");
            return response.data
        } catch (error: any) {
            if(error.response == undefined){
                throw new Error("Error getting job creation config");
            }
            throw new Error(error.response.data);
        }
    }

    public static async getSolveCurrentProgress(projectId: string, jobId: string){
        try {
            const params = { params: { projectId, jobId } }
            const response = await axios.get("/api/job/getSolveCurrentProgress", params);
            return response.data
        } catch (error: any) {
            if(error.response == undefined){
                throw new Error("Error getting solve progress");
            }
            throw new Error(error.response.data);
        }
    }

    public static async cancelSubmittedJob(projectId: string, jobId: string, containerName: string){
        try {
            const params = {projectId, jobId, containerName}
            const response = await axios.put("/api/job/cancelSubmittedJob", params);
            return response.data
        } catch (error: any) {
            throw new Error("Error cancelling job for solve");
        }
    }
    
    public static async createJobWithSampleFile(projectId: string, containerName: string, sampleJobType: SampleJobType): Promise<Iteration>{
        try {
            const params = {projectId, sampleJobType, containerName}
            const response = await axios.post("/api/project/createJobWithSampleFile", params);
            return response.data
        } catch (error: any) {
            throw new Error("Error creating job with sample file");
        }
    }
}