import { useContext, useEffect, useState } from 'react';
import FileManagementService from '../../services/FileManagementService';
import JobContext from '../../store/job/job-context';
import HideContextMenuOperator from './HideContextMenuOperator';
import JobData, { ChannelBodyType, Face, Part } from '../../store/job/job-data';
import { CadBlob } from '../../store/job/job-data';
import CadInfo from '../../store/job/cad-info';
import { GetNodeFromId, GetSelectedWithExchangeId } from '../../services/TreeviewFunctions';
import { useTranslation } from 'react-i18next';
import { SelectThroughCuttingPlaneOperator } from './operators/SelectThroughCuttingPlaneOperator';
import { useParams } from 'react-router-dom';

interface GeometryViewerProps {
    GeometryFiles: Array<CadInfo>,
    OnHwvReady: (newHWV: Communicator.WebViewer) => void,
    OnGeneratedSnapshot: (data: Blob) => void,
    OnGeneratingSnapshot: () => void
    takeSnapshotProp: boolean,
    OnModelSwitch: () => void,
    OnModelLoading: () => void,
    OnModelLoaded: () => void,
}

//Global list of callback(kind of a hack...)
//Needed to keep jobContext updated in the selection callBack
let selectionCallBacks: ((selectionEvents: Communicator.Event.NodeSelectionEvent[], removed: boolean) => void)[] = [];

export default function GeometryViewer(props: GeometryViewerProps) {
    const jobContext = useContext(JobContext);
    const [hwv, setHwv] = useState<Communicator.WebViewer>();
    const [cadBlobs, setCadBlobs] = useState<CadBlob[]>([]);
    const { OnHwvReady } = props;
    const { OnGeneratedSnapshot } = props;
    const { OnGeneratingSnapshot } = props;
    const { takeSnapshotProp } = props;
    const { t } = useTranslation();
    const { container: containerNameParam } = useParams();
    
    const resizeCanvas = () => {
        hwv?.resizeCanvas();
    }

    const onLayoutModified = () => {
        setTimeout(() => {
            hwv?.resizeCanvas();
        }, 0);
    }

    const takeSnapshot = async () => {
        OnGeneratingSnapshot()

        let selectedPlasticParts = jobContext.Categories.Part.reduce((previousValue: number[], currentValue: Part) => {
            let concatArray: number[] = [];
            concatArray = previousValue.concat(currentValue.nodesIds);
            return concatArray;
        }, [])

        //Creating second hidden instance for snapshot only
        let hiddenViewer = new Communicator.WebViewer({
            containerId: "hiddenViewer",
            rendererType: Communicator.RendererType.Client,
        });

        hiddenViewer.model.setEnableAutomaticUnitScaling(true);

        hiddenViewer.setCallbacks({
            sceneReady: function () {
                hiddenViewer.setMinimumFramerate(1);
            },
            modelStructureReady: async () => {
                //Set other cad files
                var promiseArray = [];
                for (const cadBlob of cadBlobs) {
                    const promise = hiddenViewer.model.loadSubtreeFromScsFile(hiddenViewer.model.getAbsoluteRootNode(), URL.createObjectURL(cadBlob.blob));
                    promiseArray.push(promise);
                }
                await Promise.all(promiseArray);

                generateSnapshotBlob(selectedPlasticParts, Communicator.ViewOrientation.TopRightFront, 'png', hiddenViewer).then((imgBlob: Blob) => {
                    OnGeneratedSnapshot(imgBlob);

                    //release viewer after use
                    hiddenViewer.shutdown();
                })
            },
        });

        hiddenViewer.start();
    }

    const generateSnapshotBlob = (nodeIds: number[], viewOrientation: Communicator.ViewOrientation, extension: string, viewer: Communicator.WebViewer): Promise<Blob> => {
        return new Promise(async (resolve) => {
            //remove nodeIds that are not in the model
            let filteredNodeIds = [];
            for (let nodeId of nodeIds) {
                const prop = await viewer.model.getNodeName(nodeId);
                if (prop != null) {
                    filteredNodeIds.push(nodeId);
                }
            }
            if (filteredNodeIds.length > 0) {
                try {
                    await viewer.view.isolateNodes(filteredNodeIds)
                } catch (e) {
                    window.console.log(e)
                }
            }

            viewer.view.setViewOrientation(viewOrientation).then(() => {
                let width = 180;
                let height = 180;
                let config = new Communicator.SnapshotConfig(width, height);
                viewer.takeSnapshot(config).then((htmlImageElement: HTMLImageElement) => {
                    var canvas = document.createElement('canvas');
                    canvas.width = width;
                    canvas.height = height;
                    var context = canvas.getContext('2d');

                    context?.clearRect(0, 0, width, height);
                    context?.drawImage(htmlImageElement, 0, 0, width, height);

                    canvas.toBlob((blob) => {
                        if (blob) resolve(blob);
                    });
                })
            });
        })
    }

    useEffect(() => {
        if (takeSnapshotProp == false)
            return;

        takeSnapshot();
    }, [takeSnapshotProp])

    useEffect(() => {
        if (props.GeometryFiles.length == 0) return;

        const currentModelViewer = hwv;

        const load = async () => {

            const cadFilesBlob: Array<CadBlob> = [];
            const promiseArray = [];
            for (const fileData of props.GeometryFiles) {
                if (!fileData.Blobs.Scs.Uri) continue;

                const cadFileName = fileData.Name;
                var promise = new FileManagementService().downloadFile(fileData.Blobs.Scs.Uri, containerNameParam!, fileData.Blobs.Scs.VersionId).then((file) => {
                    cadFilesBlob.push({
                        fileName: cadFileName,
                        blob: file
                    });
                });
                promiseArray.push(promise);
            }

            await Promise.all(promiseArray);

            setCadBlobs([...cadFilesBlob]);

            //If model viewer is already instanciated, use it.
            if (currentModelViewer != null) {
                switchModel(cadFilesBlob);
                return;
            }

            createHoopsWebViewer(cadFilesBlob);
        }

        load();

    }, [props.GeometryFiles]);

    useEffect(() => {
        if (!hwv)
            return

        window.addEventListener('resize', resizeCanvas);
        document.addEventListener("layoutModified", onLayoutModified);
        hwv.view.setBackfacesVisible(true);

        return () => {
            window.removeEventListener('resize', resizeCanvas);
            document.removeEventListener("layoutModified", onLayoutModified);
        };
    }, [hwv]);

    useEffect(() => {
        if (!hwv) {
            return;
        }
        const operator: Communicator.Operator.SelectionOperator = hwv.operatorManager.getOperator(Communicator.OperatorId.Select);

        operator.setPickConfig(new Communicator.PickConfig(Communicator.SelectionMask.Face));
    }, [hwv]);

    useEffect(() => {
        if (!hwv) {
            return;
        }

        if (jobContext.SelectionMode === ChannelBodyType.PARTS) {
            hwv.selectionManager.setHighlightFaceElementSelection(false);
            hwv.selectionManager.setHighlightNodeSelection(true);
        } else {
            hwv.selectionManager.setHighlightFaceElementSelection(true);
            hwv.selectionManager.setHighlightNodeSelection(false);
        }
    }, [hwv, jobContext.SelectionMode])

    const addSiblingNodeId = (nodeId: number, selectedNodeIds: (number | null)[], jobContext: JobData, listToAdd: Set<number>, verifyInSelectedNode: boolean) => {
        const group = GetNodeFromId(nodeId, jobContext);
        if(group){
            group.nodeIds.forEach((id: any) => {
                const canAdd = !verifyInSelectedNode ? true : !selectedNodeIds.includes(id);
                //Check items
                if(canAdd)
                    listToAdd.add(id)
            });
        }
    }

    const getHoopsSelectionItemsFromNodeIds = (nodeIds: Set<number>, mustBeSelected: boolean, hwv: Communicator.WebViewer) => {
        let selectionItemList = [];
        for(const selectedId of nodeIds){
            if(hwv.selectionManager.isNodeSelected(selectedId) == mustBeSelected){
                const selectionItem = Communicator.Selection.SelectionItem.create(selectedId);
                selectionItemList.push(selectionItem);
            }
        }

        return selectionItemList;
    }

    const toggleHighlightSameGroupNode = (selectionEvents: Communicator.Event.NodeSelectionEvent[], hwv: Communicator.WebViewer) => {
        if(!selectionEvents || selectionEvents.length == 0) return;

        const selectionNodeIds = selectionEvents.map((e) => {return e.getSelection().getNodeId()});
        const allSelected = hwv.selectionManager.getResults().map(si => { return si.getNodeId()!; });
        const combinedSelection = [... new Set(selectionNodeIds.concat(allSelected))];

        const siblingNodesToHighlight: Set<number> = new Set();
        const siblingNodesToUnHighlight: Set<number> = new Set();

        for(const selection of selectionEvents){
            const nodeId = selection.getSelection().getNodeId();
            if(nodeId){
                const isSelected = hwv.selectionManager.isNodeSelected(nodeId);
                if(isSelected){
                    //If selected, also highlight same groupe node
                    addSiblingNodeId(nodeId, combinedSelection, jobContext, siblingNodesToHighlight, true);
                }else{
                    //If not selected, deselect same group node
                    addSiblingNodeId(nodeId, combinedSelection, jobContext, siblingNodesToUnHighlight, false);
                }               
            }
        }

        const itemsToHighlight = getHoopsSelectionItemsFromNodeIds(siblingNodesToHighlight, false, hwv);
        const itemsToUnHighlight = getHoopsSelectionItemsFromNodeIds(siblingNodesToUnHighlight, true, hwv);

        //Set second parameter set to true to avoid recursion
        //electionArrayCallBack wont be called again with second parameter to true
        if(itemsToHighlight.length > 0)
            hwv.selectionManager.add(itemsToHighlight, true);

        if(itemsToUnHighlight.length > 0)
            hwv.selectionManager.remove(itemsToUnHighlight, true);
    }

    const selectionArrayCallBack = (selectionEvents: Communicator.Event.NodeSelectionEvent[], removed: boolean) => {
        if(!hwv || !selectionEvents || JSON.stringify(jobContext.Tree) == "{}") return; 

        toggleHighlightSameGroupNode(selectionEvents, hwv);

        let selectedEntities: Face[] = hwv.selectionManager.getResults().map(si => {
            const nodeId = si.getNodeId()!;
            const faceEntity = si.getFaceEntity();

            return {
                nodeId: nodeId,
                faceIndex: faceEntity?.getCadFaceIndex() ?? 0
            }
        });
        
        const selectedFacesWithExchangeIds = GetSelectedWithExchangeId(selectedEntities, hwv);
        jobContext.setModelSelection(selectedFacesWithExchangeIds);
    }

    const setViewerGroupSelection = () => {
        //Kind of a hack here using global array selectionCallBacks(initialised on top of this file) to unset them. 
        //Its the only way I found that wont duplicate the callback and actually remove the old callbakc(with old state) on each call of this function
        //The reason we need to unset the selection callback is because inside the function, the jobContext is not up to date otherwise. 
        //The closure capture the old state if we dont update the callback.
        //Also we need to unset them because it will pile up and call the function many times.
        for(const selectionCallBack of selectionCallBacks){
            hwv?.unsetCallbacks({
                selectionArray: selectionCallBack
            });
        }

        //Empty global callback array
        selectionCallBacks = [];

        //Set new callback using updated state(mainly jobContext)
        hwv?.setCallbacks({
            selectionArray: selectionArrayCallBack
        });

        selectionCallBacks.push(selectionArrayCallBack);
    }

    useEffect(() => {
        if(jobContext.IsTreeLoaded)
            setViewerGroupSelection();

    }, [jobContext.Tree, jobContext.IsTreeLoaded]);

    const switchModel = (cadBlobs: Array<CadBlob>) => {
        if (!hwv) return;

        hwv.model.clear().then(async function () {
            await loadSubtreeFromScsBlobs(cadBlobs, hwv);

            props.OnModelSwitch();
        });
        return
    }

    const loadSubtreeFromScsBlobs = async (cadBlobs: Array<CadBlob>, hwv: Communicator.WebViewer) => {
        if (!hwv) return;

        props.OnModelLoading();

        var promiseArray = [];

        for (const cadBlob of cadBlobs) {
            const fileUrl = URL.createObjectURL(cadBlob.blob);
            const cadFileName = cadBlob.fileName
            const promise = hwv.model.loadSubtreeFromScsFile(hwv.model.getAbsoluteRootNode(), fileUrl).then(async (nodeIds) => {
                for (const nodeId of nodeIds) {
                    hwv.model.addPropertyToNode(nodeId, "cadFileName", cadFileName, [])
                }
            });
            promiseArray.push(promise);
        }

        await Promise.all(promiseArray);

        props.OnModelLoaded();
    }

    const createHoopsWebViewer = (cadBlobs: Array<CadBlob>) => {
        if (cadBlobs.length == 0) return;

        const hwv = new Communicator.WebViewer({
            containerId: "viewerContainer"
        });

        var uiConfig: Communicator.Ui.Desktop.UiConfig = {
            containerId: 'content',
            screenConfiguration: Communicator.ScreenConfiguration.Desktop,
            showModelBrowser: true,
            showToolbar: true,
        };

        const ui = new Communicator.Ui.Desktop.DesktopUi(hwv, uiConfig);
        hwv.model.setEnableAutomaticUnitScaling(true);

        hwv.setCallbacks({
            sceneReady: () => {
                hwv.view.setBackgroundColor(Communicator.Color.white(), Communicator.Color.white());
                hwv.selectionManager.setHighlightFaceElementSelection(false);
                hwv.selectionManager.setHighlightLineElementSelection(false);
                hwv.selectionManager.setHighlightPointElementSelection(false);
                hwv.selectionManager.setHighlightNodeSelection(true);

                const hideMenuOperator = new HideContextMenuOperator(ui);
                const hideMenuOperatorHandle = hwv.operatorManager.registerCustomOperator(hideMenuOperator);
                hwv.operatorManager.push(hideMenuOperatorHandle);

                const stcpOperator = new SelectThroughCuttingPlaneOperator(hwv);
                const stcpOperatorHandle = hwv.operatorManager.registerCustomOperator(stcpOperator);
                hwv.operatorManager.push(stcpOperatorHandle);

                // Hides context menu handles.
                ui._getContextMenu()._getContextItemMap().handles.hide();
            },
            modelStructureReady: async () => {
                await loadSubtreeFromScsBlobs(cadBlobs, hwv);

                setHwv(hwv);
                OnHwvReady(hwv);
            }
        });
        hwv.start();
    }

    return (
        <>
            {/*HIDDEN VIEWER FOR SNAPSHOT */}
            <div style={{ position: "absolute", left: "9999px", width: "1px", height: "1px", background: "purple" }} id="hiddenViewer"></div>

            <div id="content" style={{ position: "relative", width: "100%", height: "100%", border: "thin solid rgba(0, 0, 0, 0.1)" }} >
                <div id="viewerContainer" ></div>
                {/* <!-- Toolbar HTML--> */}
                <div id="toolBar">
                    <div className="toolbar-tools">
                        <div
                            id="home-button"
                            title={t("Reset Camera")}
                            data-operatorclass="toolbar-home"
                            className="hoops-tool"
                        >
                            <div className="tool-icon"></div>
                        </div>
                        <div id="tool_separator_1" className="tool-separator"></div>
                        <div
                            id="edgeface-button"
                            title={t("Wireframe on Shaded")}
                            data-operatorclass="toolbar-wireframeshaded"
                            data-submenu="edgeface-submenu"
                            className="hoops-tool toolbar-menu"
                        >
                            <div className="tool-icon"></div>
                        </div>
                        <div id="tool_separator_2" className="tool-separator"></div>
                        <div
                            id="click-button"
                            title={t("Select")}
                            data-operatorclass="toolbar-select"
                            data-submenu="click-submenu"
                            className="hoops-tool toolbar-radio active-tool"
                        >
                            <div className="smarrow"></div>
                            <div className="tool-icon"></div>
                        </div>
                        <div
                            id="camera-button"
                            title={t("Orbit Camera")}
                            data-operatorclass="toolbar-orbit"
                            data-submenu="camera-submenu"
                            className="hoops-tool toolbar-menu"
                        >
                            <div className="tool-icon"></div>
                        </div>
                        <div id="tool_separator_3" className="tool-separator"></div>
                        <div
                            id="explode-button"
                            title="Explode Menu"
                            data-operatorclass="toolbar-explode"
                            data-submenu="explode-slider"
                            className="hoops-tool toolbar-menu-toggle"
                        >
                            <div className="tool-icon"></div>
                        </div>
                        <div
                            id="cuttingplane-button"
                            title={t("Cutting Plane Menu")}
                            data-operatorclass="toolbar-cuttingplane"
                            data-submenu="cuttingplane-submenu"
                            className="hoops-tool toolbar-menu-toggle"
                        >
                            <div className="tool-icon"></div>
                        </div>
                        <div id="tool_separator_5" className="tool-separator"></div>
                        <div
                            id="settings-button"
                            title={t("Settings")}
                            data-operatorclass="toolbar-settings"
                            className="hoops-tool"
                        >
                            <div className="tool-icon"></div>
                        </div>
                    </div>
                    <div id="submenus">
                        <div id="redline-submenu" data-button="redline-button" className="toolbar-submenu submenus">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div
                                                id="redline-circle-button"
                                                title={t("Redline Circle")}
                                                data-operatorclass="toolbar-redline-circle"
                                                className="submenu-icon"
                                            ></div>
                                        </td>
                                        <td>
                                            <div
                                                id="redline-rectangle-button"
                                                title={t("Redline Rectangle")}
                                                data-operatorclass="toolbar-redline-rectangle"
                                                className="submenu-icon"
                                            ></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div
                                                id="redline-note"
                                                title={t("Redline Note")}
                                                data-operatorclass="toolbar-redline-note"
                                                className="submenu-icon"
                                            ></div>
                                        </td>
                                        <td>
                                            <div
                                                id="redline-freehand"
                                                title={t("Redline Freehand")}
                                                data-operatorclass="toolbar-redline-freehand"
                                                className="submenu-icon"
                                            ></div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div id="camera-submenu" data-button="camera-button" className="toolbar-submenu submenus">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div
                                                id="operator-camera-walk"
                                                title={t("Walk")}
                                                data-operatorclass="toolbar-walk"
                                                className="submenu-icon"
                                            ></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div
                                                id="operator-camera-turntable"
                                                title={t("Turntable")}
                                                data-operatorclass="toolbar-turntable"
                                                className="submenu-icon"
                                            ></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div
                                                id="operator-camera-orbit"
                                                title={t("Orbit Camera")}
                                                data-operatorclass="toolbar-orbit"
                                                className="submenu-icon"
                                            ></div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div id="edgeface-submenu" data-button="edgeface-button" className="toolbar-submenu submenus">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div
                                                id="viewport-wireframe-on-shaded"
                                                title={t("Shaded With Lines")}
                                                data-operatorclass="toolbar-wireframeshaded"
                                                className="submenu-icon"
                                            ></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div
                                                id="viewport-shaded"
                                                title={t("Shaded")}
                                                data-operatorclass="toolbar-shaded"
                                                className="submenu-icon"
                                            ></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div
                                                title={t("Hidden Line")}
                                                data-operatorclass="toolbar-hidden-line"
                                                className="submenu-icon"
                                            ></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div
                                                id="viewport-wireframe"
                                                title={t("Wireframe")}
                                                data-operatorclass="toolbar-wireframe"
                                                className="submenu-icon"
                                            ></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div
                                                id="viewport-xray"
                                                title={t("XRay")}
                                                data-operatorclass="toolbar-xray"
                                                className="submenu-icon"
                                            ></div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div id="click-submenu" data-button="click-button" className="toolbar-submenu submenus">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div
                                                id="measure-angle-between-faces"
                                                title={t("Measure Angle Between Faces")}
                                                data-operatorclass="toolbar-measure-angle"
                                                className="submenu-icon"
                                            ></div>
                                        </td>
                                        <td>
                                            <div
                                                id="measure-distance-between-faces"
                                                title={t("Measure Distance Between Faces")}
                                                data-operatorclass="toolbar-measure-distance"
                                                className="submenu-icon"
                                            ></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div
                                                id="measure-edges"
                                                title={t("Measure Edges")}
                                                data-operatorclass="toolbar-measure-edge"
                                                className="submenu-icon"
                                            ></div>
                                        </td>
                                        <td>
                                            <div
                                                id="measure-point-to-point"
                                                title={t("Measure Point to Point")}
                                                data-operatorclass="toolbar-measure-point"
                                                className="submenu-icon"
                                            ></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div
                                                id="select-parts"
                                                title={t("Select Parts")}
                                                data-operatorclass="toolbar-select"
                                                className="submenu-icon"
                                            ></div>
                                        </td>
                                        <td>
                                            <div
                                                id="area-select"
                                                title={t("Area Select")}
                                                data-operatorclass="toolbar-area-select"
                                                onMouseUp={() => {
                                                    jobContext.setSelectionMode(ChannelBodyType.PARTS);
                                                }}
                                                className="submenu-icon"
                                            ></div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div id="explode-slider" className="toolbar-submenu slider-menu submenus">
                            <div id="explosion-slider" style={{ height: "90px" }} className="toolbar-slider"></div>
                        </div>
                        <div id="cuttingplane-submenu" className="toolbar-submenu cutting-plane submenus no-modal">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div
                                                id="cuttingplane-face"
                                                title={t("Create Cutting Plane On Selected Face")}
                                                data-plane="face"
                                                data-operatorclass="toolbar-cuttingplane-face"
                                                className="toolbar-cp-plane submenu-icon disabled"
                                            ></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div
                                                id="cuttingplane-x"
                                                title={t("Cutting Plane X")}
                                                data-plane="x"
                                                data-operatorclass="toolbar-cuttingplane-x"
                                                className="toolbar-cp-plane submenu-icon"
                                            ></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div
                                                id="cuttingplane-y"
                                                title={t("Cutting Plane Y")}
                                                data-plane="y"
                                                data-operatorclass="toolbar-cuttingplane-y"
                                                className="toolbar-cp-plane submenu-icon"
                                            ></div>
                                        </td>
                                        <td>
                                            <div
                                                id="cuttingplane-section"
                                                title={t("Cutting Plane Visibility Toggle")}
                                                data-plane="section"
                                                data-operatorclass="toolbar-cuttingplane-section"
                                                className="toolbar-cp-plane submenu-icon disabled"
                                            ></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div
                                                id="cuttingplane-z"
                                                title={t("Cutting Plane Z")}
                                                data-plane="z"
                                                data-operatorclass="toolbar-cuttingplane-z"
                                                className="toolbar-cp-plane submenu-icon"
                                            ></div>
                                        </td>
                                        <td>
                                            <div
                                                id="cuttingplane-reset"
                                                title={t("Cutting Plane Reset")}
                                                data-plane="reset"
                                                data-operatorclass="toolbar-cuttingplane-reset"
                                                className="toolbar-cp-plane submenu-icon disabled"
                                            ></div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div id="viewer-settings-dialog-container">
                    {/* <!-- Viewer Settings Window --> */}
                    <div id="viewer-settings-dialog" className="hoops-ui-window">
                        {/* <!-- Header --> */}
                        <div className="hoops-ui-window-header">
                            <span className="tab selected" id="settings-tab-label-general">{t("General")}</span>
                            <span className="tab" id="settings-tab-label-walk">{t("Walk")}</span>
                        </div>

                        {/* <!-- Walk tab --> */}
                        <div className="hoops-ui-window-body" id="settings-tab-walk">
                            <div className="settings-group-header">{t("Walk Mode")}</div>
                            <div className="settings-group settings-group-general">
                                <div className="settings-block">
                                    <label>{t("Walk Mode")}:</label>
                                    <select id="settings-walk-mode" className="right-align">
                                        <option value="0">{t("Mouse")}</option>
                                        <option value="1">{t("Keyboard")}</option>
                                    </select>
                                    <span className="clear-both"></span>
                                </div>

                                <div className="settings-block" id="walk-navigation-keys">
                                    <div id="walk-navigation-text" className="center-align bold">{t("Navigation Keys")}</div>
                                    <div>
                                        <span id="walk-key-forward">W</span> / <span id="walk-key-left">A</span> /
                                        <span id="walk-key-backward">S</span> /
                                        <span id="walk-key-right">D</span>
                                        <span className="right-align">{t("Move")}</span>
                                    </div>
                                    <div>
                                        <span id="walk-key-rotate-left">Q</span> /
                                        <span id="walk-key-rotate-right">E</span>
                                        <span className="right-align">{t("Rotate")}</span>
                                    </div>
                                    <div>
                                        <span id="walk-key-up">X</span> /
                                        <span id="walk-key-down">C</span>
                                        <span className="right-align">{t("Up / Down")}</span>
                                    </div>
                                    <div>
                                        <span id="walk-key-tilt-up">R</span> /
                                        <span id="walk-key-tilt-down">F</span>
                                        <span id="move-keys" className="right-align">{t("Tilt")}</span>
                                    </div>
                                    <div>
                                        <span id="walk-key-toggle-collision">V</span>
                                        <span className="right-align">{t("Toggle Collision Detection")}</span>
                                    </div>
                                </div>

                                <div className="settings-block">
                                    <span id="walk-rotation-text">{t("Rotation (Deg/s)")}:</span>
                                    <input type="number" min="0" id="settings-walk-rotation" className="right-align" />
                                    <span className="clear-both"></span>
                                </div>

                                <div className="settings-block">
                                    <span id="walk-speed-text"
                                    >Walk Speed (<span id="walk-speed-units">m</span>/s):</span
                                    >
                                    <input
                                        type="number"
                                        min="0"
                                        id="settings-walk-speed"
                                        step=".1"
                                        className="right-align"
                                    />
                                    <span className="clear-both"></span>
                                </div>
                                <div className="settings-block">
                                    <span id="walk-elevation-text"
                                    >Elevation Speed (<span id="elevation-speed-units">m</span>/s):</span
                                    >
                                    <input
                                        type="number"
                                        min="0"
                                        id="settings-walk-elevation"
                                        step=".1"
                                        className="right-align"
                                    />
                                    <span className="clear-both"></span>
                                </div>
                                <div className="settings-block">
                                    <span id="walk-view-angle-text">{t("Field of View (Deg)")}:</span>
                                    <input
                                        type="number"
                                        min="30"
                                        max="150"
                                        id="settings-walk-view-angle"
                                        className="right-align"
                                    />
                                    <span className="clear-both"></span>
                                </div>
                                <div className="settings-block">
                                    <span id="walk-mouse-look-text">{t("Enable Mouse Look")}:</span>
                                    <input type="checkbox" id="settings-mouse-look-enabled" />

                                    <span id="settings-mouse-look-style" className="grayed-out right-align">
                                        <label>{t("Speed")}: </label>
                                        <input id="settings-mouse-look-speed" type="number" />
                                    </span>
                                </div>
                                <div className="settings-block">
                                    <span id="walk-bim-mode-text">{t("Enable Collision Detection")}:</span>
                                    <input type="checkbox" id="settings-bim-mode-enabled" />
                                    <span className="clear-both"></span>
                                </div>
                            </div>
                        </div>

                        {/* <!--General tab--> */}
                        <div className="hoops-ui-window-body selected" id="settings-tab-general">
                            {/* <!-- general --> */}
                            <div className="settings-group-header">{t("General")}</div>
                            <div className="settings-group settings-group-general">
                                <div className="settings-block">
                                    <label>{t("Projection Mode")}:</label>
                                    <select id="settings-projection-mode" className="right-align">
                                        <option value="0">{t("Orthographic")}</option>
                                        <option value="1">{t("Perspective")}</option>
                                    </select>
                                    <span className="clear-both"></span>
                                </div>
                                <div className="settings-block">
                                    <span className="framerate-text">{t("Framerate (fps)")}:</span>
                                    <input type="number" min="0" id="settings-framerate" className="right-align" />
                                    <span className="clear-both"></span>
                                </div>
                                <div className="settings-block">
                                    <span>{t("Hidden Line Opacity (0-1)")}: </span>
                                    <input
                                        id="settings-hidden-line-opacity"
                                        className="right-align"
                                        type="number"
                                        min="0"
                                        max="1"
                                        step=".1"
                                    />
                                    <span className="clear-both"></span>
                                </div>
                                <div className="settings-block">
                                    <label htmlFor="settings-show-backfaces">{t("Show Backfaces")}:</label>
                                    <input type="checkbox" id="settings-show-backfaces" className="right-align" />
                                    <span className="clear-both"></span>
                                </div>
                                <div className="settings-block">
                                    <label htmlFor="settings-show-capping-geometry">{t("Show Capping Geometry")}:</label>
                                    <input type="checkbox" id="settings-show-capping-geometry" className="right-align" />
                                    <span className="clear-both"></span>
                                </div>
                                <div className="settings-block">
                                    <span>{t("Enable Face / Line Selection")}: </span>
                                    <input
                                        type="checkbox"
                                        id="settings-enable-face-line-selection"
                                        className="right-align"
                                    />
                                    <span className="clear-both"></span>
                                </div>
                                <div className="settings-block">
                                    <span>{t("Selection Honors Scene Visibility")}:</span>
                                    <input type="checkbox" id="settings-select-scene-invisible" className="right-align" />
                                    <span className="clear-both"></span>
                                </div>
                                <div className="settings-block">
                                    <span>{t("Rotate Around Camera Center")}:</span>
                                    <input type="checkbox" id="settings-orbit-mode" className="right-align" />
                                    <span className="clear-both"></span>
                                </div>
                            </div>

                            {/* <!-- effects --> */}
                            <div className="settings-group-header">Effects</div>
                            <div className="settings-group settings-group-general">
                                <div className="settings-block">
                                    <span>{t("Enable Ambient Occlusion")}:</span>
                                    <input type="checkbox" id="settings-ambient-occlusion" />
                                    <input
                                        id="settings-ambient-occlusion-radius"
                                        type="number"
                                        min="0"
                                        max="10"
                                        step=".01"
                                        className="right-align"
                                    />
                                    <div className="right-align">Radius:</div>
                                    <span className="clear-both"></span>
                                </div>

                                <div className="settings-block">
                                    <span>{t("Enable Anti-Aliasing")}:</span>
                                    <input type="checkbox" id="settings-anti-aliasing" className="right-align" />
                                    <span className="clear-both"></span>
                                </div>

                                <div className="settings-block">
                                    <div>
                                        <span>{t("Enable Bloom")}:</span>
                                        <input type="checkbox" id="settings-bloom-enabled" className="right-align" />
                                    </div>
                                    <span id="settings-bloom-style" className="grayed-out">
                                        <label>{t("Intensity Scale")}:</label>
                                        <input
                                            id="settings-bloom-intensity"
                                            type="number"
                                            min="0"
                                            step=".1"
                                            className="right-align"
                                            disabled
                                        />
                                        <span className="clear-both"></span>
                                        <label>{t("Threshold")}:</label>
                                        <input
                                            type="range"
                                            id="settings-bloom-threshold"
                                            min="0"
                                            max="1"
                                            step="0.1"
                                            className="right-align"
                                            disabled
                                        />
                                    </span>
                                </div>

                                <div className="settings-block">
                                    <div>
                                        <span>{t("Silhouette Edges")}:</span>
                                        <input type="checkbox" id="settings-silhouette-enabled" className="right-align" />
                                    </div>
                                </div>

                                <div className="settings-block">
                                    <div>
                                        <span>{t("Reflection Planes")}:</span>
                                        <input type="checkbox" id="settings-reflection-enabled" className="right-align" />
                                    </div>
                                </div>

                                <div className="settings-block">
                                    <div>
                                        <span>{t("Enable Shadows")}:</span>
                                        <input type="checkbox" id="settings-shadow-enabled" className="right-align" />
                                    </div>
                                    <span id="settings-shadow-style" className="grayed-out">
                                        <span>{t("Interactive")}:</span>
                                        <input
                                            type="checkbox"
                                            id="settings-shadow-interactive"
                                            className="right-align"
                                            disabled
                                        />
                                        <span className="clear-both"></span>

                                        <label>{t("Blur Samples")}:</label>
                                        <input
                                            type="range"
                                            id="settings-shadow-blur-samples"
                                            min="0"
                                            max="20"
                                            step="1"
                                            className="right-align"
                                            disabled
                                        />
                                    </span>
                                </div>
                            </div>

                            {/* <!-- axis --> */}
                            <div className="settings-group-header">{t("Axis")}</div>
                            <div className="settings-group settings-group-general">
                                <div className="settings-block">
                                    <span>{t("Show Axis Triad")}:</span>
                                    <input type="checkbox" id="settings-axis-triad" className="right-align" />
                                    <span className="clear-both"></span>
                                </div>
                                <div className="settings-block">
                                    <span>{t("Show Nav Cube")}:</span>
                                    <input type="checkbox" id="settings-nav-cube" className="right-align" />
                                    <span className="clear-both"></span>
                                </div>
                            </div>

                            {/* <!-- point cloud --> */}
                            <div className="settings-group-header">{t("Point Cloud")}</div>
                            <div className="settings-group settings-group-general">
                                <div className="settings-block">
                                    <span>{t("Enable Splats")}:</span>
                                    <input type="checkbox" id="settings-splat-rendering-enabled" />

                                    <span id="settings-splat-enabled-style" className="grayed-out right-align">
                                        <label>{t("Size")}: </label>
                                        <input id="settings-splat-rendering-size" step=".01" type="number" />

                                        <label>{t("Mode")}:</label>
                                        <select id="settings-splat-rendering-point-size-unit">
                                            <option value="0">ScreenPixels</option>
                                            <option value="1">CSSPixels</option>
                                            <option value="2">World</option>
                                            <option value="3">ScreenWidth</option>
                                            <option value="4">ScreenHeight</option>
                                            <option value="5">BoundingDiagonal</option>
                                        </select>
                                    </span>
                                </div>
                                <div className="settings-block">
                                    <span>{t("Enable Eye-Dome Lighting")}:</span>
                                    <input
                                        type="checkbox"
                                        id="settings-eye-dome-lighting-enabled"
                                        className="right-align"
                                    />
                                    <span className="clear-both"></span>
                                </div>
                            </div>

                            {/* <!--color--> */}
                            <div className="settings-group-header">{t("Color")}</div>
                            <div className="settings-group settings-group-colors">
                                <div className="settings-block">
                                    <div>{t("Background Color")}:</div>
                                    <div>
                                        <input
                                            type="text"
                                            id="settings-background-top"
                                            className="color-picker"
                                            data-position="top left"
                                        />
                                        <label>{t("Top")}</label>
                                    </div>
                                    <div>
                                        <input
                                            type="text"
                                            id="settings-background-bottom"
                                            className="color-picker"
                                            data-position="top left"
                                        />
                                        <label>{t("Bottom")}</label>
                                    </div>
                                </div>
                                <div className="settings-block">
                                    <div>{t("Capping Geometry")}:</div>
                                    <div>
                                        <input
                                            type="text"
                                            id="settings-capping-face-color"
                                            className="color-picker"
                                            data-position="top left"
                                        />
                                        <label>{t("Face")}</label>
                                    </div>
                                    <div>
                                        <input
                                            type="text"
                                            id="settings-capping-line-color"
                                            className="color-picker"
                                            data-position="top left"
                                        />
                                        <label>{t("Line")}</label>
                                    </div>
                                </div>
                                <div className="settings-block">
                                    <div>{t("Selection Color")}:</div>
                                    <input
                                        type="text"
                                        id="settings-selection-color-body"
                                        className="color-picker"
                                        data-position="top left"
                                    />
                                    <label>{t("Body")}</label>
                                    <div>
                                        <span id="settings-selection-color-face-line-style">
                                            <input
                                                type="text"
                                                id="settings-selection-color-face-line"
                                                className="color-picker"
                                                data-position="top left"
                                            />
                                            <label>{t("Faces and Lines")}</label>
                                        </span>
                                    </div>
                                </div>
                                <div className="settings-block">
                                    <input
                                        type="text"
                                        id="settings-measurement-color"
                                        className="color-picker"
                                        data-position="top left"
                                    />
                                    <label>{t("Measurement Color")}</label>
                                </div>
                                <div className="settings-block">
                                    <span id="settings-pmi-color-style" className="grayed-out">
                                        <input
                                            type="text"
                                            id="settings-pmi-color"
                                            className="color-picker"
                                            data-position="top left"
                                            disabled
                                        />
                                        <label>{t("PMI Override Color")}</label>
                                    </span>
                                    <input type="checkbox" id="settings-pmi-enabled" />
                                    <label>{t("Enable")}</label>
                                </div>
                            </div>
                        </div>

                        {/* <!-- Footer --> */}
                        <div>
                            <div className="hoops-ui-window-footer">

                                <div className="hoops-ui-footer-buttons">
                                    <button id="viewer-settings-ok-button">{t("Ok")}</button>
                                    <button id="viewer-settings-cancel-button">{t("Cancel")}</button>
                                    <button id="viewer-settings-apply-button">{t("Apply")}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="snapshot-dialog" className="hoops-ui-window">
                <div className="hoops-ui-window-header">{t("Snapshot")}</div>
                <div className="hoops-ui-window-body">
                    <img id="snapshot-dialog-image" className="snapshot-image" alt="Snapshot" />
                    <div className="snapshot-text">{t("Right Click to Save image")}</div>
                </div>
                <div className="hoops-ui-window-footer">
                    <div className="hoops-ui-footer-buttons">
                        <button id="snapshot-dialog-cancel-button">{t("Close")}</button>
                    </div>
                </div>
            </div>
        </>
    )
}