import { Grid, FormControl, Select, SelectChangeEvent, MenuItem, Tooltip, IconButton, Button, Typography, FormControlLabel, FormGroup, Switch, Fab, Alert, Dialog, DialogActions, DialogContent, DialogTitle, Collapse } from "@mui/material";
import JobData, { TreeviewFaceGroup, TreeviewFace, intletColor, outletColor, ChannelBodyType } from "../../store/job/job-data";
import InsightsRoundedIcon from '@mui/icons-material/InsightsRounded';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import AlignVerticalTopRoundedIcon from '@mui/icons-material/AlignVerticalTopRounded';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import { useState, useContext, useEffect } from "react";
import JobContext from "../../store/job/job-context";
import { mapToPart } from "../../services/TreeviewFunctions";
import { makeStyles } from "@mui/styles";
import { ChannelBodies } from "./ChannelBodiesComponent";
import { ChannelTerminals } from "./ChannelTerminalsComponent";
import { ChannelBaffles } from "./ChannelBafflesComponent";
import ErrorDialog from "../common/ErrorDialog";
import { Box } from "@mui/system";
import CircleIcon from '@mui/icons-material/Circle';
import FlareIcon from '@mui/icons-material/Flare';
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { MoldCategory } from "../../store/job/mold-categories";
import AddIcon from '@mui/icons-material/Add';
import theme from "../../styles/main-theme";
import { Channel } from "../../store/job/channel";
import { detectBaffles, getChannelBodies, repairBaffle, validateSelectedFaces } from "./helpers/autodetection";
import { HoopsColorContext } from "../../store/job/hoops-color-context";
import { resetChannelsColors, setChannelsColors } from "./helpers/channel-coloring";
import { HoopsVisibilityContext } from "../../store/job/hoops-visibility-context";
import { useFocusOnChannel } from "./hooks/useFocusOnChannel";
import { useManageFaceGroups } from "./hooks/useManageFaceGroups";
import { useTranslation } from 'react-i18next';
import { useSelectTerminal } from "./hooks/useSelectTerminal";
import { useRepairBaffle } from "./hooks/useRepairBaffle";
import { VisibilityStateActionType } from "../../store/job/hoops-visibility-reducer";
import { HoopsEntitiesContext } from "../../store/job/hoops-entities-context";


export interface ChannelConfgurationProps {
    channels: Channel[],
    Hwv: Communicator.WebViewer,
    channelBodyType: ChannelBodyType,
    disabled: boolean,
    onError: (message: string) => void,
    onBusy: (isBusy: boolean) => void
    onSelectInletOutletBody: (isSelectingInletOutletBody: boolean) => void
    onToggleInletOutletSelectionMode: (isInletOutletSelectionMode: boolean) => void
}

export function ChannelConfguration(props: ChannelConfgurationProps) {
    const { Hwv, channels } = props;
    const jobContext = useContext(JobContext);
    const { updateVisibility } = useContext(HoopsVisibilityContext);
    const colorContext = useContext(HoopsColorContext);
    const { unregisterMeshes } = useContext(HoopsEntitiesContext);
    const { t } = useTranslation();
    const [inletOutletSelectionMode, setInletOutletSelectionMode] = useState(false);
    const [isBaffleAutodetectionModeEnabled, setIsBaffleAutodetectionModeEnabled] = useState(true);
    const [openAlertChannel, setOpenAlertChannel] = useState(false);
    const [channelErrorDialogContent, setChannelErrorDialogContent] = useState("");
    const [channelErrorDialogTitle, setChannelErrorDialogTitle] = useState("");
    const [openChannelSection, setOpenChannelSection] = useState(true);
    const { focusOnChannel, enabled: focusOnChannelEnabled } = useFocusOnChannel(jobContext, Hwv);
    const { start: startRepair, enabled: repairBaffleEnabled } = useRepairBaffle(Hwv);
    const { startTerminalSelection, endTerminalSelection, enabled: selectTerminalEnabled } = useSelectTerminal(channels, Hwv, () => props.onBusy(true), () => props.onBusy(false), (errorMsg) => {
        setOpenAlertChannel(true);
        setChannelErrorDialogTitle('Select channel terminal error');
        setChannelErrorDialogContent(errorMsg);
    });
    const { addFaceGroup, editFaceGroup, enabled: faceGroupOperationEnabled } = useManageFaceGroups(jobContext, Hwv);
    const selectedChannel = jobContext.Categories.SelectedChannel;
    const addBobyAvailable = jobContext.Categories.Selected?.length !== 0;
    const canModifyChannel = props.disabled === false
        && selectTerminalEnabled === false
        && focusOnChannelEnabled === false
        && faceGroupOperationEnabled === false
        && repairBaffleEnabled === false;

    let channelHasFaceGroups = props.channelBodyType === ChannelBodyType.FACE_GROUPS;

    if (selectedChannel && (selectedChannel?.bodies ?? []).length > 0) {
        channelHasFaceGroups = selectedChannel.getBodyFaceGroups().length > 0;
    }

    const classes = makeStyles({
        container: {
            display: "flex",
            alignContent: "center",
            flexDirection: "row"
        },
        select: {
            borderRadius: "2em",
            borderStyle: "solid",
            borderWidth: "0px",
            borderColor: theme.palette.secondary.dark,
            marginTop: 0
        },
        item: {
            display: "flex",
            justifyContent: "flex-start",
            alignContent: "center",
            flexDirection: "row",
            width: "100%"
        },
        buttons: {
            justifyContent: 'flex-end',
            alignItems: 'center',
            cursor: 'pointer'
        },
        listHeader: {
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "row",
            alignItems: "end",
            position: "relative",
            width: "100%"
        },
        icon: {
            position: "relative",
            padding: "5px 15px 5px 15px",
            color: theme.palette.primary.dark,
            marginLeft: "10px"
        },
        elementsTitle: {
            fontSize: "0.95em",
            color: theme.palette.primary.dark,
            cursor: "pointer"
        },
        expandIcon: {
            cursor: "pointer",
            color: theme.palette.primary.dark,
        }
    })();

    useEffect(() => {
        if (!selectedChannel && channels.length > 0) {
            const channel = jobContext?.Categories.Channel[jobContext?.Categories.Channel.length - 1];
            jobContext.setSelectedChannel(channel);
        }
    }, [selectedChannel, channels])

    useEffect(() => {
        if (!jobContext.Categories.Selected?.length) return;

        if (jobContext.SelectionMode === ChannelBodyType.PARTS) {
            const parts = mapToPart(jobContext.Categories.Selected.map(f => f.path), jobContext.Tree);

            for (const part of parts) {
                const channel = jobContext.Categories.Channel.find(c => c.hasPart(part));

                if (channel) {
                    jobContext.setSelectedChannel(channel);
                    return;
                }
            }
        } else {
            const channel = jobContext.Categories.Channel.find(c => c.hasFaces(jobContext.Categories.Selected));

            if (channel) {
                jobContext.setSelectedChannel(channel);
                return;
            }
        }
    }, [jobContext.Categories.Selected]);

    useEffect(() => {
        if (channels.length === 0) {
            resetChannelsColors(colorContext);
        } else {
            setChannelsColors(channels, colorContext, selectedChannel);
        }

    }, [channels, selectedChannel]);

    const addSelectedBodiesToChannel = async (channelId: string, bodyType: ChannelBodyType) => {
        async function addToChannels(channelId: string, selected: TreeviewFace[], jobContext: JobData, hwv: Communicator.WebViewer, bodyType: ChannelBodyType) {
            const bodies = await getChannelBodies(selected, jobContext, hwv, bodyType, colorContext.getFaceOriginalColor);

            for (const body of bodies) {
                jobContext.addBodyToChannel(channelId, body);

                const baffles: TreeviewFaceGroup[] = isBaffleAutodetectionModeEnabled ? await detectBaffles(body, hwv, jobContext) : [];

                if (Channel.isTreeviewFaceGroup(body)) {
                    await repairBaffle(baffles, body.config, hwv, jobContext);
                }

                baffles.forEach(baffle => jobContext.addChannelBaffle(channelId, baffle.config));
            }
        }

        const selectedChannel = jobContext.Categories.Channel.find(c => c.id === channelId);

        if (!selectedChannel) {
            return;
        }

        const { isValid, message } = validateSelectedFaces(jobContext.Categories.Selected, jobContext);

        if (!isValid) {
            onChannelError(message);
        } else {
            props.onBusy(true);

            setTimeout(async () => {
                addToChannels(selectedChannel.id, jobContext.Categories.Selected, jobContext, Hwv, bodyType);
                Hwv.selectionManager.clear(true);
                props.onBusy(false);
            }, 500);
        }
    }

    const enterAddBaffleMode = async (channel: Channel) => {
        props.onSelectInletOutletBody(true);
        await isolateChannel([channel]);

        addFaceGroup(channel, async () => {
            await restoreVisibilityState();
            props.onSelectInletOutletBody(false);
        }, (errorMsg) => {
            setOpenAlertChannel(true);
            setChannelErrorDialogTitle(`${t("Channel baffle selection error")}`);
            setChannelErrorDialogContent(errorMsg);
        });
    }

    const editBaffleMode = async (channel: Channel, baffleId: string, target: 'bodies' | 'baffles') => {
        props.onSelectInletOutletBody(true);
        await isolateChannel([channel]);

        editFaceGroup(channel, baffleId, target, async () => {
            await restoreVisibilityState();
            props.onSelectInletOutletBody(false);

        }, (errorMsg) => {
            setOpenAlertChannel(true);
            setChannelErrorDialogTitle(`${t("Channel")} ${target} ${t("selection error")}`);
            setChannelErrorDialogContent(errorMsg);
        });
    };

    const repairBaffleMode = async (channel: Channel, baffleId: string) => {
        const baffle = channel.baffles.find(b => b.id === baffleId);

        if (!baffle) {
            return;
        }

        props.onSelectInletOutletBody(true);
        await isolateBaffles([baffle]);
        startRepair(baffleId, channel, async () => {
            await restoreVisibilityState();
            props.onSelectInletOutletBody(false);
        }, (errorMsg) => {
            setOpenAlertChannel(true);
            setChannelErrorDialogTitle(`${t("Channel baffle")} ${t("repair error")}`);
            setChannelErrorDialogContent(errorMsg);
        })
    }

    const bodyDeleted = (channel: Channel, bodyId: string) => {
        jobContext.removeBodyFromChannel(channel.id, bodyId);
    };

    const updateSelectedChannel = (channelId: string) => {
        const channel = jobContext.Categories.Channel.find(c => c.id === channelId);
        if (channel) {
            jobContext.setSelectedChannel(channel);
        }
    };

    const removeChannel = (channel: Channel) => {
        unregisterMeshes(channel.getSyntheticFaces().map(f => f.config));
        jobContext.removeFromChannels(channel.id);
    };

    const handleChannelChange = (event: SelectChangeEvent) => {
        updateSelectedChannel(event.target.value);
    };

    const isolateChannel = async (channels: Channel[]) => {
        updateVisibility({
            type: VisibilityStateActionType.ISOLATE_CHANNEL,
            items: channels.flatMap(c => c.getVisibleItems()),
        });
    }

    const isolateBaffles = async (baffles: TreeviewFaceGroup[]) => {
        updateVisibility({
            type: VisibilityStateActionType.ISOLATE_CHANNEL,
            items: baffles,
        });
    }

    const restoreVisibilityState = () => {
        updateVisibility({
            type: VisibilityStateActionType.RESTORE
        });
    }

    useEffect(() => {
        if (!jobContext.contextCleaned) return;

        if (inletOutletSelectionMode) {
            startTerminalSelection();
        } else {
            endTerminalSelection();
        }

    }, [jobContext.Categories.Channel, jobContext.contextCleaned, jobContext.SelectionMode, inletOutletSelectionMode]);

    const toggleInletOutletSelectionMode = () => {
        setInletOutletSelectionMode(inletOutletSelectionMode => {
            const newInletOutletSelectionMode = !inletOutletSelectionMode;

            if (newInletOutletSelectionMode) {
                isolateChannel(channels);
            } else {
                restoreVisibilityState();
            }

            props.onSelectInletOutletBody(newInletOutletSelectionMode)
            props.onToggleInletOutletSelectionMode(newInletOutletSelectionMode);

            return newInletOutletSelectionMode;
        });
    }

    const toggleOpenChannelSection = () => {
        setOpenChannelSection(!openChannelSection);
    }

    const categoryClickHandler = () => {
        async function addToChannels(selected: TreeviewFace[], jobContext: JobData, hwv: Communicator.WebViewer, bodyType: ChannelBodyType): Promise<void> {
            const channels: Partial<Channel>[] = [];
            const bodies = await getChannelBodies(selected, jobContext, hwv, bodyType, colorContext.getFaceOriginalColor);

            for (const body of bodies) {
                const baffles: TreeviewFaceGroup[] = isBaffleAutodetectionModeEnabled ? await detectBaffles(body, hwv, jobContext) : [];

                if (Channel.isTreeviewFaceGroup(body)) {
                    await repairBaffle(baffles, body.config, hwv, jobContext);
                }

                channels.push({
                    bodies: [body],
                    baffles
                })
            }

            jobContext.addToChannels(channels);

            props.onBusy(false);
        }

        const { isValid, message } = validateSelectedFaces(jobContext.Categories.Selected, jobContext);

        if (!isValid) {
            onChannelError(message);
        } else {
            props.onBusy(true);

            setTimeout(async () => {
                addToChannels(jobContext.Categories.Selected, jobContext, Hwv, props.channelBodyType);
                Hwv.selectionManager.clear(true);
                props.onBusy(false);
            }, 500);
        }
    };

    const onChannelError = (message: string) => {
        setOpenAlertChannel(true);
        setChannelErrorDialogContent(message);
    }

    return (<>
        <ErrorDialog
            open={openAlertChannel}
            onClose={() => setOpenAlertChannel(false)}
            title={channelErrorDialogTitle}
            message={channelErrorDialogContent} jobId={""} projectId={""} showEmailLink={false} />

        <FormGroup sx={{ mb: "12px" }}>
            <Tooltip title={t("Toggle this option to enter/exit Inlet/Outlet selection mode")}>
                <FormControlLabel disabled={focusOnChannelEnabled} id="inlet-outlet-selection-mode-toggle" control={<Switch color="primary" onClick={() => { toggleInletOutletSelectionMode() }} checked={inletOutletSelectionMode} />}
                    label={t("Inlet/outlet selection mode")} />
            </Tooltip>
            <Tooltip title={t("Toggle this option to enter/exit Baffle autodetection mode")}>
                <FormControlLabel id="baffle-autodetection-mode-toggle" disabled={!canModifyChannel} control={<Switch color="primary" onClick={() => {
                    setIsBaffleAutodetectionModeEnabled(!isBaffleAutodetectionModeEnabled);
                }} checked={isBaffleAutodetectionModeEnabled} />} label={t("Detect Baffles Automatically")} />
            </Tooltip>
            {inletOutletSelectionMode && <Grid sx={{ display: "flex", flexDirection: "column", padding: "5px 5px 0px 5px", width: "100%" }}>
                <Box sx={{ padding: "10px" }}>
                    <Box sx={{ display: "flex", alignItems: "center", mb: "3px", }}>
                        <CircleIcon sx={{ fontSize: "1.2em", mr: "5px", color: `rgb(${intletColor.R},${intletColor.G},${intletColor.B})` }}></CircleIcon><Typography>{t("Inlet selection")} <br /><b>{t("left-click")}</b></Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <CircleIcon sx={{ fontSize: "1.2em", mr: "5px", color: `rgb(${outletColor.R},${outletColor.G},${outletColor.B})` }}></CircleIcon><Typography>{t("Outlet selection")} <br /><b>{t("shift + left-click")}</b></Typography>
                    </Box>
                </Box>
            </Grid>}
            {repairBaffleEnabled && <Grid sx={{ display: "flex", flexDirection: "column", padding: "5px 5px 0px 5px", width: "100%" }}>
                <Box sx={{ padding: "10px" }}>
                    <Box sx={{ display: "flex", alignItems: "center", mb: "3px", }}>
                        <CircleIcon sx={{ fontSize: "1.2em", mr: "5px", color: `rgb(${intletColor.R},${intletColor.G},${intletColor.B})` }}></CircleIcon><Typography>{t("Create face")} <br /><b>{t("Enter")}</b></Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", mb: "3px", }}>
                        <CircleIcon sx={{ fontSize: "1.2em", mr: "5px", color: `rgb(255,0,0)` }}></CircleIcon><Typography>{t("Delete face")} <br /><b>{t("Del")}</b></Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <CircleIcon sx={{ fontSize: "1.2em", mr: "5px", color: `rgb(${outletColor.R},${outletColor.G},${outletColor.B})` }}></CircleIcon><Typography>{t("Exit repair mode")} <br /><b>{t("Esc")}</b></Typography>
                    </Box>
                </Box>
            </Grid>}
        </FormGroup>

        <Box id={"channel-section-items-expand"} className={classes.listHeader}>
            <Fab sx={{ mr: "10px", ml: "0px !important" }} disabled={!canModifyChannel} id={"add-icon-" + MoldCategory[MoldCategory.Channel]} className={classes.icon} color="default" size="small" aria-label="add" onClick={() => { categoryClickHandler() }}>
                <AddIcon />
            </Fab>
            {openChannelSection ? <ExpandLess className={classes.expandIcon} onClick={toggleOpenChannelSection} /> : <ExpandMore className={classes.expandIcon} onClick={toggleOpenChannelSection} />}
            <Typography className={classes.elementsTitle} onClick={toggleOpenChannelSection}>{channels.length + " " + t("channel(s)")} </Typography>
        </Box>
        <Collapse id="channel-list-collapse" in={openChannelSection} sx={{ width: "100%" }}>
            <Grid container className={classes.container} sx={{ mt: "20px" }} >
                {jobContext.Categories.Channel.length > 0 && <>
                    <Grid item className={classes.item} xs={8}>
                        <FormControl fullWidth>
                            <Select disabled={!canModifyChannel} id={"channel-selection-dropdown"} value={selectedChannel?.id ?? ''} size="small" onChange={handleChannelChange} className={classes.select}>
                                {channels.sort((c1, c2) => c1.name.localeCompare(c2.name, undefined, {
                                    sensitivity: 'base',
                                    numeric: true
                                })).map((channel: Channel) => {
                                    return (<MenuItem key={channel.id} value={channel.id}>
                                        {channel.name}
                                    </MenuItem>)
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    {selectedChannel && <>
                        <Grid item className={[classes.item, classes.buttons].join(' ')} xs={4}>
                            <IconButton onClick={() => focusOnChannel(selectedChannel)} sx={{ padding: 0 }} disabled={!canModifyChannel}>
                                <CenterFocusStrongIcon titleAccess={t("Display channel")} color={!canModifyChannel ? 'disabled' : 'primary'} />
                            </IconButton>
                            {
                                channelHasFaceGroups === false &&
                                <IconButton disabled={!canModifyChannel || props.channelBodyType !== ChannelBodyType.PARTS} onClick={() => addBobyAvailable && addSelectedBodiesToChannel(selectedChannel.id, ChannelBodyType.PARTS)} sx={{ padding: 0 }}>
                                    <InsightsRoundedIcon id={"add-channel-body-btn"}
                                        titleAccess={t("Add Body")}
                                        color={!canModifyChannel || props.channelBodyType !== ChannelBodyType.PARTS ? 'disabled' : 'primary'}></InsightsRoundedIcon>
                                </IconButton>
                            }
                            {
                                channelHasFaceGroups === true &&
                                <IconButton disabled={!canModifyChannel || props.channelBodyType !== ChannelBodyType.FACE_GROUPS} onClick={() => addBobyAvailable && addSelectedBodiesToChannel(selectedChannel.id, ChannelBodyType.FACE_GROUPS)} sx={{ padding: 0 }}>
                                    <FlareIcon id={"add-channel-body_face-btn"}
                                        titleAccess={t("Pick Channel Face")}
                                        color={!canModifyChannel || props.channelBodyType !== ChannelBodyType.FACE_GROUPS ? 'disabled' : 'primary'}></FlareIcon>
                                </IconButton>
                            }

                            <IconButton disabled={!canModifyChannel} onClick={() => enterAddBaffleMode(selectedChannel)} sx={{ padding: 0 }}>
                                <AlignVerticalTopRoundedIcon id='add-channel-baffle-btn'
                                    color={!canModifyChannel ? 'disabled' : 'primary'} />
                            </IconButton>

                            <IconButton disabled={!canModifyChannel} onClick={() => removeChannel(selectedChannel)} sx={{ padding: 0 }}>
                                <RemoveCircleTwoToneIcon id={"remove-channel-body-btn"} titleAccess={t("Remove channel")} color={!canModifyChannel ? 'disabled' : 'primary'} />
                            </IconButton>
                        </Grid>
                    </>}
                    {selectedChannel && <Grid container component='div' onMouseLeave={() => canModifyChannel && props.Hwv.model.resetModelHighlight()}>
                        <Grid item xs={12} className={classes.item}><ChannelBodies channel={selectedChannel} Hwv={Hwv} onBodyDeleted={bodyDeleted} onEnterEditMode={editBaffleMode} disableControl={!canModifyChannel} disableHighlighting={!canModifyChannel}></ChannelBodies></Grid>
                        <Grid item xs={12} className={classes.item}><ChannelTerminals channel={selectedChannel} Hwv={Hwv} terminals={selectedChannel.inlets} disableControl={!canModifyChannel} disableHighlighting={!canModifyChannel}></ChannelTerminals></Grid>
                        <Grid item xs={12} className={classes.item}><ChannelTerminals channel={selectedChannel} Hwv={Hwv} terminals={selectedChannel.outlets} disableControl={!canModifyChannel} disableHighlighting={!canModifyChannel}></ChannelTerminals></Grid>
                        <Grid item xs={12} className={classes.item}><ChannelBaffles channel={selectedChannel} Hwv={Hwv} onEnterEditMode={editBaffleMode} onEnterRepairMode={repairBaffleMode} disableControl={!canModifyChannel} disableHighlighting={!canModifyChannel}></ChannelBaffles></Grid>
                    </Grid>}
                </>}
            </Grid>
        </Collapse>

        <Dialog
            open={openAlertChannel}
            onClose={() => { setOpenAlertChannel(false) }}
        >
            <DialogTitle id="alert-dialog-title">
                {t("Channel selection error")}
            </DialogTitle>
            <DialogContent>
                <div id="alert-dialog-description">
                    <Alert severity="error">{channelErrorDialogContent}</Alert>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { setOpenAlertChannel(false) }}>
                    {t("Ok")}
                </Button>
            </DialogActions>
        </Dialog>
    </>);
}