import React, { PropsWithChildren } from 'react';
import AuthenticationService from './AuthenticationService';
import { UserRole } from '../components/users/UserList';

export type AuthContextType = {
    id: string,
    username: string,
    email: string,
    isAdmin: boolean,
    isSupervisor: boolean,
    isBetaTester: boolean,
    isEmployee: boolean,
    companyName: string, 
    role: UserRole,
    logout: () => Promise<void>,
    token: () => Promise<string | undefined>
};

export type AuthContextProviderProps = {
    authenticationService: AuthenticationService;
};

export const AuthContext = React.createContext<AuthContextType>({
    id: '',
    username: '',
    email: '',
    isAdmin: false,
    isSupervisor: false,
    isBetaTester: false,
    isEmployee: false,
    companyName: "",
    role: UserRole.User,
    logout: () => Promise.resolve(undefined),
    token: () => Promise.resolve(undefined)
});

export const AuthContextProvider = (props: PropsWithChildren<AuthContextProviderProps>) => {
    return (
        <AuthContext.Provider value={{
            id: props.authenticationService.userid as string,
            username: props.authenticationService.username as string,
            email: props.authenticationService.userEmail as string,
            isAdmin: props.authenticationService.isAdmin(),
            isSupervisor: props.authenticationService.isSupervisor(),
            isBetaTester: props.authenticationService.isBetaTester(),
            isEmployee: props.authenticationService.isEmployee(),
            companyName: props.authenticationService.userCompanyName,
            role: props.authenticationService.role,
            logout: props.authenticationService.logout.bind(props.authenticationService),
            token: props.authenticationService.retrieveAccessToken.bind(props.authenticationService)
        }}>
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthContext;