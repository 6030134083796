import { Grid, InputAdornment, TextField, Tooltip, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { useContext, useEffect, useState } from "react";
import JobContext from "../../store/job/job-context";
import maintheme from "../../styles/main-theme";
import { useTranslation } from 'react-i18next';

interface FrozenWallThicknessProps {
    disabled: boolean;
}
export const FrozenWallThickness = ({ disabled }: FrozenWallThicknessProps) => {
    const jobContext = useContext(JobContext);
    const [wallThickness, setWallThickness] = useState<number|string>(40);
    const { t } = useTranslation();
    const classes = makeStyles({
        item: {
            display: "flex",
            justifyContent: "flex-start",
            alignContent: "center",
            flexDirection: "row",
            "&:last-child": {
                marginLeft: "auto"
            }
        }
    })();

    useEffect(() => {
        if (jobContext.Categories.Cooling.frozenWallThickness) {
            const thickness = convertWallThickness(Number(jobContext.Categories.Cooling.frozenWallThickness))
            setWallThickness(thickness.frozenWallThickness);
        }
      }, [jobContext.Categories.Cooling.frozenWallThickness]);

    //converts wall thickness to solver standard. 
    //solver range: 10%-50%, ui range: 20%-100%
    function convertWallThickness(value: number) {
        return {
            frozenWallThickness: value * 2,
            solverWallThickness: value / 2
        }
    }
    const onTextFieldChange = (value: number|string) => {
        if (value == '') {
            setWallThickness('');

        } else {
            setWallThickness(Number(value));
            jobContext.setFrozenWallThickness(convertWallThickness(Number(value)).solverWallThickness);

        }
    }

    return (
        <div>
            <Tooltip placement="left" title={
                <>
                    <div style={{ marginBottom: '5px' }}>{t("Default frozen wall thickness: 40%")} <br/></div>
                    <div>{t("Expected range: 20% to 100%")} <br/></div>
                </>
            }>
                <Grid item className={classes.item} xs={12} >
                    <TextField disabled={disabled} id={"plastic-mold-frozen-wall-thickness"} label={t("Frozen Wall Thickness")} type='number'
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">%</InputAdornment>
                        }}
                        inputProps={{
                            step: 1,
                            min: 20,
                            max: 100
                        }}
                        onChange={(event: any) => onTextFieldChange(Number(event.target.value))}
                        value={wallThickness}
                        error={(Number(wallThickness) < 20 || Number(wallThickness) > 100) ? true : false}
                        sx={{
                            marginTop: '1.2em',
                            marginBottom:'5px',
                            width: '100%',
                            '& .MuiInputBase-root': { borderRadius: '2em' },
                            '& .MuiInputLabel-root': { fontSize: '1.2rem', backgroundColor: maintheme.palette.background.paper}
                        }}
                    />
                </Grid>
            </Tooltip>
        </div>
    );
}
