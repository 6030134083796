import * as cee from "@ceetron/common/CeeEnvisionWebComponents";
import { useState, useEffect, useContext } from "react";
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { CeetronActionType, CeetronContext, DisplayMode, DisplayPreset, VtfxStateInfo } from "../../store/job/ceetron-context";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import { FastForward, FastRewind, PlayArrow, SkipNext, SkipPrevious, Stop } from "@mui/icons-material";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { findDefaultTimeStepIndex } from "../../services/CeetronService";
import { useTranslation } from 'react-i18next';

interface StatesControlProps {
    model: cee.ug.RemoteModel;
    ejectionTime: number;
    modelIndex: number;
    name?: string;
}

const useStyles = makeStyles({
    headers: {
        marginTop: "15px"
    },
    subheaders: {
        fontSize: "1rem",
        marginTop: "15px"
    },
    container: {
        padding: "0px 20px 20px 0px",
        borderBottom: "1px solid"
    },
    buttons: {
        display: "none",
        justifyContent: "space-around",
        flexWrap: "wrap",
        alignItems: "stretch",
        marginTop: "1em",
        rowGap: "0.5em"
    },
    buttonsLeft: {
        display: "none",
        justifyContent: "space-around",
        flexWrap: "wrap",
        marginTop: "1em",
        rowGap: "0.5em"
    },
    select: {
        marginTop: "1em",
        width: "100%"
    },
    frinput: {
        width: "5em"
    }
});

export const StatesControl = (props: StatesControlProps) => {
    const [timeStepIndexForEjectionTime, setTimeStepIndexForEjectionTime] = useState<number>(0);
    const [position, setPosition] = useState<number>(0);
    const [frameRate, setFrameRate] = useState<number>(8);
    const [infoArray, setInfoArray] = useState<VtfxStateInfo[]>([]);
    const context = useContext(CeetronContext);
    const classes = useStyles();
    const { model, ejectionTime, modelIndex, name } = props;
    const { t } = useTranslation();
    useEffect(() => {
        if (props.model && props.model.modelDirectory && typeof props.ejectionTime === 'number') {
            setTimeStepIndexForEjectionTime(findDefaultTimeStepIndex(props.model, props.ejectionTime));
        }
    }, [props.ejectionTime]);

    useEffect(() => {
        if (model) {
            updateFrameDuration(8);
        }
        let lastIndex = context.ceetronState.vtfxStates[modelIndex].lastIndex;
        if (context.ceetronState.displayPreset === DisplayPreset.FREEZE_TIME) {
            lastIndex = context.ceetronState.vtfxStates[modelIndex].defaultIndex;
        }
        setPosition(lastIndex);
    }, [context.ceetronState.displayPreset]);

    useEffect(() => {
        setPosition(context.ceetronState.vtfxStates[modelIndex].currentIndex);
    }, [context.ceetronState.vtfxStates[modelIndex].currentIndex]);

    useEffect(() => {
        setInfoArray(context.ceetronState.vtfxStates[modelIndex].stateInfoArray);
    }, [context.ceetronState.vtfxStates[modelIndex].stateInfoArray]);

    function updateContextStateIndex(index: number) {
        context.updateCeetronState({ type: CeetronActionType.SetVtfxStateIndex, payload: {value: index, modelIndex} });
    }

    function playAnimation() {
        if (!isAnimationSetup()) {
            setupAnimation();
        }
        model.animation.runAnimation(true);
    }

    function stopAnimation() {
        const currentIndex = model.currentFrameIndex;
        model.animation.runAnimation(false);
        updateContextStateIndex(currentIndex);
    }

    function previousState() {
        const index = position - 1;
        if (!model.modelDirectory.stateInfoArray[index]) {
            lastState();
        } else {
            updateContextStateIndex(index);
        }
    }

    function nextState() {
        const index = position + 1;
        if (!model.modelDirectory.stateInfoArray[index]) {
            firstState();
        } else {
            updateContextStateIndex(index);
        }
    }

    function firstState() {
        updateContextStateIndex(0);
    }

    function lastState() {
        const lastIndex = model.modelDirectory.stateInfoArray.length > 0 ? model.modelDirectory.stateInfoArray.length - 1 : 0;
        updateContextStateIndex(lastIndex);
    }

    function updateFrameDuration(value: number) {
        let finalValue = 1;
        if (value > 0) {
            finalValue = value;
        }
        setFrameRate(finalValue);
        model.animation.targetFrameRate = finalValue;
    }

    function setFasterAnimation() {
        updateFrameDuration(Math.ceil(1.1 * frameRate));
    }

    function setSlowerAnimation() {
        updateFrameDuration(Math.floor(0.9 * frameRate));
    }

    function isAnimationSetup() {
        return model.modelSpec.stateIdArray.length === context.ceetronState.vtfxStates[modelIndex].stateInfoArray.length &&
            context.ceetronState.vtfxStates[modelIndex].stateInfoArray.every(si => model.modelSpec.stateIdArray.includes(si.id));
    }

    function setupAnimation() {
        model.modelSpec.stateIdArray = model.modelDirectory.stateInfoArray.map(si => si.id);
        model.animation.setAnimationFrameChangedCallback((frameIndex) => {
            updateContextStateIndex(frameIndex)
        });
    }

    return (
        <div className={classes.container}>
            <Typography variant="h6" className={classes.headers}>{t("Simulation time")}</Typography>
            {name && <Typography variant="h5" className={classes.subheaders}> {name} </Typography>}
            {infoArray.length > 1 && <Select className={classes.select} value={position} disabled={!context.ceetronState.isViewerReady || context.ceetronState.displayPreset === DisplayPreset.FREEZE_TIME} onChange={(event: any, value: any) => updateContextStateIndex(event.target.value)}>
                {infoArray.map((option, index) => {
                    return (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label ?? option.value} {ejectionTime && index === timeStepIndexForEjectionTime ? ' *' : ''}
                        </MenuItem>
                    );
                })}
            </Select>}
            <div className={classes.buttonsLeft}>
                <Input
                    className={classes.frinput}
                    value={frameRate}
                    onChange={(event: any) => updateFrameDuration(event.target.value)}
                    inputProps={{
                        step: 1,
                        type: 'number'
                    }}
                    endAdornment={<InputAdornment position="start">fps</InputAdornment>} />
            </div>
            <div className={classes.buttons}>
                <Button aria-label="First step" variant="contained" color="secondary" disabled={!context.ceetronState.isViewerReady} sx={{ minWidth: 'fit-content', paddingLeft: '0.5em', paddingRight: '0.5em', zoom: 1.2 }} onClick={firstState.bind(this)} >
                    <SkipPrevious />
                </Button>
                <Button aria-label="Previous step" variant="contained" color="secondary" disabled={!context.ceetronState.isViewerReady} sx={{ minWidth: 'fit-content', paddingLeft: '0.5em', paddingRight: '0.5em', zoom: 1.2 }} onClick={previousState.bind(this)} >
                    <FastRewind />
                </Button>
                <Button aria-label="Play animation" variant="contained" color="secondary" sx={{ display: context.ceetronState.isViewerReady && (!model?.animation.isAnyAnimationRunning()) ? 'inline-flex' : 'none', minWidth: 'fit-content', paddingLeft: '0.5em', paddingRight: '0.5em', zoom: 1.2 }} onClick={playAnimation.bind(this)} >
                    <PlayArrow />
                </Button>
                <Button aria-label="Stop animation" variant="contained" color="secondary" sx={{ display: !context.ceetronState.isViewerReady || model?.animation.isAnyAnimationRunning() ? 'inline-flex' : 'none', minWidth: 'fit-content', paddingLeft: '0.5em', paddingRight: '0.5em', zoom: 1.2 }} onClick={stopAnimation.bind(this)} >
                    <Stop />
                </Button>
                <Button aria-label="Next step" variant="contained" color="secondary" disabled={!context.ceetronState.isViewerReady} sx={{ minWidth: 'fit-content', paddingLeft: '0.5em', paddingRight: '0.5em', zoom: 1.2 }} onClick={nextState.bind(this)} >
                    <FastForward />
                </Button>
                <Button aria-label="Last step" variant="contained" color="secondary" disabled={!context.ceetronState.isViewerReady} sx={{ minWidth: 'fit-content', paddingLeft: '0.5em', paddingRight: '0.5em', zoom: 1.2 }} onClick={lastState.bind(this)} >
                    <SkipNext />
                </Button>
            </div>
        </div>
    )
}