import { Alert, Box, Paper, TextField, Typography, useTheme, Tooltip } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { useReducer, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import debounce from "lodash.debounce"

export interface ExtraJobConfig{
    expectedCoolingTime?: number | null
}

export interface ExtraJobConfigurationProps{
    disabled: boolean,
    extraConfig: ExtraJobConfig,
    onChangeConfiguration: (data: ExtraJobConfig) => void
}

export interface ExtraJobConfigReducerAction{
    type: string,
    value: ExtraJobConfig
}

interface JobConfigState{
    config: ExtraJobConfig,
    errors: string[]
}

const maxExpectedCoolingTimeValue = 600;

const reducer = (state: JobConfigState, action: ExtraJobConfigReducerAction): JobConfigState => {
    if(action.type == "SetExpectedCoolingTimeMaxValue"){
        return {
            ...state,
            config: {
                ...state.config,
                expectedCoolingTime: maxExpectedCoolingTimeValue
            },
            errors: [`Max value for expected cooling time is ${maxExpectedCoolingTimeValue}`]
        }
    }else if(action.type == "SetExpectedCoolingTime"){
        return {
            ...state,
            config: {
                ...state.config,
                expectedCoolingTime: action.value?.expectedCoolingTime
            },
            errors: []
        }
    }

    return state;
}

export const ExtraJobConfiguration = (props: ExtraJobConfigurationProps) => {
    const [jobConfiguration, dispatchJobConfiguration] = useReducer(reducer, {
        config: {
            expectedCoolingTime: props.extraConfig.expectedCoolingTime
        },
        errors: []        
    });

    const { t } = useTranslation();

    const debounceUpdate = useCallback(debounce((config: ExtraJobConfig, onChangeConfig: any) => {
        onChangeConfig(config);
    }, 500), []);

    const theme = useTheme();
    const classes = makeStyles({
        header: {
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            marginBottom: "1.3em"
        },
        paper: {
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "1em 1em 1.4em 1em"
        }
    })();
    
    const onChangeExpectedCoolingTime = (e: any) => {
        const value = Number(e.target.value)

        const config: ExtraJobConfig = {
            expectedCoolingTime: value
        };

        if(Number.isNaN(value) || value == 0){
            config.expectedCoolingTime = null;
        }else if(value > maxExpectedCoolingTimeValue){
            config.expectedCoolingTime = maxExpectedCoolingTimeValue;
        }
        
        dispatchJobConfiguration({type: "SetExpectedCoolingTime", value: config});
        //using lodash debounce
        debounceUpdate(config, props.onChangeConfiguration);
    }

    useEffect(() => {
        dispatchJobConfiguration({type: "SetExpectedCoolingTime", value: props.extraConfig});
    }, [props.extraConfig.expectedCoolingTime])

    return (
        <Box sx={{ position: "relative" }}>
            <Paper elevation={1} className={classes.paper}>
                <div className={classes.header}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: theme.palette.primary.dark }}>{t("Optional Configuration")}</Typography>
                </div>
                {jobConfiguration.errors.length > 0 && <Alert severity="warning">
                    <ul style={{paddingLeft: "15px", margin: 0}}>
                        {jobConfiguration.errors.map((e, index) => {
                            return <li key={index}>{e}</li>
                        })}
                    </ul>
                </Alert>}
                <TextField
                    disabled={props.disabled} 
                    id={"expectedCoolingTimeTextField"} 
                    label={t("ExpectedCoolingTimeInSecondsLabel")} 
                    type='number'
                    size="small"
                    onKeyDown={(event) => {
                        if(event.key != "Backspace" && Number.isNaN((Number(event.key)))){
                            event.preventDefault();
                        }
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        step: 1,
                        min: 0,
                        max: 600
                    }}
                    sx={{
                        marginTop: '1em',
                        marginBottom: "10px",
                        width: '100%',
                        '& .MuiInputBase-root': { borderRadius: '2em' },
                        '& .MuiInputLabel-root': { fontSize: '1.2rem', backgroundColor: 'white' }
                    }}
                    onChange={onChangeExpectedCoolingTime}
                    value={jobConfiguration.config.expectedCoolingTime ?? ''}
                />                    
            </Paper>
        </Box>
    )
}