import { useCallback, useEffect, useMemo, useState } from "react";
import { GetNodeFromId } from "../../../services/TreeviewFunctions";
import { Channel } from "../../../store/job/channel";
import JobData, { TreeviewFace, TreeviewFaceGroup } from "../../../store/job/job-data";
import { SelectDisplayGroupOperator } from "../operators/SelectDisplayGroupOperator";
import { repairBaffle } from "../helpers/autodetection";

export type ManageFaceGroupsErrorCallback = (message: string) => void;

export type ManageFaceGroupsCompleteCallback = () => void;

const areDisplayGroupsAlreadyUsedAsBaffleInChannel = (displayGroups: TreeviewFace[], channel: Channel, existingBaffle?: TreeviewFaceGroup) => {
    return displayGroups.some(dg => {
        return channel.baffles.some(b => {
            if (b.id === existingBaffle?.id) { // skip current baffle when editing to not collide with faces already there
                return false;
            }
            return b.config.some(c => c.nodeId === dg.nodeId && c.faceIndex === dg.faceIndex);
        });
    });
}

export function useManageFaceGroups(jobContext: JobData, hwv: Communicator.WebViewer) {
    const [enabled, setEnabled] = useState<boolean>(false);
    const operatorId = useMemo(() => {
        if (hwv && jobContext.IsTreeLoaded) {
            return hwv.registerCustomOperator(new SelectDisplayGroupOperator(hwv, true));
        }
        return Communicator.OperatorId.None;
    }, [jobContext.IsTreeLoaded, hwv]);

    useEffect(() => {
        return () => {
            hwv && hwv.unregisterCustomOperator(operatorId);
        }
    }, [hwv, operatorId]);

    const addFaceGroup = useCallback((channel: Channel, completeCb?: ManageFaceGroupsCompleteCallback, errorCb?: ManageFaceGroupsErrorCallback) => {
        if (operatorId !== Communicator.OperatorId.None) {
            const operator = hwv.operatorManager.getOperator(operatorId) as SelectDisplayGroupOperator;

            operator.setSelectedEntities([]);

            operator.onSelect = async (selectedFaces) => {
                const displayGroups: TreeviewFace[] = selectedFaces.map(sf => {
                    const node = GetNodeFromId(sf.nodeId, jobContext);

                    return {
                        ...sf,
                        path: node?.path ?? ''
                    }
                })

                if (areDisplayGroupsAlreadyUsedAsBaffleInChannel(displayGroups, channel)) {
                    errorCb?.('Some items in your selection were already used as part of a baffle for this channel')
                } else if (selectedFaces.length) {
                    const newBaffle: TreeviewFaceGroup = {
                        id: '',
                        name: '',
                        config: displayGroups
                    }
                    const channelBodyFaces = channel.getBodyFaceGroups().flatMap(fg => fg.config);
                    
                    if (channelBodyFaces.length) {
                        await repairBaffle([newBaffle], channelBodyFaces, hwv, jobContext);
                    }

                    //await repairBaffle([newBaffle], channel.bodies, hwv, jobContext);
                    jobContext.addChannelBaffle(channel.id, newBaffle.config);
                }
            };

            hwv.operatorManager.push(operatorId);
            setEnabled(true);

            operator.onClose = () => {
                hwv.operatorManager.remove(operatorId);
                setEnabled(false);
                completeCb?.();
            };
        }
    }, [hwv, operatorId]);

    const editFaceGroup = useCallback((channel: Channel, itemId: string, target: 'bodies' | 'baffles',
        completeCb?: ManageFaceGroupsCompleteCallback, errorCb?: ManageFaceGroupsErrorCallback) => {
            if (operatorId !== Communicator.OperatorId.None) {
                const operator = hwv.operatorManager.getOperator(operatorId) as SelectDisplayGroupOperator;
                const item = channel[target].find(i => i.id === itemId);

                if (!Channel.isTreeviewFaceGroup(item)) {
                    return;
                }

                operator.setSelectedEntities(item.config);

                operator.onSelect = async (selectedFaces) => {
                    const displayGroups: TreeviewFace[] = selectedFaces.map(sf => {
                        const node = GetNodeFromId(sf.nodeId, jobContext);
    
                        return {
                            ...sf,
                            path: node?.path ?? ''
                        }
                    });
    
                    if (selectedFaces.length && item && Channel.isTreeviewFaceGroup(item)) {
                        if (target === 'bodies') {
                            jobContext.editChannelBody(channel.id, item.id, displayGroups);
                        }
                        if (target === 'baffles') {
                            const newBaffle: TreeviewFaceGroup = {
                                id: '',
                                name: '',
                                config: displayGroups
                            }
                            
                            const channelBodyFaces = channel.getBodyFaceGroups().flatMap(fg => fg.config);

                            if (channelBodyFaces.length) {
                                await repairBaffle([newBaffle], channelBodyFaces, hwv, jobContext);
                            }
                            jobContext.editChannelBaffle(channel.id, item.id, newBaffle.config);
                        }
                    }
                };
    
                hwv.operatorManager.push(operatorId);
                setEnabled(true);
    
                operator.onClose = () => {
                    hwv.operatorManager.remove(operatorId);
                    setEnabled(false);
                    completeCb?.();
                };
            }
    }, [hwv, operatorId]);


    return {
        enabled,
        addFaceGroup,
        editFaceGroup,
    }
}