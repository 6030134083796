import { createContext, Dispatch, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { TreeviewItemModel } from "../../components/job/TreeviewNodeModel";
import { GetAllRelatedNodeIds, getBasicMoldPart } from "../../services/TreeviewFunctions";
import { makeFaceKey, parseFaceKey } from "../../utils/hoops.utils";
import { Channel } from "./channel";
import { Part, Tree, TreeviewFaceGroup } from "./job-data";
import { useUpdateOnChange } from "../../hooks/useUpdateOnChange";
import JobContext from "./job-context";
import { SyntheticMesh, useVisibilityReducer, VisibilityMode, VisibilityStateAction, VisibilityStateActionType } from "./hoops-visibility-reducer";

export type HoopsVisiblityContextType = {
    mode: VisibilityMode,
    updateVisibility: Dispatch<VisibilityStateAction>,
    getVisibility: (item: Part | TreeviewFaceGroup) => boolean
}

export const HoopsVisibilityContext = createContext<HoopsVisiblityContextType>({
    updateVisibility: () => null,
    getVisibility: () => true,
    mode: VisibilityMode.REGULAR
})

type FaceVisibilityRecord = {
    visible: number[],
    hidden: number[]
}

export function useVisibilityContext(syntheticMeshes: SyntheticMesh[], hwv: Communicator.WebViewer | null) {
    const jobContext = useContext(JobContext);
    const [changesToNodeVisibility, setNodeVisibilityMap] = useState<Map<number, boolean>>(new Map());
    const [changesToFaceVisibility, setFaceVisibilityMap] = useState<Map<number, FaceVisibilityRecord>>(new Map());
    const meshes = useUpdateOnChange(syntheticMeshes);
    const treeNodeItemNodeIdMap = useMemo(() => {
        const map = new Map<number, TreeviewItemModel>();

        if (jobContext.IsTreeLoaded) {
            const items = Object.values(jobContext.Tree);

            for (const item of items) {
                item.nodeIds.forEach(nodeId => map.set(nodeId, item));
            }
        }
        return map;
    }, [jobContext.IsTreeLoaded]);
    const getRelatedNodes = useCallback((nodeIds: number[]) => hwv !== null ? GetAllRelatedNodeIds(nodeIds, hwv) : [], [hwv]);
    const getRelatedParts = useCallback((nodeIds: number[]): Part[] => {
        const treeNodes: TreeviewItemModel[] = [];
        const treeNodeIds: Set<string> = new Set();

        for (const [nodeId, entry] of treeNodeItemNodeIdMap.entries()) {
            if (nodeIds.includes(nodeId) && !treeNodeIds.has(entry.id)) {
                treeNodes.push(entry);
                treeNodeIds.add(entry.id);
            }
        };

        const parts: Part[] = treeNodes.map(treeNode => ({
            id: treeNode.id,
            name: treeNode.name,
            path: treeNode.path,
            cadFileName: treeNode.cadFileName,
            nodesIds: treeNode.nodeIds
        }))

        if (jobContext.basicMold.enabled && nodeIds.some(nodeId => jobContext.basicMold.nodesIds.includes(nodeId))) {
            const basicMoldPart = getBasicMoldPart(jobContext.basicMold.nodesIds);

            parts.push(basicMoldPart);
        }

        return parts;
    }, [treeNodeItemNodeIdMap, jobContext.basicMold]);

    const [state, dispatch] = useVisibilityReducer(getRelatedNodes);

    const getVisibility = useCallback((item: Part | TreeviewFaceGroup): boolean => {
        if (Channel.isTreeviewFaceGroup(item)) {
            return item.config.every(face => state.faceVisibilityMap.get(makeFaceKey(face)) === true);
        } else {
            return item.nodesIds.every(nodeId => {
                return state.nodeVisibilityMap.get(nodeId) === true;
            });
        }
    }, [state]);

    const updateSceneFromHoops = useCallback((showBodyIds: number[], hideBodyIds: number[]) => {
        if (hideBodyIds.length === 0) {
            dispatch({
                type: VisibilityStateActionType.RESET
            });
        }

        if (hideBodyIds.length > 0 && showBodyIds.length === 0) {
            const parts = getRelatedParts(hideBodyIds);

            setNodeVisibilityMap(prevNodeVisibilityMap => {
                const map = new Map(prevNodeVisibilityMap);

                hideBodyIds.forEach(nodeId => map.has(nodeId) && map.set(nodeId, false));
                return map;
            });

            dispatch({
                type: VisibilityStateActionType.SET_PART_VISIBILITY,
                items: parts,
                visibility: false
            });
        }

        if (showBodyIds.length > 0 && hideBodyIds.length > 0 && showBodyIds.length < hideBodyIds.length) {
            const parts = getRelatedParts(showBodyIds);

            setNodeVisibilityMap(prevNodeVisibilityMap => {
                const map = new Map(prevNodeVisibilityMap);

                for (const [nodeId, _] of map) {
                    map.set(nodeId, showBodyIds.includes(nodeId));
                }

                return map;
            });


            dispatch({
                type: VisibilityStateActionType.ISOLATE_PART,
                items: parts,
            });
        }
    }, [getRelatedNodes, getRelatedParts]);


    useEffect(() => {
        dispatch({
            type: VisibilityStateActionType.UPDATE_SYNTHETIC_MESHES,
            meshes
        });
    }, [meshes]);

    useEffect(() => {
        if (state.isSilent) {
            return;
        }

        const changesToNodeVisibility: Map<number, boolean> = new Map();
        const changesToFaceVisibility: Map<number, {
            visible: number[],
            hidden: number[]
        }> = new Map();


        for (const [nodeId, visibility] of state.nodeVisibilityMap.entries()) {
            changesToNodeVisibility.set(nodeId, visibility);
        }

        for (const [nodeId, visibility] of state.syntheticMeshVisibilityMap.entries()) {
            changesToNodeVisibility.set(nodeId, visibility);
        }


        setNodeVisibilityMap(changesToNodeVisibility);

        let needsNodeVisibilityUpdate = false;

        for (const [key, visibility] of state.faceVisibilityMap.entries()) {
            const face = parseFaceKey(key);
            const nodeVisibility = state.nodeVisibilityMap.get(face.nodeId);
            const record = changesToFaceVisibility.get(face.nodeId) || {
                visible: [],
                hidden: []
            };

            changesToFaceVisibility.set(face.nodeId, record);

            if (nodeVisibility === visibility) {
                continue;
            }

            if (visibility === false) {
                record.hidden.push(face.faceIndex);
            } else {
                record.visible.push(face.faceIndex);
            }

            changesToNodeVisibility.set(face.nodeId, true);
            needsNodeVisibilityUpdate = true;
        }

        needsNodeVisibilityUpdate && setNodeVisibilityMap(changesToNodeVisibility);

        setFaceVisibilityMap(changesToFaceVisibility);
    }, [state]);

    useEffect(() => {
        async function exec(changesToNodeVisibility: Map<number, boolean>, hwv: Communicator.WebViewer) {
            hwv.unsetCallbacks({
                visibilityChanged: updateSceneFromHoops
            });

            await hwv.model.setNodesVisibilities(changesToNodeVisibility);

            for (const [nodeId, _] of changesToNodeVisibility) {
                try {
                    hwv.model.clearNodeFaceVisibility(nodeId);
                    hwv.model.clearNodeLineVisibility(nodeId);
                } catch { }
            }

            hwv.setCallbacks({
                visibilityChanged: updateSceneFromHoops
            });

        }
        if (hwv && changesToNodeVisibility.size) {
            exec(changesToNodeVisibility, hwv);
        }
    }, [hwv, changesToNodeVisibility, updateSceneFromHoops]);

    useEffect(() => {
        async function exec(map: Map<number, FaceVisibilityRecord>, hwv: Communicator.WebViewer) {
            for (const [nodeId, record] of map.entries()) {
                hwv.model.clearNodeFaceVisibility(nodeId);
                hwv.model.clearNodeLineVisibility(nodeId);

                if (record.hidden.length || record.visible.length) {
                    record.hidden.forEach(h => hwv.model.setNodeFaceVisibility(nodeId, h, false));

                    if (record.visible.length) {
                        const faceCount = await hwv.model.getFaceCount(nodeId);

                        for (let faceIndex = 0; faceIndex < faceCount; faceIndex++) {
                            hwv.model.setNodeFaceVisibility(nodeId, faceIndex, record.visible.includes(faceIndex));
                        }
                    }

                    const lineCount = await hwv.model.getEdgeCount(nodeId);

                    for (let lineIndex = 0; lineIndex < lineCount; lineIndex++) {
                        hwv.model.setNodeLineVisibility(nodeId, lineIndex, false);
                    }
                }
            }
        }

        if (hwv) {
            exec(changesToFaceVisibility, hwv);
        }
    }, [hwv, changesToFaceVisibility]);

    useEffect(() => {
        if (treeNodeItemNodeIdMap.size) {
            dispatch({
                type: VisibilityStateActionType.UPDATE_SCENE,
                changesToNodeVisibility: new Map<number, boolean>([...treeNodeItemNodeIdMap.keys()].map(nodeId => [nodeId, true]))
            });
        }
    }, [treeNodeItemNodeIdMap]);

    useEffect(() => {
        hwv?.setCallbacks({
            visibilityChanged: updateSceneFromHoops
        });

        return () => {
            hwv?.unsetCallbacks({
                visibilityChanged: updateSceneFromHoops
            });
        }
    }, [hwv, updateSceneFromHoops]);

    useEffect(() => {
        if (treeNodeItemNodeIdMap.size && changesToNodeVisibility.size > 0) {
            const subTree: Tree = {};

            for (const [nodeId, visibility] of changesToNodeVisibility) {
                const treeNode = treeNodeItemNodeIdMap.get(nodeId);

                if (treeNode) {
                    subTree[treeNode.id] = {
                        ...treeNode,
                        isVisible: visibility
                    }
                }
            };

            const newTree = {
                ...jobContext.Tree,
                ...subTree
            };

            jobContext.setTree(newTree);
        }
    }, [changesToNodeVisibility, treeNodeItemNodeIdMap]);


    return {
        state,
        getVisibility,
        updateVisibility: dispatch
    }
}