import { ChannelBodyType, Face, SyntheticMeshInfo } from "../../../store/job/job-data";
import { createMesh, drawFace, getTerminalVertices } from "../../../utils/hoops.utils";
import { SelectOperator } from "./SelectOperator";

type GetChannelTypeFn = (face: Face) => ChannelBodyType | null;

type GetMeshFn = (face: Face) => SyntheticMeshInfo | null;

export type ChannelEndMesh = { nodeId: number, faceIndex: number, config: SyntheticMeshInfo };

export class SelectTerminalOperator extends SelectOperator {
    onStart?: () => void;

    onComplete?: (face: Face | ChannelEndMesh, shiftModifier: boolean) => void;

    getChannelType: GetChannelTypeFn = () => null;

    getMesh: GetMeshFn = () => null;

    async onMouseDown(event: Communicator.Event.MouseInputEvent) {
        this._selectedItem = await this._hwv.view.pickFromPoint(event.getPosition(), new Communicator.PickConfig(Communicator.SelectionMask.Face));
    }

    async onMouseMove(event: Communicator.Event.MouseInputEvent) {
        delete this._selectedItem;
    }

    async onMouseUp(event: Communicator.Event.MouseInputEvent): Promise<void> {
        if (this._selectedItem) {
            const nodeId = this._selectedItem.getNodeId();
            const face = this._selectedItem.getFaceEntity();
            const faceIndex = face?.getCadFaceIndex();

            if (nodeId && faceIndex !== undefined) {
                if (this._highlight) {
                    this._hwv.model.setNodeFaceHighlighted(nodeId, faceIndex, true);
                }

                this.onStart?.();

                const channelType = this.getChannelType({ nodeId, faceIndex });
                const shiftModifier = event.getModifiers() == Communicator.KeyModifiers.Shift;

                if (channelType === ChannelBodyType.FACE_GROUPS) {
                    let meshInfo = this.getMesh?.({ nodeId, faceIndex });

                    if (meshInfo) {
                        this.onComplete?.({ nodeId, faceIndex, config: meshInfo }, shiftModifier);
                    } else {
                        setTimeout(async () => {
                            const vertices = await getTerminalVertices([{ nodeId, faceIndex }], this._hwv);
                            const meshInfo = drawFace(vertices);
    
                            meshInfo && this.onComplete?.({ nodeId, faceIndex, config: meshInfo }, shiftModifier);
                        }, 500);
                    }
                } else if (channelType === ChannelBodyType.PARTS) {
                    this.onComplete?.({ nodeId, faceIndex }, shiftModifier);
                }
            }
        }

        event.setHandled(true);
    }
}