import { Box, Typography } from "@mui/material";
import { useContext } from "react";
import { useQuery } from "react-query";
import JobService from "../../services/JobService";
import JobContext from "../../store/job/job-context";
import Iteration, { ChannelInfo } from "../../store/project/iteration";
import InfoDialog from "../common/InfoDialog";
import Loading from "../common/Loading";
import theme from "../../styles/main-theme";
import { Channel } from "../../store/job/channel";
import { useTranslation } from 'react-i18next';

interface SolveInfoProps {
    open: boolean,
    onClose: () => void
    channelInfo: ChannelInfo[]
    iteration: Iteration
}

const SolveInfoContent = (props: {
    iterationId: string,
    channelInfo: ChannelInfo,
    channels: Channel[]
}) => {
    const key = `${props.iterationId}${props.channelInfo.id}`;
    const { t } = useTranslation();
    const channel = props.channels.find(c => c.id === props.channelInfo.id);
    const getInletName = (channel: Channel, inletId: string) => {
        return channel.inlets.find(i => i.id === inletId)?.name;
    };
    const getOutletName = (channel: Channel, outletId: string) => {
        return channel.outlets.find(o => o.id === outletId)?.name;
    }
    const formatFlowRate = (flowRate: number) => `${(flowRate * 60000).toFixed(2)}lpm / ${(flowRate * 15850.3).toFixed(2)}gpm`;
    const formatTemperature = (temp: number) => `${temp.toFixed(2)}C / ${(temp * 1.8 + 32).toFixed(2)}F`;

    // *TODO: commented temperature for next release*
    const TODO = false;
    return (<>
        {channel && <Box sx={{ color: theme.palette.text.secondary }}>
            <Box id="channel-summary-content" key={key}>
                <Typography>{channel.name}</Typography>
                <Box sx={{ marginLeft: '10px', marginBottom: '10px', '& p': { margin: 0 } }}>
                    <Typography>{t("Flow rate")}: {props.channelInfo.inlets[0].flowRate && formatFlowRate(props.channelInfo.inlets[0].flowRate)} </Typography>
                    {TODO && props.channelInfo.inlets.map(i => <Typography key={key + i.id}>{getInletName(channel, i.id)}: {t("Temperature")} {formatTemperature(i.temperature)}</Typography>)}
                    {TODO && props.channelInfo.outlets.map(o => <Typography key={key + o.id}>{getOutletName(channel, o.id)}: {t("Temperature")} {formatTemperature(o.temperature)}</Typography>)}
                </Box>
            </Box>
        </Box>}
    </>);
}

const SolveInfoDialog = (props: SolveInfoProps) => {
    const jobContext = useContext(JobContext);
    const { t } = useTranslation();
    const jobDetails = useQuery(['iterationSetup', props.iteration.id],
        async () => {
            const jobDetails = await JobService.getJobDetails(props.iteration.projectId, props.iteration.id, props.iteration.containerName);
            return jobDetails?.Categories;
        },
        {
            initialData: () => {
                if (jobContext.Project) {
                    return jobContext.Categories;
                }
            },
            refetchOnWindowFocus: false,
            staleTime: Infinity,
            enabled: props.open
        });

    return (<InfoDialog open={props.open} title={t("Channel Summary")} onClose={props.onClose}>
        <>
            <Loading />
            {jobDetails.isSuccess && jobDetails.data && props.channelInfo.map(ci => <SolveInfoContent
                key={props.iteration.id + ci.id}
                iterationId={props.iteration.id}
                channelInfo={ci} channels={jobDetails.data!.Channel}
            />)}
        </>
    </InfoDialog>)

}

export default SolveInfoDialog;