import { Socket } from "socket.io-client";

export const disconnectSocketAndClearModel = (socket: Socket, view: cee.View) => {
    socket?.disconnect();
    view.removeAllModels();
}

export const computeInitialCuttingPlanePosition = (model: cee.ug.RemoteModel, normal: cee.Vec3Like) => {
    const min = model.getBoundingBox().minimum;
    if (Math.abs(normal.x) === 1) {
        return min.x;
    } else if (Math.abs(normal.y) === 1) {
        return min.y;
    } else {
        return min.z;
    }
}