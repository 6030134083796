import Layout from '../ui/Layout';
import JobCreationPage from './JobCreationPage';

const JobComponent = () => {

    return (
        <Layout>
            <JobCreationPage />
        </Layout>
    )
};

export default JobComponent;