import { Box, Button, Chip, Divider, Paper, Typography } from "@mui/material";
import { PropsWithChildren } from "react";
import theme from "../../styles/main-theme";
import { PriceItem, ProductModel } from "./models/productModel";
import { useTranslation } from 'react-i18next';

type BuyProductComponentProps = {
    product: ProductModel,
    buyButtonTitle: string,
    onButtonClick: () => void
};

const getRecurringLabel = (priceData: PriceItem) => {
    const currency = priceData.currency;    
    return `${currency}$`.toUpperCase() + " " + priceData.unit_amount_decimal/100;
}

const getStandardLabel = (priceData: PriceItem) => {
    const currency = priceData.currency;

    return `${currency}$`.toUpperCase() + "" + priceData.unit_amount_decimal/100;
}

const BuyProductComponent = (props: PropsWithChildren<BuyProductComponentProps>) => {
    const { t } = useTranslation();    
    return <>    
        <Paper sx={{flex: "1", p: "15px", m: "8px", minWidth: "250px", maxWidth: "300px", display: "flex", flexDirection: "column"}} elevation={1}>
            <Box id="title" sx={{mb: "10px"}}>
                {!props.product.livemode && <Chip sx={{mb: "10px", color: theme.palette.info.light}} label={t("Test mode")} size="small"/>}
                <Typography sx={{color: theme.palette.primary.main, fontWeight: "bold"}}>{props.product.name}</Typography>               
            </Box>

            <Box id="content" sx={{flex: "1"}}>
                <Typography paragraph={true} fontSize="small">{props.product.description}</Typography>
            </Box>
            <Divider sx={{mb: "10px"}}></Divider>
            <Box>
                <Box sx={{mb: "10px", textAlign: "center"}}>
                    { 
                        <Typography>
                            {props.product.default_price.type == "recurring" ? getRecurringLabel(props.product.default_price) + " " + t("/ month") : getStandardLabel(props.product.default_price)}
                            &nbsp;({props.product.default_price.metadata?.Credits ? props.product.default_price.metadata?.Credits : "X"} {t("Credits")})
                        </Typography>
                    }
                </Box>
                <Button className="buyProductButton" data-product-cost={props.product.default_price.metadata?.Credits} onClick={() => {props.onButtonClick()}} variant="outlined" sx={{width:"100%"}}>
                    {props.buyButtonTitle}    
                </Button>  
            </Box>
        </Paper>
    </>
}

export default BuyProductComponent;