import React, { PropsWithChildren, useEffect, useMemo, useReducer } from 'react';
import { UiSettingsService } from '../../services/UiSettingsService';
import { DEFAULT_TYPE } from '../../store/project/project-data';
import { useTranslation } from 'react-i18next';
export enum TemperatureUnit {
    C = '°C',
    F = '°F'
};

export enum Language {
    en = 'en',
    fr = 'fr'
};

export interface UiSettingsData {
    temperatureUnit: TemperatureUnit,
    projectType: string,
    language?: Language,
    displayTipsAndTricks: boolean
}

export enum UiSettingsActionType {
    SAVE_SETTINGS = 'SAVE SETTINGS',
    LOAD_SETTINGS = 'LOAD SETTINGS'
}

export type UiSettings = {
    temperatureUnit: TemperatureUnit;
    projectType: string;
    language?: Language;
    displayTipsAndTricks: boolean;
}

export type UiSettingsAction = {
    type: UiSettingsActionType,
    data: UiSettings
}

export const initialState = () => {
    return {
        temperatureUnit: TemperatureUnit.C,
        projectType: DEFAULT_TYPE,
        displayTipsAndTricks: false,
        initialLoadComplete: false
    };
}

export const UiSettingsContext = React.createContext<{ uiSettings: UiSettings, dispatch: React.Dispatch<UiSettingsAction>, setAndSaveConfig: (config: UiSettingsData) => void }>({
    uiSettings: initialState(),
    dispatch: () => { },
    setAndSaveConfig: () => { }
});

const reducerFn = (state: UiSettings, action: UiSettingsAction) => {

    if (action.type === UiSettingsActionType.LOAD_SETTINGS || action.type === UiSettingsActionType.SAVE_SETTINGS) {
        const returnState = {
            ...state,
            ...action.data,
            initialLoadComplete: true
        }
        return returnState;
    }

    return { ...state, initialLoadComplete: true };
}

export const UiSettingsContextProvider = (props: PropsWithChildren) => {
    const [uiSettings, dispatch] = useReducer(reducerFn, initialState());
    const { i18n } = useTranslation();

    const setAndSaveConfig = async (config: UiSettingsData) => {
        await UiSettingsService.Set(config);
        dispatch({ type: UiSettingsActionType.SAVE_SETTINGS, data: config });
    }

    const value = useMemo(() => {
        if (uiSettings.language && i18n.language != uiSettings.language) {
            i18n.changeLanguage(uiSettings.language);
        }
        return { uiSettings, dispatch, setAndSaveConfig };
    }, [uiSettings, dispatch, setAndSaveConfig]);

    const load = async () => {
        dispatch({ type: UiSettingsActionType.LOAD_SETTINGS, data: await UiSettingsService.Get() });
    }

    useEffect(() => {
        load();
    }, [])

    return (
        <UiSettingsContext.Provider value={value}>
            {uiSettings.initialLoadComplete && props.children}
        </UiSettingsContext.Provider>
    );
};

function useState(arg0: boolean): [any, any] {
    throw new Error('Function not implemented.');
}
