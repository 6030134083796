import * as cee from "@ceetron/common/CeeEnvisionWebComponents";
import { Box, createTheme, Slider, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useState, useEffect, useContext } from "react";
import { ThemeProvider } from '@emotion/react';
import { getScalarResultByName } from "../../services/CeetronService";
import { CeetronContext, DisplayPreset, VtfxScalarResults } from "../../store/job/ceetron-context";
import maintheme from "../../styles/main-theme";
import { convertTemperature, getUnit } from "../../utils/units.utils";
import { TemperatureUnit, UiSettingsContext } from "../../store/uiSettings/UiSettingsContext";

interface IsovolumeProps {
    model: cee.ug.RemoteModel,
    isoVolume: cee.ug.Isovolume,
    comparisonIsoVolume?: cee.ug.Isovolume,
    ejectionTemperature: number | null // assumed in Celsius
}

const useStyles = makeStyles({
    headers: {
        marginTop: "15px"
    },
    wrapper: {
        padding: "30px 20px 0 20px"
    },
    container: {
        padding: "0px 20px 5px 0px",
        borderBottom: "1px solid"
    }
});

const theme = createTheme({
    palette: {
        text: {
            primary: maintheme.palette.background.paper
        }
    },
    components: {
        MuiSwitch: {
            styleOverrides: {
                switchBase: {
                    color: maintheme.palette.primary.contrastText,
                },
                root: {
                    '& .Mui-disabled .MuiSwitch-thumb': {
                        color: maintheme.palette.primary.contrastText,
                        opacity: 0.12
                    }
                }
            }
        },
        MuiStack: {
            defaultProps: {
                sx: {
                    padding: "20px"
                }
            }
        },
        MuiSlider: {
            styleOverrides: {
                valueLabel: {
                    backgroundColor: maintheme.palette.primary.main
                },
                thumb: {
                    color: maintheme.palette.primary.main,
                    "&:hover, &.Mui-focusVisible": {
                        boxShadow: `0 0 0 8px ${maintheme.palette.primary.light}`
                    }
                },
                rail: {
                    color: maintheme.palette.primary.light
                },
                track: {
                    color: maintheme.palette.primary.light
                }
            }
        }
    }
})

export const IsoVolume = (props: IsovolumeProps) => {
    const [minValue, setMinValue] = useState<number>(0);
    const [maxValue, setMaxValue] = useState<number>(0);
    const [currentValue, setCurrentValue] = useState<number>(0);
    const [isovolumeLabel, setIsovolumeLabel] = useState<string>('');
    const { model, isoVolume: isoVolume, comparisonIsoVolume: comparisonIsoVolume, ejectionTemperature } = props;
    const classes = useStyles();
    const context = useContext(CeetronContext);
    const uiSettingsContext = useContext(UiSettingsContext);

    function handleSliderPositionChange(value: number) {
        setCurrentValue(value);
    }

    function handleSliderPositionChangeCommitted(value: number) {
        if (isoVolume) {
            let valueToUse = value;
            const scalarResultInfo = model.modelDirectory.scalarResultArray.find(sra => sra.id === isoVolume.mapScalarResultId);
            if (scalarResultInfo?.name === VtfxScalarResults.TEMPERATURE_RESULT) {
                valueToUse = convertTemperature({
                    value, unit: uiSettingsContext.uiSettings.temperatureUnit
                }, TemperatureUnit.C).value;
            }
            isoVolume.minimumIsoValue = valueToUse;
            if (comparisonIsoVolume) {
                comparisonIsoVolume.minimumIsoValue = valueToUse;
            }
        }
    }

    function setSliderRange(min: number, max: number) {
        let maxValue = max;
        let minValue = min;
        let value = parseFloat((min + (max - min) * 0.7).toFixed(2));
        let newValue = value;
        const scalarResultInfo = model.modelDirectory.scalarResultArray.find(sra => sra.id === isoVolume.mapScalarResultId);
        if (scalarResultInfo?.name === VtfxScalarResults.TEMPERATURE_RESULT) {
            maxValue = convertTemperature({
                value: max, unit: getUnit(scalarResultInfo) as TemperatureUnit
            }, uiSettingsContext.uiSettings.temperatureUnit).value;
            minValue = convertTemperature({
                value: min, unit: getUnit(scalarResultInfo) as TemperatureUnit
            }, uiSettingsContext.uiSettings.temperatureUnit).value;
            if (typeof ejectionTemperature === 'number') {
                const tempValue = convertTemperature({
                    value: ejectionTemperature, unit: TemperatureUnit.C
                }, uiSettingsContext.uiSettings.temperatureUnit).value;
                value = (tempValue <= maxValue && tempValue >= minValue) ? tempValue : value; 
            }
            newValue = convertTemperature({
                value: value, unit: getUnit(scalarResultInfo) as TemperatureUnit
            }, uiSettingsContext.uiSettings.temperatureUnit).value;
        }
        // Revisit min/max range
        if (context.ceetronState.displayPreset === DisplayPreset.FREEZE_TIME) {
            const stateInfoArray = model.modelDirectory.stateInfoArray;
            minValue = minValue ? minValue : 0;
            maxValue = maxValue ? maxValue : stateInfoArray[stateInfoArray.length - 1].referenceValue;
        }

        if (isoVolume.minimumIsoValue > max || isoVolume.minimumIsoValue < min || isoVolume.minimumIsoValue === -1) {
            isoVolume.minimumIsoValue = value;
            setCurrentValue(newValue);
        }
        if (comparisonIsoVolume) {
            comparisonIsoVolume.minimumIsoValue = isoVolume.minimumIsoValue;
        }
        setMinValue(parseFloat((minValue).toFixed(2)));
        setMaxValue(parseFloat((maxValue).toFixed(2)));
    }

    function matchComparisonIsoVolume(isoVolume: cee.ug.Isovolume, comparisonIsoVolume: cee.ug.Isovolume) {
        comparisonIsoVolume.minimumIsoValue = isoVolume.minimumIsoValue;
        comparisonIsoVolume.maximumIsoValue = isoVolume.maximumIsoValue;
        comparisonIsoVolume.computeFromVisibleParts = isoVolume.computeFromVisibleParts;
        comparisonIsoVolume.mapScalarResultId = isoVolume.mapScalarResultId;
        comparisonIsoVolume.isoScalarResultId = isoVolume.isoScalarResultId;
    }

    useEffect(() => {
        const scalarResult = getScalarResultByName(model, context.ceetronState.scalarResults);

        if (!scalarResult || (context.ceetronState.scalarResultsRange.min === 0 && context.ceetronState.scalarResultsRange.max === 0)) {
            return;
        }
        const settings = model.getScalarSettingsById(scalarResult.id);
        setSliderRange(settings.rangeMinimum, settings.rangeMaximum);
    }, [context.ceetronState.scalarResultsRange]);

    useEffect(() => {
        const scalarResult = getScalarResultByName(model, context.ceetronState.scalarResults);
        if (!scalarResult  || !isoVolume) {
            return;
        }
        const settings = model.getScalarSettingsById(scalarResult.id);
        const maxValueToUse = model.getScalarResultMaximumValue(scalarResult.id) ? model.getScalarResultMaximumValue(scalarResult.id) : settings.rangeMaximum;
        const minValueToUse = model.getScalarResultMinimumValue(scalarResult.id) ? model.getScalarResultMinimumValue(scalarResult.id) : settings.rangeMinimum;
        isoVolume.minimumIsoValue = -1;
        isoVolume.maximumIsoValue = maxValueToUse;
        isoVolume.computeFromVisibleParts = true;
        isoVolume.mapScalarResultId = scalarResult.id;
        isoVolume.isoScalarResultId = scalarResult.id;
        if (comparisonIsoVolume) {
            matchComparisonIsoVolume(isoVolume, comparisonIsoVolume);
        }
        setSliderRange(minValueToUse, maxValueToUse);
        setIsovolumeLabel(scalarResult.name);
    }, [isoVolume, comparisonIsoVolume]);

    return (
        <div className={classes.container}>
            <Typography variant="h6" className={classes.headers}>{isovolumeLabel}</Typography>
            <div className={classes.wrapper}>
                <Stack>
                    <ThemeProvider theme={theme}>
                        <Box>
                            <Slider
                                id="isovolume-slider"
                                value={currentValue}
                                valueLabelDisplay="on"
                                aria-labelledby="radius-slider"
                                onChange={(_event: any, value: any) => handleSliderPositionChange(value)}
                                onChangeCommitted={(_event: any, value: any) => handleSliderPositionChangeCommitted(value)}
                                color="secondary"
                                step={0.01}
                                min={minValue}
                                max={maxValue}
                                disabled={!context.ceetronState.isViewerReady}
                            />
                        </Box>
                    </ThemeProvider>
                </Stack>
            </div>
        </div>
    );
}