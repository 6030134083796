import { Box, MenuItem, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, CircularProgress, Checkbox, useTheme, ListItemText } from "@mui/material"
import ClearIcon from '@mui/icons-material/Clear';
import { ChangeEvent, useContext, useEffect, useMemo, useState } from "react";
import debounce from "lodash.debounce";
import { t } from "i18next";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import AuthContext from "../../auth/AuthenticationContext";
import { UserRole } from "../users/UserList";
import CreditsAccountContext from "../../store/creditsAccount/CreditsAccountContext";

export interface SelectedUserFilterModel{
    id: string,
    name: string,
    email: string,
    checked: boolean,
    isDeleted: boolean
}

export interface ProjectFilters{
    inputSearch: string | null,
    filterType: ProjectFilterType,
    users: SelectedUserFilterModel[]
}

export interface InputFilterProps{
    filters: ProjectFilters,
    onSearchChange: (updatedText: string | null, filterType: ProjectFilterType, selectedUsers: SelectedUserFilterModel[]) => void,
    debounceTime?: number | null,
    isLoading: boolean
}

export enum ProjectFilterType{
    AllAttributes = "AllAttributes",
    Name = "Name",
    Description = "Description",
    Tag = "Tag",
    Author = "Author",
}

export const ProjectFilter = (props: InputFilterProps) => {
    const authContext = useContext(AuthContext);
    const accountContext = useContext(CreditsAccountContext);
    
    const [text, setText] = useState<string | null>(null);
    const [filterType, setFilterType] = useState<ProjectFilterType>(ProjectFilterType.Name);
    const theme = useTheme();
    const users: SelectedUserFilterModel[] = props.filters.users;

    useEffect(() => {
        setText(props.filters.inputSearch);
        setFilterType(props.filters.filterType);
    }, [props.filters])

    const onDebounced = (text: string | null, filterType: ProjectFilterType) => {
        props.onSearchChange(text, filterType, props.filters.users);
    }

    const debouncedChangeHandler = useMemo(
        () => {
            return debounce(onDebounced, props.debounceTime ?? 300);
        }
    , [props.filters]);

    useEffect(() => {
        if(text != undefined){
            debouncedChangeHandler(text, filterType);
        }
    }, [text])

    const onClearField = () => {
        if(!props.isLoading){
            setText(null);
            props.onSearchChange(null, filterType, props.filters.users);
        }
    }

    const setUserInput = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setText(event.target.value);
    }

    const onChangeFilterType = (event: SelectChangeEvent<ProjectFilterType>) => {
        const filterType = ProjectFilterType[event.target.value as keyof typeof ProjectFilterType];
        props.onSearchChange(text, filterType, props.filters.users);
    }

    const handleUserSelectionChange = (event: SelectChangeEvent) => {
        const {
          target: { value },
        } = event;

        // On autofill we get a stringified value.
        const listChecked = typeof value === 'string' ? value.split(',') : value as SelectedUserFilterModel[]
        const idsList: string[] = listChecked.map((i) => {return (i as SelectedUserFilterModel).id})

        const isSelectAll = value.includes("selectAll");
        const isUnselectAll = value.includes("unselectAll");

        const updatedCheckedList: SelectedUserFilterModel[] = [];
        for(let user of props.filters.users){
            updatedCheckedList.push({
                ...user,
                checked: isSelectAll ? true : isUnselectAll ? false : idsList.includes(user.id)
            });
        }

        props.onSearchChange(text, filterType, updatedCheckedList as any as SelectedUserFilterModel[]);
    };

    const isAllSelected = () => {
        return props.filters.users.filter((u) => {return u.checked}).length == props.filters.users.length
    }

    const activeUsers = users.filter((u) => {return !u.isDeleted});
    const deletedUsers = users.filter((u) => {return u.isDeleted});

    return (
        <>
            <Box sx={{display: "flex", alignItems: "center"}}>
                <FormControl id="project-search-filter-form-input" sx={{ m: 1, width: '320px'}} variant="outlined" size="small">
                    <InputLabel htmlFor="outlined-adornment-project-filter">{t("Search Project")}</InputLabel>
                    <OutlinedInput
                        value={text ?? ""}
                        sx={{background: "#f9f9f9"}}
                        label={t("Search Project")}
                        id="project-search-filter"
                        type={'text'}
                        onChange={setUserInput}
                        endAdornment={
                            <InputAdornment position="end">
                            <IconButton
                                onClick={onClearField}
                                aria-label="Clear Project Filter"                           
                                onMouseDown={() => {}}
                                edge="end"
                            >
                                {props.isLoading ? <CircularProgress sx={{width: "20px !important", height: "20px !important"}}/> : <ClearIcon/>}
                            </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <FormControl size="small" sx={{ m: 1, width: '200px'}}>
                    <InputLabel id="select-type-of-filter">{t("Filter")}</InputLabel>
                    <Select
                        label={t("Filter")}
                        sx={{background: "#f9f9f9"}}
                        size="small"
                        labelId="select-label"
                        id="project-type-filter"
                        value={filterType}
                        onChange={onChangeFilterType}
                    >
                        <MenuItem value={ProjectFilterType.AllAttributes}>{t(ProjectFilterType.AllAttributes)}</MenuItem>
                        <MenuItem value={ProjectFilterType.Name}>{t(ProjectFilterType.Name)}</MenuItem>
                        <MenuItem value={ProjectFilterType.Description}>{t(ProjectFilterType.Description)}</MenuItem>
                        <MenuItem value={ProjectFilterType.Tag}>{t(ProjectFilterType.Tag + "-2")}</MenuItem>
                    </Select>
                </FormControl>

                {authContext.role == UserRole.Admin && !accountContext.isFree &&
                    <FormControl size="small" sx={{ m: 1, width: '200px'}}>
                        <InputLabel id="multi-users-input-label">{t("users")}</InputLabel>
                        <Select
                            data-testid="multi-user-select-filter"
                            MenuProps={
                                {
                                    autoFocus: false,
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    PaperProps: {
                                        style: {
                                            scrollbarWidth: "thin",
                                            maxHeight: 500,
                                        }
                                    },
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "right"
                                    }
                                }
                            }
                            size="small"
                            label={t("users")}
                            sx={{background: "#f9f9f9", maxHeight: "200px"}}
                            labelId="multi-users-select"
                            id="users-selection-filter"
                            multiple
                            value={users.filter((u) => {return u.checked}) as any}
                            onChange={handleUserSelectionChange}
                            renderValue={(selected) => (selected as any).map((s: any) => {return s.name}).join(', ')}
                        >
                            {!isAllSelected() && <MenuItem key="select-all" value="selectAll" sx={{paddingLeft: "15px", paddingRight: "10px"}}>
                                <ListItemText primary={t("select all")} />
                            </MenuItem>}
                            {isAllSelected() && <MenuItem key="unselect-all" value="unselectAll" sx={{paddingLeft: "15px", paddingRight: "10px"}}>
                                <ListItemText primary={t("unselect all")} />
                            </MenuItem>}
                            {activeUsers.map((user) => (
                                <MenuItem key={user.id} value={user as any} sx={{paddingLeft: "10px", paddingRight: "10px"}}>
                                    <Checkbox sx={{padding: "2px", marginRight: "5px"}} checked={user.checked} />
                                    <ListItemText primary={user.name} />
                                </MenuItem>
                            ))}
                            {deletedUsers.length > 0 &&
                                <MenuItem disabled value="">
                                <em>{t("Deleted Users")}</em>
                            </MenuItem>}
                            {deletedUsers.map((user) => (
                                <MenuItem key={user.id} value={user as any} sx={{paddingLeft: "10px", paddingRight: "10px"}}>
                                    <Checkbox sx={{padding: "2px", marginRight: "5px"}} checked={user.checked} />
                                    <ListItemText primary={user.name} sx={{color: theme.palette.secondary.contrastText}}/>
                                </MenuItem>
                            ))}
                        </Select>                                  
                    </FormControl>
                }          
            </Box>
        </>
    )
}