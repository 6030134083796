import { MouseEvent, useContext, useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { CardContent, IconButton, Stack, Typography, CircularProgress, LinearProgress, styled, linearProgressClasses } from '@mui/material';
import Box from '@mui/material/Box';
import { CanJobBeCancelled, Status, StatusLabel } from '../../store/job/job-data';
import Iteration from '../../store/project/iteration';
import { Compare as CompareIcon, Edit as EditIcon, Error as ErrorIcon, Warning as WarningIcon, Info as InfoIcon } from "@mui/icons-material";
import Tooltip from '@mui/material/Tooltip';
import theme from '../../styles/main-theme';
import { ThemeProvider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ErrorDialog from '../common/ErrorDialog';
import JobService from '../../services/JobService';
import SolveInfoDialog from './SolveInfoDialog';
import IterationSummaryDialog from './IterationSummary';
import { CancelSubmittedJob } from '../job/CancelSubmittedJob';
import queryErrorHandler from '../../react-query/queryErrorHandler';
import { ErrorCancellingJob } from '../../utils/ErrorMessages';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'; 
import ProjectService from '../../services/ProjectsService';
import { useTranslation } from 'react-i18next';
import DisplayFreezeTime from '../../utils/freezeTime.utils';
import { CeetronContext } from '../../store/job/ceetron-context';

export type IterationListItemProps = {
    iteration: Iteration;
    onClick?: (version: Iteration) => void;
    onDetailsClick?: (iteration: Iteration) => void;
    onCompareClick?: (iteration: Iteration) => void;
    compact: boolean;
    active?: boolean;
    onJobCancel: (projectId: string, jobId: string) => void;
}

const IterationListItem = (props: IterationListItemProps) => {
    const { t } = useTranslation();
    const [showErrorInfoDlg, setShowErrorInfoDlg] = useState(false);
    const [showSolveInfoDlg, setShowSolveInfoDlg] = useState(false);
    const [showSummaryInfoDlg, setShowSummaryInfoDlg] = useState(false);
    const [currentSolveProgress, setcurrentSolveProgress] = useState<number | null>(props.iteration.solveCurrentProgress);
    const [startPollingProgress, setStartPollingProgress] = useState<boolean>(false);
    const [hasWarning, setHasWarning] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);
    const [isCancelling, setIsCancelling] = useState<boolean>(false);
    const [isOpenComparison, setIsOpenComparison] = useState<boolean>(false);
    const [iterationItem, setIterationItem] = useState<Iteration>();
    const projectService = new ProjectService();
    const ceetronContext = useContext(CeetronContext);

    const onIterationClick = (event: MouseEvent<HTMLElement>) => {
        if(isOpenComparison) return;

        event.stopPropagation();
        props.onClick && props.onClick(props.iteration);
    }
    const classes = makeStyles({
        label: {
            color: theme.palette.primary.dark,
            fontSize: '0.8em',
        },
        value: {
            color: theme.palette.info.light
        },
        stateIcon: {
            fontSize: '0.8em'
        },
        textBox: {
            display: '-webkit-box',
            maxWidth: '100%',
            WebkitBoxOrient: 'vertical',
            whitespace: 'nowrap',
            overflowWwrap: 'break-word!important',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
    })();

    useEffect(() => {
        if (props.compact == true) {
            const iterationSolveProgress = props.iteration.solveCurrentProgress;
            setcurrentSolveProgress(iterationSolveProgress);
        }
    }, [props.iteration])

    useEffect(() => {
        setHasWarning(props.iteration.errorInfo?.code == 9999);
        setHasError(props.iteration.errorInfo?.code != null && props.iteration.errorInfo?.code != 0 && props.iteration.errorInfo?.code != 9999);
    }, [props.iteration.errorInfo?.code])

    useEffect(() => {
        if (props.compact == true) {
            return;
        }

        if (StatusLabel[props.iteration.solveStatus] == "In progress" && props.iteration.solveCurrentProgress != 100) {
            window.console.log("Start polling solver progress for job " + props.iteration.name)
            setStartPollingProgress(true);
        } else if (startPollingProgress == true) {
            window.console.log("Stop polling solver progress for job " + props.iteration.name)
            setStartPollingProgress(false);
        }
    }, [props.iteration.solveStatus])

    useEffect(() => {
        if (props.onCompareClick) {
            setIsOpenComparison(!ceetronContext.ceetronState.isViewerReady);
        }
    }, [ceetronContext.ceetronState.isViewerReady])

    let textColorActive = theme.palette.primary.contrastText;

    const statusSection = (<Grid container direction='column' alignItems={'center'} justifyContent='center' sx={{ height: '100%'}}
        color={textColorActive || theme.palette.primary.dark}>
        <Grid item className={classes.value} alignItems={'center'} sx={{padding:0}}>
            {!isCancelling ?
                StatusLabel[props.iteration.solveStatus] != "In progress" && <Box>{t(StatusLabel[props.iteration.solveStatus])}</Box>
                :
                <Box>
                    <CircularProgress className={classes.value} size={15} sx={{ mr: "5px" }} /> {t("Cancelling")}
                </Box>
            }

        </Grid>
    </Grid>)

    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 3.5,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: theme.palette.error.main
        },
    }));

    const cancelJob = async () => {
        try {
            setIsCancelling(true);
            await JobService.cancelSubmittedJob(props.iteration.projectId, props.iteration.id, props.iteration.containerName);
            props.onJobCancel(props.iteration.projectId, props.iteration.id);
        } catch (error) {
            queryErrorHandler(new Error(ErrorCancellingJob));
        } finally {
            setIsCancelling(false);
        }
    }

    const loadIterationSummary = async () => {
        const iteration = await projectService.getIteration(props.iteration.projectId, props.iteration.id, props.iteration.containerName, true);
        if (iteration) {
            setIterationItem(iteration);
            setShowSummaryInfoDlg(true);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Card className="job-card-item" key={props.iteration.id} onClick={onIterationClick} sx={{
                '& .MuiCardContent-root:last-child': {
                    paddingBottom: '8px',
                },
                border: '1px solid transparent',
                ':hover': { border: `1px solid ${'primary.dark'}`, cursor: 'pointer' },
                ...(props.active
                    ? {
                        backgroundColor: 'primary.light',
                    }
                    : {})
            }}>
                <CardContent sx={{ position: 'relative' }} component='article'>
                    <Tooltip title={t("View Summary")}>
                        <IconButton id="summary-info-button" onClick={(e) => {
                            e.stopPropagation();
                            loadIterationSummary();                          
                        }} sx={{
                            position: 'absolute', top: '-2px', left: '-2px', transform: 'scale(90%)',
                            ...(props.active ? {
                                color: 'grey[200]',
                                ':hover': { color: 'grey[200]', backgroundColor: 'grey[200]' },
                            } : { color: 'grey[200]' })
                        }}>
                            <MoreHorizIcon />
                        </IconButton>
                    </Tooltip>
                    <Box>
                        <Tooltip
                            title={props.iteration.name}>
                            <Typography fontWeight={'bold'} className={classes.textBox}
                                maxHeight={35} sx={{
                                    padding: '12px 0px 15px 0px',
                                    fontSize: '1.2em',
                                    margin: '0 0 8px 0',
                                    WebkitLineClamp: '1'
                                }}
                                color={textColorActive || 'primary.dark'}>
                                {props.iteration.name}
                            </Typography>
                        </Tooltip>
                    </Box>
                    <Box>
                        <Tooltip
                            title={props.iteration.description}>
                            <Typography maxHeight={20} className={classes.textBox} sx={{
                                padding: '0px 5px 15px 0px',
                                fontWeight: '400',
                                fontSize: '0.875rem',
                                lineHeight: '1.43',
                                margin: '0 0 15px 0',
                                WebkitLineClamp: '1'
                            }}
                                color={textColorActive || 'primary.dark'}>
                                {props.iteration.description}
                            </Typography>
                        </Tooltip>
                    </Box>
                    <Tooltip title={t("Edit Job")}>
                        <IconButton
                            sx={{
                                position: 'absolute', top: '-2px', right: '-2px', transform: 'scale(90%)',
                                ...(props.active ? {
                                    color: 'secondary.contrastText',
                                    ':hover': { color: 'secondary.contrastText', backgroundColor: 'grey[200]' },
                                } : { color: 'secondary.contrastText' })
                            }}
                            onClick={(e) => {
                                if (props.onDetailsClick) {
                                    e.stopPropagation();
                                    props.onDetailsClick(props.iteration)
                                }
                            }}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    {props.iteration.solveStatus === Status.Completed && props.onCompareClick && !props.active &&
                        <Tooltip title={t("Compare Jobs")}><span><IconButton
                            disabled={isOpenComparison}
                            sx={{
                                position: 'absolute', top: '-2px', right: '33px', transform: 'scale(90%)',
                                ...(props.active ? {
                                    color: 'secondary.contrastText',
                                    ':hover': { color: 'secondary.contrastText', backgroundColor: 'grey[200]' },
                                } : { color: 'secondary.contrastText' })
                            }}
                            onClick={(e) => {
                                if (props.onCompareClick && !isOpenComparison) {
                                    setIsOpenComparison(true);
                                    e.stopPropagation();
                                    props.onCompareClick(props.iteration)
                                }
                            }}>
                            <CompareIcon />
                        </IconButton></span></Tooltip>}
                    {CanJobBeCancelled(props.iteration.solveStatus) &&
                        <Box sx={{ position: 'absolute', top: '-2px', right: '33px', transform: 'scale(90%)' }}>
                            <CancelSubmittedJob listItem={true}
                                onCancel={() => { cancelJob() }}
                            ></CancelSubmittedJob>
                        </Box>}
                    <Grid container sx={{ flexWrap: "nowrap", alignItems: "center" }}>
                        <Grid item xs={3} sx={{alignItems: "center"}}>
                            <Grid container sx={{ flexWrap: "nowrap" }}>
                                <Grid item xs="auto">
                                    {(hasError || hasWarning) &&
                                        <Tooltip title={hasWarning ? t("Solver Warning") : t("Solver Error")}>
                                            <IconButton id="solve-warning-error-button" aria-label={t("Error Info Dialog")} onClick={(e) => {
                                                e.stopPropagation();
                                                setShowErrorInfoDlg(true);
                                            }} sx={{
                                                color: hasWarning ?
                                                    theme.palette.warning.main :
                                                    theme.palette.error.main,
                                                padding: '0px'
                                            }}>
                                                {hasWarning ?
                                                    <WarningIcon id="solve-warning-icon" className={classes.stateIcon} /> :
                                                    <ErrorIcon id="solve-error-icon" className={classes.stateIcon} />}
                                            </IconButton>
                                        </Tooltip>
                                    }
                                </Grid>
                                <Grid item xs="auto">
                                    {props.iteration.solveStatus === Status.Completed
                                        && props.iteration.solveInfo?.channelInfo
                                        && props.iteration.solveInfo.channelInfo?.length > 0
                                        && <Tooltip title={t("Solver info")}>
                                            <IconButton id="solve-info-button" aria-label={t("Solver Info Dialog")} onClick={(e) => {
                                                e.stopPropagation();
                                                setShowSolveInfoDlg(true);
                                            }} sx={{
                                                color: theme.palette.info.main,
                                                padding: '0px',
                                                paddingLeft: '5px'
                                            }}>
                                                <InfoIcon id="solve-info-icon" className={classes.stateIcon} />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={5} sx={{alignItems: "center", justifyContent: "center"}}>
                            <Stack
                                sx={{display: "flex", alignItems: "center", justifyContent: "center"}}
                                direction="row"
                                spacing={1}
                                color={textColorActive || 'primary.dark'}>
                               {props.iteration.solveInfo && <DisplayFreezeTime solveInfo={props.iteration.solveInfo}/>}
                            </Stack>
                        </Grid>
                        <Grid item xs={4} sx={{alignItems: "center"}}>
                            {statusSection}
                        </Grid>
                    </Grid>
                    <Grid container sx={{ flexWrap: "nowrap" }}>
                        <Grid item xs={12} justifyContent="flex-end">
                            {currentSolveProgress &&
                                StatusLabel[props.iteration.solveStatus] == "In progress" &&
                                <Stack direction="row" justifyContent="end" className={classes.value}>{t(StatusLabel[props.iteration.solveStatus])} {parseFloat((Math.round(currentSolveProgress * 100) / 100).toFixed(2))}%</Stack>}
                            {currentSolveProgress &&
                                StatusLabel[props.iteration.solveStatus] == "In progress" &&
                                <BorderLinearProgress variant="determinate" value={parseFloat((Math.round(currentSolveProgress * 100) / 100).toFixed(2))} />}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <IterationSummaryDialog
                open={showSummaryInfoDlg}
                iteration={iterationItem}
                onClose={() => setShowSummaryInfoDlg(false)}
            />
            <ErrorDialog
                open={showErrorInfoDlg}
                onClose={() => setShowErrorInfoDlg(false)}
                title={hasWarning ? t("Solver Warning") : t("Solver Error")}
                message={props.iteration.errorInfo?.message ?? ''}
                jobId={props.iteration.id}
                projectId={props.iteration.projectId}
                showEmailLink={!hasWarning} />
            {props.iteration.solveStatus === Status.Completed
                && props.iteration.solveInfo?.channelInfo
                && props.iteration.solveInfo.channelInfo?.length > 0
                && <SolveInfoDialog
                    open={showSolveInfoDlg}
                    iteration={props.iteration}
                    channelInfo={props.iteration.solveInfo.channelInfo}
                    onClose={() => setShowSolveInfoDlg(false)} />}

        </ThemeProvider>
    )
};

export default IterationListItem;
